import { getDataMapper, mapField, mapArray } from "@/shared";

export const mapSalaryToServer = getDataMapper({
    id: mapField("id", Number),
    wage: mapField("wage"),
    percent: mapField("percent"),
    employee: mapField("employee", (item) => item.human.fio),
    employee_id: mapField("employee", (item) => item.id),
    salary_type_id: mapField("salaryTypeId", Number),
    bonus: mapField("bonus", Number),
    sum: mapField("sum", Number),
});

export const mapSalariesToServer = mapArray(mapSalaryToServer);
