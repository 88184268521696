export const SERVICE_ROUTE = {
    LIST: "RepairServiceList",
    ADD: "RepairServiceAdd",
    EDIT: "RepairServiceEdit",
};
export const ORDERS_ROUTE = {
    LIST: "RepairList",
    ADD: "RepairAdd",
    EDIT: "RepairEdit",
};

export const REPAIR_ROUTE_NAMES = {
    SERVICE: SERVICE_ROUTE,
    ORDER: ORDERS_ROUTE,
};
