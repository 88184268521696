/* eslint-disable node/no-unsupported-features/es-syntax */
import { KIT_ROUTE_NAMES } from "@/shared";
export const KIT_ROUTES = [
    {
        name: KIT_ROUTE_NAMES.LIST,
        path: "/kit",
        meta: {
            title: "Комплекты",
        },
        component: () => import("../ui/KitList.vue"),
    },
    {
        name: KIT_ROUTE_NAMES.CARD,
        path: "/kit/:id",
        meta: {
            title: "Карточка комплекта",
        },
        component: () => import("../ui/KitCard.vue"),
        props: true,
    },
    {
        name: KIT_ROUTE_NAMES.ADD,
        path: "/kit/add",
        meta: {
            title: "Добавление комплекта",
        },
        component: () => import("../ui/KitEdit.vue"),
    },
    {
        name: KIT_ROUTE_NAMES.EDIT,
        path: "/kit/edit/:id",
        meta: {
            title: "Редактирование комплекта",
        },
        component: () => import("../ui/KitEdit.vue"),
        props: true,
    },
];
