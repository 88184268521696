import { getDataMapper, mapField } from "@/shared";

export const mapDepositRepairToServer = getDataMapper(
    {
        deposit_id: mapField("id", Number),
        is_accepted: mapField("isAccepted", Boolean),
        is_returned: mapField("isReturned", Boolean),
        sum: mapField("sum", Number),
    },
    { shouldAvoidUndefined: true, shouldAvoidNull: true }
);
