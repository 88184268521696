import { getDataMapper, mapField, mapArray } from "../../../lib";

import { mapPriceToServer } from "./mapPricesToServer";

function CategoryMetaData() {
    this.body = mapField("body");
    this.cash_deposit = mapField("cashDeposit", Number);
    this.children = mapField("children", this);
    this.const = mapField("const");
    this.controller_id = mapField("controllerId");
    this.count = mapField("count", Number);
    this.description = mapField("description");
    this.dict_id = mapField("dictId", Number);
    this.disabled = mapField("disabled", Boolean);
    this.discounts = mapField("discount");
    this.id = mapField("id", Number);
    this.order_id = mapField("orderId");
    this.parent_id = mapField("parentId");
    this.tariffs = mapField("prices", mapPriceToServer);
    this.selected = mapField("selected", Boolean);
    this.title = mapField("title", String);
}

export const mapCategoryToServer = getDataMapper(new CategoryMetaData());

export const mapCategoriesToServer = mapArray(mapCategoryToServer);
