import { useStorage } from "@vueuse/core";
import { defineStore } from "pinia";
import { ref, onMounted } from "vue";

import { getMenuLinks } from "../api/getMenuLinks";

export const useSidebarStore = defineStore("SidebarStore", () => {
    const menuLinks = ref([]);
    const isCollapsed = useStorage(
        "is-sidebar-collapsed",
        window.innerWidth <= 576,
        localStorage
    );

    async function fetchMenuLinks() {
        menuLinks.value = await getMenuLinks();
    }

    function setMenuLinks(array) {
        menuLinks.value = array;
    }

    function setCollapsedMode(value) {
        isCollapsed.value = value;
        toggleSidebarAppClass();
    }

    function toggleCollapsedMode() {
        isCollapsed.value = !isCollapsed.value;
        toggleSidebarAppClass();
    }

    function toggleSidebarAppClass() {
        const app = document.querySelector("#app");

        if (isCollapsed.value) {
            app.setAttribute("aria-expanded", "false");
        } else {
            app.setAttribute("aria-expanded", "true");
        }
    }

    onMounted(() => {
        toggleSidebarAppClass();
    });

    return {
        menuLinks,
        isCollapsed,
        setMenuLinks,
        fetchMenuLinks,
        setCollapsedMode,
        toggleCollapsedMode,
    };
});
