import { defineStore } from "pinia";
import { ref } from "vue";

export const useIntegrationsStore = defineStore("integrationsStore", () => {
    const isAtolIntegrated = ref(false);
    const myCalls = ref({});
    const smsDiscount = ref({});
    const zadarma = ref({});
    const googleCalendar = ref({});
    const amoCRM = ref({});
    const fDoc = ref({});

    const setIntegrations = (integrations) => {
        myCalls.value = integrations.myCalls;
        smsDiscount.value = integrations.smsDiscount;
        zadarma.value = integrations.zadarma;
        googleCalendar.value = integrations.googleCalendar;
        amoCRM.value = integrations.amoCRM;
        fDoc.value = integrations.fDoc;
    };

    // MyCalls
    const setMyMessagesIntegrated = (value) => {
        myCalls.value.sms = value;
    };

    const setMyCallsIntegrated = (value) => {
        myCalls.value.calls = value;
    };

    // SmsDiscount
    const setSmsDiscountIntegrated = (value) => {
        smsDiscount.value.isIntegrated = value;
    };

    const setSmsDiscountActiveName = (value) => {
        smsDiscount.value.activeName = value;
    };

    // Zadarma
    const setZadarmaIntegrated = (value) => {
        zadarma.value.isIntegrated = value;
    };
    const setZadarmaAtc = (value) => {
        zadarma.value.atc = value;
    };

    const setZadarmaWebRTCKey = (value) => {
        zadarma.value.webRTCKey = value;
    };

    // Google Calendar
    const setGoogleCalendarIntegrated = (value) => {
        googleCalendar.value.isIntegrated = value;
    };
    const setGoogleCalendarId = (value) => {
        googleCalendar.value.calendarId = value;
    };

    // AmoCRM
    const setAmoCRMIntegrated = (value) => {
        amoCRM.value.isIntegrated = value;
    };

    // Atol
    const setAtolIntegrated = (value) => {
        isAtolIntegrated.value = value;
    };

    // fDoc
    const setFDocCompanyIntegrated = (value) => {
        fDoc.value.companyStatus = value;
    };
    const setFDocUserIntegrated = (value) => {
        fDoc.value.userStatus = value;
    };

    return {
        myCalls,
        smsDiscount,
        zadarma,
        googleCalendar,
        amoCRM,
        isAtolIntegrated,
        fDoc,
        setIntegrations,
        setMyMessagesIntegrated,
        setMyCallsIntegrated,
        setSmsDiscountIntegrated,
        setSmsDiscountActiveName,
        setZadarmaIntegrated,
        setZadarmaAtc,
        setZadarmaWebRTCKey,
        setGoogleCalendarIntegrated,
        setGoogleCalendarId,
        setAmoCRMIntegrated,
        setAtolIntegrated,
        setFDocCompanyIntegrated,
        setFDocUserIntegrated,
    };
});
