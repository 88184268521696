<script setup>
import { storeToRefs } from "pinia";
import { computed, ref, watch } from "vue";

import BaseNotification from "./BaseNotification.vue";
import BaseNotificationCall from "./BaseNotificationCall.vue";
import importedProps from "./config/notificationsProps";
import { useNotificationsStore } from "./model/notificationsStore";

const props = defineProps({ ...importedProps });

const { notify } = storeToRefs(useNotificationsStore());

const notifications = ref([]);
const id = ref(1);
const isAnimation = ref(false);
const timer = ref(null);

const styles = computed(() => {
    const [y, x] = props.position.split(" ");

    return {
        [x]: `${props.xOffset}px`,
        [y]: `${props.yOffset}px`,
        width: `${props.width}px`,
    };
});

const active = computed(() =>
    notifications.value.filter((item) => item.state !== "CLOSED")
);

const animationName = computed(() => {
    const x = props.position.split(" ")[1];

    return x === "right" ? "slide-right" : "slide-left";
});

const addNotify = (notify) => {
    notify.state = "OPENED";

    if (!notify.id) notify.id = id.value++;

    if (!notify.icon) notify.icon = "attention";

    if (!notify.title) notify.title = "";

    if (!notify.text) notify.text = "";

    if (!notify.duration) notify.duration = props.defaultDuration;

    if (!notify.date) notify.date = new Date();

    if (!notify.linkContent) notify.linkContent = "Перейти";

    if (
        notify.icon !== "phone" &&
        props.ignoreDuplicates &&
        active.value.some(
            (item) =>
                item.title === notify.title &&
                item.text === notify.text &&
                item.icon === notify.icon
        )
    ) {
        return;
    }

    notifications.value.push(notify);
};

const clear = () => {
    if (!isAnimation.value) {
        notifications.value = notifications.value.filter(
            (item) => item.state !== "CLOSED"
        );
    }
};

const handleClose = (notification) => {
    notification.state = "CLOSED";
    isAnimation.value = true;
    clearTimeout(timer.value);
    timer.value = setTimeout(() => {
        isAnimation.value = false;
        clear();
    }, 550);
};

watch(
    () => notify.value,
    (newNotify) => {
        addNotify(newNotify);
    }
);
</script>

<template>
    <div class="base-notifications" :style="styles">
        <TransitionGroup :name="animationName" tag="div">
            <template v-for="notification in active" :key="notification.id">
                <BaseNotificationCall
                    v-if="notification.icon === 'phone'"
                    class="base-notifications__item"
                    :item="notification"
                    :icon="notification.icon"
                    :duration="15000"
                    :date="notification.date"
                    :text="notification.text"
                    :title="notification.title"
                    :incomingCall="notification.incomingCall"
                    @close="handleClose(notification)"
                />
                <BaseNotification
                    v-else
                    class="base-notifications__item"
                    :item="notification"
                    :icon="notification.icon"
                    :duration="notification.duration"
                    :date="notification.date"
                    :text="notification.text"
                    :title="notification.title"
                    @close="handleClose"
                >
                    <template v-if="notification.externalLink" #link>
                        <a :href="notification.externalLink" target="_blank">
                            {{ notification.linkContent }}
                        </a>
                    </template>
                    <template v-else-if="notification.internalLink" #link>
                        <router-link :to="notification.internalLink">
                            {{ notification.linkContent }}
                        </router-link>
                    </template>
                </BaseNotification>
            </template>
        </TransitionGroup>
    </div>
</template>

<style lang="scss" scoped>
.base-notifications {
    position: fixed;
    z-index: 5555;

    &__item {
        transition: all 0.5s;
    }

    &__item:not(:last-child) {
        margin-bottom: 10px;
    }
}

.slide-right-enter-active {
    animation: slide-in 0.5s;
}

.slide-right-leave-active {
    position: absolute;

    animation: slide-in 0.5s reverse;
}

.slide-left-enter-active {
    animation: slide-left-in 0.5s;
}

.slide-left-leave-active {
    position: absolute;

    animation: slide-left-in 0.5s reverse;
}

@keyframes slide-in {
    0% {
        right: -100%;

        opacity: 0.5;
    }

    100% {
        right: 0;

        opacity: 1;
    }
}

@keyframes slide-left-in {
    0% {
        left: -100%;

        opacity: 0.5;
    }

    100% {
        left: 0;

        opacity: 1;
    }
}
</style>
