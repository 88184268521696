import {
    getDataMapper,
    mapField,
    mapArray,
    mapMediaFromServer,
    parseISO,
} from "@/shared";

import { mapInventoryFromServer } from "@/entities/inventory/@x";

export const mapExpenseFromServer = getDataMapper({
    id: mapField("id", Number),
    name: mapField("name"),
    value: mapField("value", Number),
    expenseType: mapField("expense_type", {
        id: mapField("id", Number),
        title: mapField("title"),
        const: mapField("const"),
    }),
    createdAt: mapField("created_at", parseISO),
    date: mapField("date", parseISO),
    inventory: mapField("inventory", mapInventoryFromServer),
    point: mapField("point", {
        id: mapField("id", Number),
        title: mapField("title"),
    }),
    comment: mapField("comment"),
    media: mapField("media", mapMediaFromServer),
});

export const mapExpensesFromServer = mapArray(mapExpenseFromServer);
