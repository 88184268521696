import { makeHttpRequest } from "@/shared";

import { mapCalculatedRepairFromServer } from "./mappers/mapCalculatedRepairFromServer";
import { mapRepairBreakingToServer } from "./mappers/mapRepairBreakingToServer.js";
export const addRepairBreaking = async (sessionKey, breaking) => {
    const response = (
        await makeHttpRequest({
            url: `/repair/builder/${sessionKey}/add/broken`,
            method: "PUT",
            data: mapRepairBreakingToServer(breaking),
        })
    ).data;

    return mapCalculatedRepairFromServer(response.data);
};
