import {
    makeHttpRequest,
    mapPaginationParamsToServer,
    mapDatabaseParamsFromServer,
} from "@/shared";

import { mapRepairFiltersToServer } from "./mappers/mapRepairFiltersToServer";
import { mapRepairsFromServer } from "./mappers/mapRepairsFromServer";

export const getRepairs = async (paginationParams = {}, filters) => {
    const response = (
        await makeHttpRequest({
            url: "/repair",
            method: "GET",
            params: {
                ...mapPaginationParamsToServer(paginationParams),
                ...mapRepairFiltersToServer(filters),
            },
        })
    ).data;

    return {
        data: mapRepairsFromServer(response.data),
        ...mapDatabaseParamsFromServer(response),
    };
};
