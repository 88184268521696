<script setup>
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";

import { CLIENT_ROUTE_NAMES, RENT_ROUTE_NAMES } from "../../constants";
import { IconPhone } from "../../icons";
import BaseButton from "../button/BaseButton.vue";

import importedProps from "./config/notificationProps";

const props = defineProps({
    incomingCall: {
        type: Object,
        default: () => ({}),
    },
    ...importedProps,
});
const emit = defineEmits(["close"]);

const router = useRouter();

const timer = ref(null);
const start = ref(null);
const remaining = ref(null);

const closeNotify = () => {
    clearTimeout(timer.value);
    emit("close");
};

const startTimer = () => {
    start.value = Date.now();
    timer.value = setTimeout(() => {
        closeNotify();
    }, props.duration);
};

const pauseTimer = () => {
    clearTimeout(timer.value);
    remaining.value = props.duration - (Date.now() - start.value);
};

const resumeTimer = () => {
    start.value = Date.now();
    clearTimeout(timer.value);
    timer.value = setTimeout(() => {
        closeNotify();
    }, remaining.value);
};

const goToRents = () => {
    router.push({
        name: RENT_ROUTE_NAMES.LIST,
        query: {
            humanId: props.incomingCall.humanId,
        },
    });
};

const goToClient = () => {
    if (props.incomingCall.guid) {
        router.push({
            name: CLIENT_ROUTE_NAMES.PROFILE,
            params: {
                clientId: props.incomingCall.id,
            },
        });
    } else {
        router.push({
            name: CLIENT_ROUTE_NAMES.CREATE,
            query: {
                phone: props.incomingCall.contact ?? props.incomingCall.number,
            },
        });
    }
};

const createRent = () => {
    router.push({
        name: RENT_ROUTE_NAMES.ADD,
        query: {
            clientFio: props.incomingCall.fio,
        },
    });
};

onMounted(() => {
    startTimer();
});
</script>

<template>
    <div
        class="base-notification-call"
        @mouseenter="pauseTimer"
        @mouseleave="resumeTimer"
    >
        <p class="base-notification-call__header">{{ title }}</p>
        <div class="base-notification-call__body">
            <IconPhone class="base-notification-call__phone" />
            <div class="base-notification-call__info">
                <p class="base-notification-call__fio">
                    {{ incomingCall.fio ?? "Абонент неизвестен" }}
                </p>
                <p class="base-notification-call__number">
                    {{ incomingCall.contact ?? incomingCall.number }}
                </p>
                <p
                    class="base-notification-call__active-rents base-notification-call__link"
                    @click="goToRents"
                >
                    {{
                        incomingCall.is_active_rent
                            ? "Есть активные аренды"
                            : "Аренд нет"
                    }}
                </p>
            </div>
            <div class="base-notification-call__rent">
                <BaseButton
                    class="base-notification-call__create-rent"
                    variant="secondary"
                    type="rounded"
                    size="sm"
                    @click="createRent"
                >
                    Создать аренду
                </BaseButton>
                <p
                    class="base-notification-call__card base-notification-call__link"
                    @click="goToClient"
                >
                    {{
                        incomingCall.id
                            ? "Перейти в карточку"
                            : "Создать клиента"
                    }}
                </p>
            </div>
        </div>
        <button
            class="base-notification-call__cross"
            @click="closeNotify"
        ></button>
    </div>
</template>

<style lang="scss" scoped>
.base-notification-call {
    position: relative;

    display: flex;

    flex-direction: column;

    float: right;

    width: 150%;
    min-width: 100%;
    padding: var(--default-paddings);

    color: var(--c-text);

    background-color: var(--c-background);
    border-radius: var(--default-small-border-radius);
    box-shadow: var(--default-box-shadow);

    &__header {
        font-size: 12px;
        font-weight: 600;
        color: inherit;
    }

    &__body {
        display: flex;

        align-items: center;
        justify-content: space-between;
    }

    &__phone {
        width: 42px;
        height: 42px;

        color: var(--c-primary);
    }

    &__info {
        font-size: 12px;
        font-weight: 400;
        color: inherit;
    }

    &__active-rents {
        font-size: 9px;
        font-weight: 500;
    }

    &__create-rent {
        margin-bottom: 10px;

        font-size: 12px;
        font-weight: 500;
        line-height: 14px;
    }

    &__card {
        font-size: 9px;
        font-weight: 400;
        color: inherit;
        text-align: center;
    }

    &__link {
        margin-bottom: 0;

        cursor: pointer;
    }

    &__cross {
        position: absolute;
        top: 10px;
        right: 10px;

        width: 15px;
        height: 15px;
        padding: 0;
        margin: 0;

        cursor: pointer;

        background: none !important;
        border: 0;
        opacity: 0.7;

        &:hover {
            opacity: 0.8;
        }

        &::before,
        &::after {
            position: absolute;
            top: 0;
            left: 7.5px;

            width: 2px;
            height: 15px;

            content: " ";

            background-color: var(--c-neutral-80);
        }

        &::before {
            transform: rotate(45deg);
        }

        &::after {
            transform: rotate(-45deg);
        }
    }

    & a {
        color: var(--c-primary);

        &:hover {
            color: var(--c-primary);
        }
    }

    p {
        margin-bottom: 10px;
    }

    @media (max-width: 599px) {
        width: unset;

        p {
            margin-bottom: 5px;
        }

        &__body {
            flex-wrap: wrap;
        }

        &__info {
            width: 70%;
            margin-bottom: 5px;

            text-align: center;
        }

        &__rent {
            width: 100%;

            text-align: center;
        }
    }
}
</style>
