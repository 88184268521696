import { defineStore } from "pinia";

import { useEntityStore } from "@/shared";

import { addProduct } from "../api/addProduct";
import { deleteProduct } from "../api/deleteProduct";
import { deleteProducts } from "../api/deleteProducts";
import { editProduct } from "../api/editProduct";
import { getProduct } from "../api/getProduct";
import { getProducts } from "../api/getProducts";

export const useProductsStore = defineStore("products", () => {
    const {
        isManyLoading,
        isOneLoading,
        listData,
        singleData,
        meta,
        permissions,
        fetchMany,
        addOne,
        getOne,
        deleteOne,
        deleteMany,
        editOne,
        setManyLoading,
    } = useEntityStore({
        addEntity: addProduct,
        getEntity: getProduct,
        getEntities: getProducts,
        deleteEntity: deleteProduct,
        deleteEntities: deleteProducts,
        editEntity: editProduct,
    });

    return {
        // entity
        listData,
        singleData,
        isManyLoading,
        isOneLoading,
        meta,
        permissions,
        fetchMany,
        addOne,
        getOne,
        deleteOne,
        deleteMany,
        editOne,
        setManyLoading,
    };
});
