import { makeHttpRequest } from "@/shared";

import { mapCalculatedSellingFromServer } from "./mappers/mapCalculatedSellingFromServer";

export const addDiscountToSellingByValue = async (sessionKey, value) => {
    const response = (
        await makeHttpRequest({
            url: `/stock/builder/${sessionKey}/add/discount/custom`,
            method: "PUT",
            data: {
                value: Number(value),
            },
        })
    ).data;

    return mapCalculatedSellingFromServer(response.data);
};
