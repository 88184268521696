export const PRODUCT_ROUTE = {
    MAIN: "StoreProductMain",
    ADD: "StoreProductAdd",
    EDIT: "StoreProductEdit",
};
export const STOCK_ROUTE = {
    MAIN: "StockMain",
    ADD: "StockAdd",
    EDIT: "StockEdit",
};

export const STORE_ROUTE_NAMES = {
    PRODUCT: PRODUCT_ROUTE,
    STOCK: STOCK_ROUTE,
};
