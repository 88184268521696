import { makeHttpRequest } from "@/shared";

import { mapExpenseTypeFromServer } from "./mappers/mapExpenseTypesFromServer";

export const getExpenseType = async (id) => {
    const response = (
        await makeHttpRequest({
            url: `/expense/type/${id}`,
            method: "GET",
        })
    ).data;

    return mapExpenseTypeFromServer(response.data);
};
