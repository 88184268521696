import { mapField, mapArray, getDataMapper } from "@/shared";

export const mapInventoryPaybackFromServer = getDataMapper({
    categoryTitle: mapField("category_title", String),
    countRent: mapField("count_rent", Number),
    income: mapField("income", Number),
    inventoryTitle: mapField("inventory_title", String),
    paybackPoint: mapField("payback_point", String),
    pointTitle: mapField("point_title", String),
});

export const mapInventoryPaybacksFromServer = mapArray(
    mapInventoryPaybackFromServer
);
