import {
    getDataMapper,
    mapField,
    getObjectId,
    mapMediaToServer,
} from "@/shared";

export const mapProductToServer = getDataMapper({
    id: mapField("id", Number),
    title: mapField("title"),
    count: mapField("count", Number),
    price: mapField("price", Number),
    buy_price: mapField("price", Number),
    point_id: mapField("point", getObjectId),
    discounts: mapField("discounts", getObjectId),
    category_resource_id: mapField("category", getObjectId),
    buy_date: mapField(null, () => null, { applyToNil: true }),
    artule: mapField("articleNumber"),
    media: mapField("media", mapMediaToServer),
});
