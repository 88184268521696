import { makeHttpRequest } from "@/shared";

export const deleteDiscount = async (id) => {
    return (
        await makeHttpRequest({
            url: `/discount/${id}`,
            method: "DELETE",
        })
    ).data;
};
