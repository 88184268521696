import {
    getDataMapper,
    mapCategoryFromServer,
    mapField,
    mapMediaFromServer,
} from "@/shared";

export const mapInventoryClientFromServer = getDataMapper({
    id: mapField("id", Number),
    title: mapField("title", String),
    description: mapField("description", String),
    category: mapField("category", mapCategoryFromServer),
    media: mapField("media", mapMediaFromServer),
});
