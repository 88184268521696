import {
    makeHttpRequest,
    mapDatabaseParamsFromServer,
    mapPaginationParamsToServer,
} from "@/shared";

import { mapBreakingsFromServer } from "./mappers/mapBreakingsFromServer";

export const getBreakings = async (paginationParams = {}) => {
    const response = (
        await makeHttpRequest({
            url: "/broken",
            method: "GET",
            params: mapPaginationParamsToServer(paginationParams),
        })
    ).data;

    return {
        data: mapBreakingsFromServer(response.data),
        ...mapDatabaseParamsFromServer(response),
    };
};
