import {
    mapHumanFromServer,
    mapContactFromServer,
    mapCompanyFromServer,
    mapMediaFromServer,
    getDataMapper,
    mapField,
} from "@/shared";

// import { mapDebtsFromServer } from "./mapDebtsFromServer";

export const mapClientFromServer = getDataMapper({
    id: mapField("id", Number),
    avatar: mapField("avatar"),
    createdAt: mapField("created_at"),
    updatedAt: mapField("updated_at"),
    address: mapField("address"),
    comment: mapField("comment"),
    blackList: mapField("black_list", Boolean),
    sourceAttraction: mapField(
        "source_attraction",
        getDataMapper({
            id: mapField("id", Number),
            title: mapField("title", String),
            const: mapField("const", String),
        })
    ),
    isLegalEntity: mapField("is_legal_entity", Boolean),
    human: mapField("human", mapHumanFromServer),
    contacts: mapField("contacts", mapContactFromServer),
    debts: mapField("debts", Number),
    rentsSum: mapField("rents_sum", Number),
    repairsSum: mapField("repairs_sum", Number),
    stocksSum: mapField("stocks_sum", Number),
    discounts: mapField("discounts"),
    number: mapField("number"),
    company: mapField("company", mapCompanyFromServer),
    media: mapField("media", mapMediaFromServer),
    hasContract: mapField("has_contract", Boolean),
    hasDeposit: mapField("has_deposit", Boolean),
});
