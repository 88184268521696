import { makeHttpRequest } from "@/shared";

import { mapCalculatedRepairFromServer } from "./mappers/mapCalculatedRepairFromServer";
export const removeRepairClient = async (sessionKey) => {
    const response = (
        await makeHttpRequest({
            url: `/repair/builder/${sessionKey}/remove/client`,
            method: "DELETE",
        })
    ).data;

    return mapCalculatedRepairFromServer(response.data);
};
