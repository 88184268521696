import { makeHttpRequest } from "@/shared";

export const deleteEmployees = async (ids) => {
    return (
        await makeHttpRequest({
            url: `/human/employee/delete-multiple`,
            method: "DELETE",
            params: { items: ids },
        })
    ).data;
};
