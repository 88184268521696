import { makeHttpRequest } from "@/shared";

import { mapCalculatedRentFromServer } from "./mappers/mapCalculatedRentFromServer";
export const addClientToRent = async (sessionKey, humanId) => {
    const response = (
        await makeHttpRequest({
            url: `/rent/builder/${sessionKey}/add/client/${humanId}`,
            method: "PUT",
        })
    ).data;

    return mapCalculatedRentFromServer(response.data);
};
