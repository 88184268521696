import { createRouter, createWebHistory } from "vue-router";

import { useTariffStore } from "@/entities";
import { ROUTES, isAuthRoute, hasToken } from "@/pages";
import { AUTHENTICATION_ROUTE_NAMES, GENERAL_ROUTE_NAMES } from "@/shared";

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: ROUTES,
});

router.beforeEach((to) => {
    const isAuthenticated = hasToken();
    const isCurrentRouteAuth = isAuthRoute(to.name);
    const tariffStore = useTariffStore();

    if (
        tariffStore.statistic?.daysLeft === 0 &&
        to.name !== GENERAL_ROUTE_NAMES.BUY_TARIFF
    ) {
        return { name: GENERAL_ROUTE_NAMES.BUY_TARIFF };
    }

    if (!isAuthenticated && !isCurrentRouteAuth) {
        return { name: AUTHENTICATION_ROUTE_NAMES.LOGIN };
    }

    if (isAuthenticated && isCurrentRouteAuth) {
        return { name: GENERAL_ROUTE_NAMES.DEFAULT };
    }

    if (to.query.token) {
        return { name: to.name, query: {} };
    }
});

export const withRouter = (application) => {
    application.use(router);
};

export { router };
