import { defineStore } from "pinia";

import { useEntityStore } from "@/shared";

import { addBreaking } from "../api/addBreaking";
import { deleteBreaking } from "../api/deleteBreaking";
import { deleteBreakings } from "../api/deleteBreakings";
import { editBreaking } from "../api/editBreaking";
import { getBreaking } from "../api/getBreaking";
import { getBreakings } from "../api/getBreakings";

export const useBreakingsStore = defineStore("breakings", () => {
    const {
        isManyLoading,
        isOneLoading,
        listData,
        singleData,
        meta,
        permissions,
        addOne,
        deleteOne,
        deleteMany,
        editOne,
        fetchMany,
        getOne,
        setManyLoading,
    } = useEntityStore({
        addEntity: addBreaking,
        getEntity: getBreaking,
        getEntities: getBreakings,
        deleteEntity: deleteBreaking,
        deleteEntities: deleteBreakings,
        editEntity: editBreaking,
    });

    return {
        listData,
        singleData,
        isManyLoading,
        isOneLoading,
        meta,
        permissions,
        fetchMany,
        deleteOne,
        deleteMany,
        getOne,
        editOne,
        addOne,
        setManyLoading,
    };
});
