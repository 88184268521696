import { getDataMapper, mapField } from "@/shared";

export const mapCompanyToServer = getDataMapper({
    id: mapField("id", Number),
    title: mapField("title"),
    email: mapField("email"),
    website: mapField("website"),
    mobile: mapField("mobile"),
    place_id: mapField("placeId", Number),
    group_of_user_id: mapField("groupOfUserId", Number),
    bic: mapField("bic"),
    ogrn: mapField("ogrn"),
    inn: mapField("inn"),
    kpp: mapField("kpp"),
    checking_account: mapField("checkingAccount"),
    correspondent_account: mapField("correspondentAccount"),
    bank_name: mapField("bankName"),
    address: mapField("address"),
});
