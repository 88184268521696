import { makeHttpRequest } from "@/shared";

export const deleteSalariesHistory = async (ids) => {
    return (
        await makeHttpRequest({
            url: "/human/employee/salary/pay/delete-multiple",
            method: "DELETE",
            params: { items: ids },
        })
    ).data;
};
