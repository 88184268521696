import { getDataMapper, mapArray, mapField } from "../../../lib";

export const mapMediaFromServer = getDataMapper({
    dictId: mapField("dict_id", Number),
    mediaId: mapField("media_id", Number),
    orderId: mapField("order_id", Number),
    src: mapField("src", String),
});

export const mapMediasFromServer = mapArray(mapMediaFromServer);
