import { getDataMapper, mapField, mapArray } from "@/shared";

export const mapEfficiencyFromServer = getDataMapper({
    dealsCount: mapField("deals_count", Number),
    fio: mapField("fio", String),
    rentCount: mapField("rent_count", Number),
    repairCount: mapField("repair_count", Number),
    shiftCount: mapField("shift_count", Number),
    stockCount: mapField("stock_count", Number),
    income: mapField("income", Number),
});

export const mapEfficienciesFromServer = mapArray(mapEfficiencyFromServer);
