import { mapInventoryFiltersToServer } from "@/entities";
import {
    makeHttpRequest,
    mapPaginationParamsToServer,
    mapDatabaseParamsFromServer,
} from "@/shared";

import { mapKitsFromServer } from "./mappers/mapKitsFromServer";

export const getFreeKitsByRent = async (
    paginationParams,
    filters,
    { sessionKey }
) => {
    const response = (
        await makeHttpRequest({
            url: `/rent/builder/${sessionKey}/search/kit`,
            method: "GET",
            params: {
                ...mapPaginationParamsToServer(paginationParams),
                ...mapInventoryFiltersToServer(filters),
            },
        })
    ).data;

    return {
        data: mapKitsFromServer(response.data),
        ...mapDatabaseParamsFromServer(response),
    };
};
