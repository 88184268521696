import { makeHttpRequest } from "@/shared";

import { mapCalculatedRentFromServer } from "./mappers/mapCalculatedRentFromServer";
import { mapPaymentRentToServer } from "./mappers/mapPaymentRentToServer";

export const addPaymentToRent = async (sessionKey, payment) => {
    const response = (
        await makeHttpRequest({
            url: `/rent/builder/${sessionKey}/add/payment`,
            method: "PUT",
            data: mapPaymentRentToServer(payment),
        })
    ).data;

    return mapCalculatedRentFromServer(response.data);
};
