export default {
    buttonType: {
        type: String,
        validator(value) {
            return ["circle", "rounded"].includes(value);
        },
        default: "circle",
    },
    buttonVariant: {
        type: String,
        validator(value) {
            return [
                "primary",
                "default", // он же светло-серый цвет
                "gray",
                "red",
                "secondary",
                "white",
                "invisible",
                "none",
            ].includes(value);
        },
        default: "default",
    },
    buttonSize: {
        type: String,
        validator(value) {
            return ["xs", "sm", "md", "lg"].includes(value);
        },
        default: "md",
    },
    buttonStyles: {
        type: Object,
        default: null,
    },
    buttonClass: {
        type: [String, Array],
        default: "",
    },
    tooltipText: {
        type: String,
        default: null,
    },
    dropdownRight: {
        type: String,
        default: null,
    },
    dropdownMaxHeight: {
        type: String,
        default: null,
    },
    links: {
        type: Array,
        default: null,
    },
    hasCross: {
        type: Boolean,
        default: true,
    },
    zIndex: {
        type: String,
        default: "var(--zi-dropdown)",
    },
};
