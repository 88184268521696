import { makeHttpRequest } from "@/shared";

export const deleteInventory = async (id) => {
    return (
        await makeHttpRequest({
            url: `/inventory/${id}`,
            method: "DELETE",
        })
    ).data;
};
