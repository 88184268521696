import { makeHttpRequest } from "@/shared";

import { mapClientFromServer } from "./mappers/from/mapClientFromServer";
import { mapClientToServer } from "./mappers/to/mapClientToServer";

export const addClient = async (client) => {
    const response = (
        await makeHttpRequest({
            url: "/human/client",
            method: "POST",
            data: mapClientToServer(client),
        })
    ).data;

    return mapClientFromServer(response.data);
};
