export default {
    right: {
        type: String,
        default: null,
    },
    maxHeight: {
        type: String,
        default: null,
    },
    links: {
        type: Array,
        default: null,
    },
    hasCross: {
        type: Boolean,
        default: true,
    },
};
