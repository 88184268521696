import { getDataMapper, mapField } from "../../../lib";

export const mapUserToServer = getDataMapper({
    id: mapField("id", Number),
    confirmed: mapField("isConfirmed", Boolean),
    login: mapField("login"),
    password: mapField("password"),
    last_visit: mapField("lastVisit"),
    register_user_agent: mapField("registerUserAgent"),
    is_admin_session: mapField("isAdminSession", Boolean),
});
