import { makeHttpRequest } from "@/shared";

import { mapCalculatedRentFromServer } from "./mappers/mapCalculatedRentFromServer";
import { mapRentKitToServer } from "./mappers/mapRentKitToServer";
export const addKitToRent = async (sessionKey, kit) => {
    const response = (
        await makeHttpRequest({
            url: `/rent/builder/${sessionKey}/add/kit`,
            method: "PUT",
            data: mapRentKitToServer(kit),
        })
    ).data;

    return mapCalculatedRentFromServer(response.data);
};
