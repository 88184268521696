import { makeHttpRequest } from "@/shared";

import { mapRefusalTypeToServer } from "./mappers/mapRefusalTypeToServer";

export const addRefusalType = async (sourceAttraction) => {
    const response = (
        await makeHttpRequest({
            url: "/delivery/refusal-type",
            method: "POST",
            data: mapRefusalTypeToServer(sourceAttraction),
        })
    ).data;

    return response;
};
