import { getDataMapper, mapField, isObject, formatISODuration } from "@/shared";

const mapGoogleAccessTokenToServer = getDataMapper({
    access_token: mapField("accessToken"),
    expires_in: mapField("expiresIn", Number),
    scope: mapField("scope"),
    token_type: mapField("tokenType"),
    created: mapField("created", Number),
});

export const mapSettingsToServer = getDataMapper({
    id: mapField("id", Number),
    min_rent_time: mapField("minRentTime", formatISODuration),
    const: mapField("const"),
    notify_mail: mapField("notifyMail"),
    notify_shift: mapField("shouldNotifyShift", Boolean),
    notify_rent: mapField("shouldNotifyRent", Boolean),
    notify_vk: mapField("shouldNotifyVk", Boolean),
    group_id: mapField("groupId", Number),
    need_calc_breaking: mapField("needCalcBreaking", Boolean),
    need_calc_rent_delay: mapField("needCalcRentDelay", Boolean),
    active_human_balance: mapField("activeHumanBalance", Number),
    active_client_verify: mapField("activeClientVerify", Number),
    currency_id: mapField("currencyId", Number),
    timezone_id: mapField("timezoneId", Number),
    rent_order_number: mapField("rentOrderNumber", Number),
    rent_order_format: mapField("rentOrderFormat"),
    stock_order_number: mapField("stockOrderNumber", Number),
    stock_order_format: mapField("stockOrderFormat"),
    repair_order_number: mapField("repairOrderNumber", Number),
    repair_order_format: mapField("repairOrderFormat"),
    first_enter: mapField("firstEnter", Boolean),
    has_user_token: mapField("hasUserToken", Boolean),
    is_legal_entity: mapField("isDefaultTypeLegalEntity", Boolean),
    notify_before_end_rent: mapField("notifyBeforeEndRent", Boolean),
    notify_before_start_rent: mapField("notifyBeforeStartRent", Boolean),
    notify_after_end_rent: mapField("notifyAfterEndRent", Boolean),
    notify_chat: mapField("notifyChat", Boolean),
    has_module_delivery: mapField("hasModuleDelivery", Boolean),
    has_module_rent: mapField("hasModuleRent", Boolean),
    has_module_repair: mapField("hasModuleRepair", Boolean),
    has_module_stock: mapField("hasModuleStock", Boolean),
    is_rent_fine: mapField("isRentFine", Boolean),
    login: mapField("login"),
    rent_human_balance_pay: mapField("rentHumanBalancePay", Number),
    google_access_token_json: mapField("googleAccessTokenJson", (value) => {
        if (isObject(value)) {
            return JSON.stringify(mapGoogleAccessTokenToServer(value));
        }

        return null;
    }),
    google_calendar_id: mapField("googleCalendarId", Number),
    amo_app_id: mapField("amoAppId", Number),
    amo_secret: mapField("amoSecret"),
    amo_access_token: mapField("amoAccessToken"),
    amo_refresh_token: mapField("amoRefreshToken"),
    amo_base_domain: mapField("amoBaseDomain"),
    my_calls_key: mapField("myCallsKey"),
    my_calls_url: mapField("myCallsUrl"),
    my_calls_username: mapField("myCallsUsername"),
    zadarma_phone: mapField("zadarmaPhone"),
    zadarma_key: mapField("zadarmaKey"),
    zadarma_secret: mapField("zadarmaSecret"),
    sms_d_password: mapField("smsDPassword"),
    sms_d_login: mapField("smsDLogin"),
    zadarma_atc: mapField("zadarmaAtc"),
    my_calls_status: mapField("myCallsStatus", Number),
    sms_discount_active_name: mapField("smsDiscountActiveName"),
    send_shift_report: mapField("shouldSendReports", Boolean),
});
