import { makeHttpRequest } from "@/shared";

import { mapCalculatedSellingFromServer } from "./mappers/mapCalculatedSellingFromServer";
import { mapSellingAdditionalToServer } from "./mappers/mapSellingAdditionalToServer";

export const updateSellingAdditional = async (sessionKey, stockAdditional) => {
    const response = (
        await makeHttpRequest({
            url: `/stock/builder/${sessionKey}/add/config`,
            method: "PUT",
            data: mapSellingAdditionalToServer(stockAdditional),
        })
    ).data;

    return mapCalculatedSellingFromServer(response.data);
};
