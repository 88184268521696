import { getDataMapper, mapArray, mapField } from "../../../lib";

export const mapAttractionFromServer = getDataMapper({
    id: mapField("id", Number),
    const: mapField("const"),
    createdAt: mapField("created_at"),
    title: mapField("title"),
    updatedAt: mapField("updated_at"),
});

export const mapAttractionsFromServer = mapArray(mapAttractionFromServer);
