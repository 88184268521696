import { makeHttpRequest } from "@/shared";

import { mapCalculatedRepairFromServer } from "./mappers/mapCalculatedRepairFromServer";
import { mapRepairAdditionalToServer } from "./mappers/mapRepairAdditionalToServer";
export const updateRepairAdditional = async (sessionKey, repairAdditional) => {
    const response = (
        await makeHttpRequest({
            url: `/repair/builder/${sessionKey}/add/config`,
            method: "PUT",
            data: mapRepairAdditionalToServer(repairAdditional),
        })
    ).data;

    return mapCalculatedRepairFromServer(response.data);
};
