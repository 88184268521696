import { getDataMapper, mapField } from "../../../lib";
export const mapHumanContactFromServer = getDataMapper({
    userId: mapField("user_id", Number),
    contactType: mapField("contact_type", {
        id: mapField("id", Number),
        value: mapField("value"),
        const: mapField("const"),
    }),
    contactTypeId: mapField("contact_type_id", Number),
    protectedValue: mapField("protected_value"),
    waitTo: mapField("wait_to"),
});
