import {
    makeHttpRequest,
    mapPaginationParamsToServer,
    mapDatabaseParamsFromServer,
} from "@/shared";

import { mapExpenseFiltersToServer } from "./mappers/mapExpenseFiltersToServer";
import { mapExpensesFromServer } from "./mappers/mapExpensesFromServer";

export const getExpenses = async (paginationParams, filters) => {
    const response = (
        await makeHttpRequest({
            url: "/expense",
            method: "GET",
            params: {
                ...mapPaginationParamsToServer(paginationParams),
                ...mapExpenseFiltersToServer(filters),
            },
        })
    ).data;

    return {
        data: mapExpensesFromServer(response.data),
        ...mapDatabaseParamsFromServer(response),
    };
};
