import {
    makeHttpRequest,
    mapDatabaseParamsFromServer,
    mapPaginationParamsToServer,
    isArray,
} from "@/shared";

import { mapProductsFromServer } from "./mappers/mapProductsFromServer";

export const getProducts = async (paginationParams = {}, filters) => {
    const response = (
        await makeHttpRequest({
            url: "/product",
            method: "GET",
            params: {
                ...mapPaginationParamsToServer(paginationParams),
                point_id: isArray(filters.points)
                    ? filters.points.map(({ id }) => id)
                    : null,
            },
        })
    ).data;

    return {
        data: mapProductsFromServer(response.data),
        ...mapDatabaseParamsFromServer(response),
    };
};
