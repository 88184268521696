export const LOCALES_META = [
    {
        locale: "ru",
        title: "Русский",
    },
    {
        locale: "en",
        title: "Английский",
    },
    {
        locale: "tr",
        title: "Турецкий",
    },
];

export const SUPPORTED_LOCALES = LOCALES_META.map(({ locale }) => locale);
