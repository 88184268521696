import { getDataMapper, mapField } from "@/shared";

export const mapCalendarFiltersToServer = getDataMapper({
    from: mapField("startStr"),
    to: mapField("endStr"),
    point_id: mapField("pointId"),
    inventory_id: mapField("inventoryId"),
    human_id: mapField("humanId"),
    admin_user_id: mapField("adminUserId"),
});
