import {
    makeHttpRequest,
    mapPaginationParamsToServer,
    mapDatabaseParamsFromServer,
} from "@/shared";

import { mapPaymentsHistoryFromServer } from "./mappers/mapPaymentsHistoryFromServer";

export const getPaymentsHistory = async (params) => {
    const response = (
        await makeHttpRequest({
            url: "/tariff/payment-history",
            method: "GET",
            params: mapPaginationParamsToServer(params),
        })
    ).data;

    return {
        data: mapPaymentsHistoryFromServer(response.data),
        ...mapDatabaseParamsFromServer(response),
    };
};
