import { getDataMapper, mapField } from "@/shared";

export const mapDiscountToServer = getDataMapper(
    {
        id: mapField("id", Number),
        title: mapField("title"),
        value: mapField("value", String),
        discount_type_id: mapField("type", Number),
        only_promo: mapField("isPromocode", () => 1, { applyToNil: true }),
        is_discount: mapField("isDiscount", Number),
        promocode: mapField("promocode"),
        limits: mapField("limit", Number),
        time_start: mapField("timeStart"),
        time_duration: mapField("timeDuration"),
    },
    {
        shouldAvoidUndefined: true,
    }
);
