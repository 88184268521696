import { makeHttpRequest } from "@/shared";

import { mapCalculatedRepairFromServer } from "./mappers/mapCalculatedRepairFromServer";
export const addRepairDiscountByPromo = async (sessionKey, promocode) => {
    const response = (
        await makeHttpRequest({
            url: `/repair/builder/${sessionKey}/add/discount/promocode`,
            method: "PUT",
            data: {
                promocode,
            },
        })
    ).data;

    return mapCalculatedRepairFromServer(response.data);
};
