import { getDataMapper, mapField } from "@/shared";

export const mapContractToServer = getDataMapper(
    {
        id: mapField("id", Number),
        title: mapField("title"),
        body: mapField("document"),
    },
    {
        shouldAvoidUndefined: true,
    }
);
