import { makeHttpRequest } from "@/shared";

import { mapBreakingToServer } from "./mappers/mapBreakingToServer";

export const addBreaking = async (breaking) => {
    const response = (
        await makeHttpRequest({
            url: "/broken",
            method: "POST",
            data: mapBreakingToServer(breaking),
        })
    ).data;

    return response;
};
