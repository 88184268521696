import { defineStore } from "pinia";

import { useEntityStore, useSelectEntityStore } from "@/shared";

import { addDelivery } from "../api/addDelivery";
import { deleteDelivery } from "../api/deleteDelivery";
import { editDelivery } from "../api/editDelivery";
import { getDeliveries } from "../api/getDeliveries";
import { getDelivery } from "../api/getDelivery";

export const useDeliveryStore = defineStore("inventories", () => {
    const {
        isManyLoading,
        isOneLoading,
        listData,
        singleData,
        meta,
        permissions,
        addOne,
        deleteOne,
        editOne,
        fetchMany,
        getOne,
        setManyLoading,
    } = useEntityStore({
        addEntity: addDelivery,
        getEntities: getDeliveries,
        getEntity: getDelivery,
        deleteEntity: deleteDelivery,
        editEntity: editDelivery,
    });

    const {
        selectMeta,
        selectData,
        isSelectLoading,
        selectPrevPaginationParams,
        fetchSelect,
    } = useSelectEntityStore({
        getEntities: getDelivery,
    });

    return {
        // entity
        listData,
        singleData,
        isManyLoading,
        isOneLoading,
        meta,
        permissions,
        fetchMany,
        getOne,
        addOne,
        deleteOne,
        editOne,
        setManyLoading,

        // select
        selectData,
        selectMeta,
        isSelectLoading,
        selectPrevPaginationParams,
        fetchSelect,
    };
});
