export {
    parseISO,
    format,
    formatISO,
    formatDuration,
    formatDistance,
    formatDistanceToNow,
    formatISODuration,
    addSeconds,
    intervalToDuration,
} from "date-fns";
export { parse as parseISODuration } from "iso8601-duration";

import { formatDuration, parseISO, formatISO } from "date-fns";
import ruLocale from "date-fns/locale/ru";

import { isString } from "./types";

export const formatDurationRu = (duration, options) => {
    return formatDuration(duration, { locale: ruLocale, ...options });
};

export const addSecondsToDuration = (duration, amount) => {
    duration.seconds += amount;
    duration.minutes += Math.floor(duration.seconds / 60);
    duration.hours += Math.floor(duration.minutes / 60);
    duration.days += Math.floor(duration.hours / 24);
    // duration.weeks += Math.floor(duration.days / 7);
    duration.months += Math.floor(duration.days / 30);
    duration.years += Math.floor(duration.months / 12);

    if (duration.seconds > 59) {
        duration.seconds %= 60;
    }

    if (duration.minutes > 59) {
        duration.minutes %= 60;
    }

    if (duration.hours > 23) {
        duration.hours %= 24;
    }

    if (duration.days > 29) {
        duration.days %= 30;
    }

    // duration.weeks %= 7;
    if (duration.months > 11) {
        duration.months %= 12;
    }
};

export const formatISODate = (maybeDateOrISOString) => {
    if (isString(maybeDateOrISOString)) {
        maybeDateOrISOString = parseISO(maybeDateOrISOString);
    }

    return formatISO(maybeDateOrISOString, { representation: "date" });
};
