import {
    makeHttpRequest,
    mapDatabaseParamsFromServer,
    mapPaginationParamsToServer,
} from "@/shared";

import { mapInventoryStatusesFromServer } from "./mappers/mapInventoryStatusesFromServer";

export const getInventoryStatuses = async (paginationParams = {}) => {
    const response = (
        await makeHttpRequest({
            url: "/inventory/state",
            method: "GET",
            params: mapPaginationParamsToServer(paginationParams),
        })
    ).data;

    return {
        data: mapInventoryStatusesFromServer(response.data),
        ...mapDatabaseParamsFromServer(response),
    };
};
