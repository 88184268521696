import { defineStore } from "pinia";

import { useEntityStore, useSelectEntityStore } from "@/shared";

import { addContract } from "../api/addContract";
import { deleteContract } from "../api/deleteContract";
import { deleteContracts } from "../api/deleteContracts";
import { editContract } from "../api/editContract";
import { getContract } from "../api/getContract";
import { getContracts } from "../api/getContracts";

export const useContractsStore = defineStore("contracts", () => {
    const {
        isManyLoading,
        isOneLoading,
        listData,
        singleData,
        meta,
        permissions,
        addOne,
        deleteOne,
        deleteMany,
        editOne,
        fetchMany,
        getOne,
        setManyLoading,
    } = useEntityStore({
        addEntity: addContract,
        getEntity: getContract,
        getEntities: getContracts,
        deleteEntity: deleteContract,
        deleteEntities: deleteContracts,
        editEntity: editContract,
    });

    const { fetchSelect, isSelectLoading, selectData } = useSelectEntityStore({
        getEntities: getContracts,
    });

    return {
        // entity
        listData,
        singleData,
        isManyLoading,
        isOneLoading,
        meta,
        permissions,
        fetchMany,
        deleteOne,
        deleteMany,
        getOne,
        editOne,
        addOne,
        setManyLoading,

        // select
        fetchSelect,
        isSelectLoading,
        selectData,
    };
});
