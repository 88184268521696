import { defineStore } from "pinia";

import { useEntityStore } from "@/shared";

import { addRefusalType } from "../api/addRefusalType";
import { deleteRefusalType } from "../api/deleteRefusalType";
import { editRefusalType } from "../api/editRefusalType";
import { getRefusalType } from "../api/getRefusalType";
import { getRefusalTypes } from "../api/getRefusalTypes";

export const useRefusalTypesStore = defineStore("refusalTypes", () => {
    const {
        isManyLoading,
        isOneLoading,
        listData,
        singleData,
        meta,
        permissions,
        addOne,
        deleteOne,
        editOne,
        fetchMany,
        getOne,
        setManyLoading,
    } = useEntityStore({
        addEntity: addRefusalType,
        getEntity: getRefusalType,
        getEntities: getRefusalTypes,
        deleteEntity: deleteRefusalType,
        editEntity: editRefusalType,
    });

    return {
        listData,
        singleData,
        isManyLoading,
        isOneLoading,
        meta,
        permissions,
        fetchMany,
        deleteOne,
        getOne,
        editOne,
        addOne,
        setManyLoading,
    };
});
