import { getDataMapper, mapArray, mapField } from "../../../lib";

export const mapPriceFromServer = getDataMapper({
    id: mapField("id", Number),
    pointId: mapField("point_id", Number),
    title: mapField("title"),
    article_number: mapField("article_number"),
    values: mapField("values", {
        id: mapField("id", Number),
        period: mapField("period"),
        value: mapField("value"),
        moreThen: mapField("more_then"),
        isFixed: mapField("is_fixed", Boolean),
        priceId: mapField("price_id", Number),
        sortId: mapField("sort_id", Number),
    }),
});

export const mapPricesFromServer = mapArray(mapPriceFromServer);
