import { makeHttpRequest } from "@/shared";

import { mapInventoryPriceFromServer } from "./mappers/mapInventoryPricesFromServer";

export const getInventoryPrice = async (id) => {
    const response = (
        await makeHttpRequest({
            url: `/inventory/price/${id}`,
            method: "GET",
        })
    ).data;

    return mapInventoryPriceFromServer(response.data);
};
