import {
    getDataMapper,
    mapField,
    mapMediaToServer,
    mapContactToServer,
} from "@/shared";

export const mapDirectorToServer = getDataMapper({
    id: mapField("id"),
    human: mapField(
        "human",
        getDataMapper({
            id: mapField("id"),
            name: mapField("name"),
            surname: mapField("surname"),
            patro: mapField("patro"),
            vatin: mapField("vatin"),
            number: mapField("number"),
            user: mapField(
                "user",
                getDataMapper({
                    id: mapField("id"),
                    login: mapField("login"),
                    password: mapField("password"),
                })
            ),
            company: mapField(
                "company",
                getDataMapper({
                    title: mapField("title"),
                    address: mapField("address"),
                })
            ),
        })
    ),
    contacts: mapField("contacts", mapContactToServer),
    address: mapField("address"),
    media: mapField("media", mapMediaToServer),
});
