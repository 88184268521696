import {
    makeHttpRequest,
    mapPaginationParamsToServer,
    mapDatabaseParamsFromServer,
    isArray,
} from "@/shared";

import { mapStoreSellingsFromServer } from "./mappers/mapStoreSellingsFromServer";

export const getStoreSellings = async (paginationParams, filters) => {
    const response = (
        await makeHttpRequest({
            url: "stock",
            method: "GET",
            params: {
                ...mapPaginationParamsToServer(paginationParams),
                point_id: isArray(filters.points)
                    ? filters.points.map(({ id }) => id)
                    : null,
            },
        })
    ).data;

    return {
        data: mapStoreSellingsFromServer(response.data),
        ...mapDatabaseParamsFromServer(response),
    };
};
