import { makeHttpRequest } from "@/shared";

export const deleteBreakings = async (ids) => {
    const response = (
        await makeHttpRequest({
            url: "/broken/delete-multiple",
            method: "DELETE",
            params: { items: ids },
        })
    ).data;

    return response;
};
