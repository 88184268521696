import { makeHttpRequest, mapEmployeeFromServer } from "@/shared";

import { mapEmployeeToServer } from "./mappers/mapEmployeesToServer";

export const createEmployee = async (employee) => {
    const response = (
        await makeHttpRequest({
            url: "/human/employee",
            method: "POST",
            data: mapEmployeeToServer(employee),
        })
    ).data;

    return mapEmployeeFromServer(response.data);
};
