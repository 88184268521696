import { makeHttpRequest } from "@/shared";

import { mapServiceToServer } from "./mappers/mapServiceToServer";

export const editService = async (id, service) => {
    const response = (
        await makeHttpRequest({
            url: `/service/${id}`,
            method: "PUT",
            data: mapServiceToServer(service),
        })
    ).data;

    return response;
};
