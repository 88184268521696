import { makeHttpRequest } from "@/shared";

export const deleteServices = async (ids) => {
    const response = (
        await makeHttpRequest({
            url: "/service/delete-multiple",
            method: "DELETE",
            params: {
                items: ids,
            },
        })
    ).data;

    return response;
};
