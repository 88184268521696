import { defineStore } from "pinia";

import { useEntityStore } from "@/shared";

import { deleteSelling } from "../api/deleteSelling";
import { deleteSellings } from "../api/deleteSellings";
import { getStoreSellings } from "../api/getStoreSellings";

export const useStoreSellingsStore = defineStore("storeSellings", () => {
    const {
        isManyLoading,
        listData,
        meta,
        permissions,
        fetchMany,
        deleteOne,
        deleteMany,
        setManyLoading,
    } = useEntityStore({
        getEntities: getStoreSellings,
        deleteEntity: deleteSelling,
        deleteEntities: deleteSellings,
    });

    return {
        listData,
        isManyLoading,
        meta,
        permissions,
        fetchMany,
        deleteOne,
        deleteMany,
        setManyLoading,
    };
});
