import { AUTHENTICATION_ROUTES, isAuthRoute } from "./authentication";
import { CLIENT_ROUTES } from "./client";
import { DASHBOARD_ROUTES } from "./dashboard";
import { DELIVERY_ROUTES } from "./delivery";
import { EMPLOYEE_ROUTES } from "./employee";
import { EXPENSE_ROUTES } from "./expense";
import { GENERAL_ROUTES } from "./general";
import { INVENTORY_ROUTES } from "./inventory";
import { KIT_ROUTES } from "./kit";
import { TARIFF_ROUTES } from "./payment";
import { PROFILE_ROUTES } from "./profile";
import { RENT_ROUTES } from "./rent";
import { RENT_BOOKING_ROUTES } from "./rent-booking";
import { REPAIR_ROUTES } from "./repair";
import { REPORTS_ROUTES } from "./reports";
import { SALARY_ROUTES } from "./salary";
import { SETTINGS_ROUTES } from "./settings";
import { STORE_ROUTES } from "./store";

export const ROUTES = [
    ...AUTHENTICATION_ROUTES,
    ...CLIENT_ROUTES,
    ...EMPLOYEE_ROUTES,
    ...SALARY_ROUTES,
    ...EXPENSE_ROUTES,
    ...INVENTORY_ROUTES,
    ...KIT_ROUTES,
    ...TARIFF_ROUTES,
    ...PROFILE_ROUTES,
    ...RENT_ROUTES,
    ...RENT_BOOKING_ROUTES,
    ...REPAIR_ROUTES,
    ...REPORTS_ROUTES,
    ...SETTINGS_ROUTES,
    ...GENERAL_ROUTES,
    ...STORE_ROUTES,
    ...DASHBOARD_ROUTES,
    ...DELIVERY_ROUTES,
];

// Helpers for routing
export { isAuthRoute };
