import { makeHttpRequest } from "@/shared";

import { mapServiceToServer } from "./mappers/mapServiceToServer";

export const addService = async (service) => {
    const response = (
        await makeHttpRequest({
            url: "/service",
            method: "POST",
            data: mapServiceToServer(service),
        })
    ).data;

    return response;
};
