import {
    getDataMapper,
    mapField,
    composeFields,
    formatISO,
    getObjectId,
} from "@/shared";

export const mapRentFiltersToServer = getDataMapper({
    time: composeFields(["dateFrom", "dateTo"], ([dateFrom, dateTo]) => {
        return {
            time_from: dateFrom ? formatISO(dateFrom) : null,
            time_to: dateTo ? formatISO(dateTo) : null,
        };
    }),
    rent_state_id: mapField("rentStatuses", getObjectId),
    point_id: mapField("points", getObjectId),
    pay_type_id: mapField("paymentType", Number),
    additional_service_id: mapField("additionalServices", getObjectId),
    hide_closed_rents: mapField("shouldHideClosed", Boolean),
    has_debts: mapField("hasDebts", Boolean),
    has_delivery: mapField("hasDelivery", Boolean),
    client_human_id: mapField("clientHumanId", Number),
    admin_human_id: mapField("adminHumanId", Number),
    inventory_id: mapField("inventoryId", Number),
    kit_id: mapField("kitId", Number),
    admin_user_id: mapField("adminUser", (adminUser) => adminUser.userId),
});
