import {
    makeHttpRequest,
    mapDatabaseParamsFromServer,
    mapPaginationParamsToServer,
} from "@/shared";

import { mapDiscountsFiltersDataToServer } from "./mappers/mapDiscountsFiltersDataToServer";
import { mapDiscountsFromServer } from "./mappers/mapDiscountsFromServer";

export const getDiscounts = async (paginationParams = {}, filtersData = {}) => {
    if (filtersData.isSearchingByCode) {
        filtersData.searchCode = paginationParams.search;
        paginationParams.search = null;
    }

    const response = (
        await makeHttpRequest({
            url: "/discount",
            method: "GET",
            params: {
                ...mapPaginationParamsToServer(paginationParams),
                ...mapDiscountsFiltersDataToServer(filtersData),
            },
        })
    ).data;

    return {
        data: mapDiscountsFromServer(response.data),
        ...mapDatabaseParamsFromServer(response),
    };
};
