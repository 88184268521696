import { makeHttpRequest } from "@/shared";

import { mapDepositToServer } from "./mappers/mapDepositToServer";

export const editDeposit = async (id, deposit) => {
    return (
        await makeHttpRequest({
            url: `/rent/deposit/${id}`,
            method: "PUT",
            data: mapDepositToServer(deposit),
        })
    ).data;
};
