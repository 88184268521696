export default {
    id: {
        type: String,
        default: "",
    },
    type: {
        type: String,
        default: "",
    },
    placeholder: {
        type: String,
        default: "",
    },
    label: {
        type: String,
        default: "",
    },
    name: {
        type: String,
        default: "",
    },
    required: {
        type: Boolean,
        default: false,
    },
    disabled: {
        type: Boolean,
        deafult: false,
    },
    inputClass: {
        type: Object,
        default: () => {
            return {};
        },
    },
    autocomplete: {
        type: String,
        default: "on",
    },
    size: {
        type: String,
        validator(value) {
            return ["xs", "md", "lg"].includes(value);
        },
        default: "md",
    },
    isError: {
        type: Boolean,
        default: false,
    },
};
