import { getDataMapper, mapField, formatISO } from "@/shared";

export const mapPaymentRepairToServer = getDataMapper(
    {
        pay_type_id: mapField("id", Number),
        value: mapField("value", Number),
        payment_date: mapField("date", formatISO),
    },
    { shouldAvoidUndefined: true, shouldAvoidNull: true }
);
