import { makeHttpRequest } from "@/shared";

import { mapCalculatedRepairFromServer } from "./mappers/mapCalculatedRepairFromServer";

export const changeRepairStatus = async (sessionKey, status) => {
    const response = (
        await makeHttpRequest({
            url: `/repair/builder/${sessionKey}/add/state/${status.id}`,
            method: "PUT",
        })
    ).data;

    return mapCalculatedRepairFromServer(response.data);
};
