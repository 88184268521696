import { makeHttpRequest } from "@/shared";

import { mapRefusalTypeFromServer } from "./mappers/mapRefusalTypesFromServer";

export const getRefusalType = async (id) => {
    const response = (
        await makeHttpRequest({
            url: `/delivery/refusal-type/${id}`,
            method: "GET",
        })
    ).data;

    return mapRefusalTypeFromServer(response.data);
};
