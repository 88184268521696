import {
    getDataMapper,
    mapField,
    mapArray,
    mapEmployeeFromServer,
} from "@/shared";

export const mapSalaryFromServer = getDataMapper({
    id: mapField("id", Number),
    wage: mapField("wage"),
    percent: mapField("percent"),
    employee: mapField("employee", mapEmployeeFromServer),
    salaryType: mapField("salary_type"),
    salaryTypeId: mapField("salary_type", (item) => item.id),
    bonus: mapField("bonus", Number),
    sum: mapField("sum", Number),
});

export const mapSalariesFromServer = mapArray(mapSalaryFromServer);
