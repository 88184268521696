import { getDataMapper, mapArray, mapField } from "../../../lib";

export const mapPriceToServer = getDataMapper({
    id: mapField("id", Number),
    point_id: mapField("pointId", Number),
    title: mapField("title"),
    article_number: mapField("article"),
    values: mapField("values", {
        id: mapField("id", Number),
        period: mapField("period"),
        value: mapField("value"),
        more_then: mapField("moreThen"),
        is_fixed: mapField("isFixed", Boolean),
        price_id: mapField("priceId", Number),
        sort_id: mapField("sortId", Number),
    }),
});

export const mapPricesToServer = mapArray(mapPriceToServer);
