import { getDataMapper, mapArray, mapField } from "@/shared";

function TariffData() {
    this.baseCost = mapField("base_cost", Number);
    this.children = mapField("children", this);
    this.cost = mapField("cost", Number);
    this.haveAccessRepairService = mapField(
        "have_access_repair_service",
        Boolean
    );
    this.haveAccessStore = mapField("have_access_store", Boolean);
    this.id = mapField("id", Number);
    this.limitClients = mapField("limit_clients", Number);
    this.limitEmployees = mapField("limit_employees", Number);
    this.limitInventories = mapField("limit_inventories", Number);
    this.limitPoints = mapField("limit_points", Number);
    this.parentId = mapField("parent_id", Number);
    this.parentTitle = mapField("parent_title", String);
    this.period = mapField("period", String);
    this.sortId = mapField("sort_id", Number);
    this.timeDisactive = mapField("time_disactive", String);
    this.title = mapField("title", String);
}

export const mapTariffFromServer = getDataMapper(new TariffData());

export const mapTariffsListFromServer = mapArray(mapTariffFromServer);
