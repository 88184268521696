<script setup>
import { computed } from "vue";

import menuProps from "./config/menuProps";

const props = defineProps({
    ...menuProps,
});

const dropdownMenuClasses = computed(() => {
    const dropdownMenuRight = `base-dropdown-menu__${props.right && "right"}`;
    const dropdownMenuMaxHeight = `base-dropdown-menu__${
        props.maxHeight && "max-height"
    }`;

    const arrayClasses = [];

    props.right && arrayClasses.push(dropdownMenuRight);
    props.maxHeight && arrayClasses.push(dropdownMenuMaxHeight);

    return arrayClasses;
});
</script>

<template>
    <ul class="base-dropdown-menu" :class="dropdownMenuClasses">
        <li
            class="base-dropdown-menu__cross"
            v-if="hasCross"
            @click="$emit('close')"
        ></li>
        <slot>
            <li
                class="base-dropdown-menu__item"
                v-for="link in links"
                :key="link.id"
                @click="$emit('close')"
            >
                <RouterLink :to="link.link">
                    {{ link.title }}
                </RouterLink>
            </li>
        </slot>
    </ul>
</template>

<style lang="scss" scoped>
.base-dropdown-menu {
    --dropdown-min-width: 200px;
    --dropdown-padding-x: 10px;
    --dropdown-padding-y: 24px;
    --dropdown-border-radius: 5px;

    min-width: var(--dropdown-min-width);
    padding: var(--dropdown-padding-y) var(--dropdown-padding-x);
    overflow: auto;

    background-color: var(--c-neutral-10);

    border-radius: var(--dropdown-border-radius);
    box-shadow: 0 0 5px rgb(var(--c-rgb-neutral-70) / 20%);

    &__right {
        right: v-bind(right);
    }

    &__max-height {
        max-height: v-bind(maxHeight);
    }

    &__cross {
        position: absolute;
        top: 10px;
        right: 10px;

        display: block;

        width: 15px;
        height: 15px;

        cursor: pointer;

        opacity: 0.7;

        &:hover {
            opacity: 0.8;
        }

        &::before,
        &::after {
            position: absolute;
            left: 7.5px;

            width: 2px;
            height: 15px;

            content: " ";

            background-color: #333;
        }

        &::before {
            transform: rotate(45deg);
        }

        &::after {
            transform: rotate(-45deg);
        }
    }

    & > li:not(&__cross),
    :slotted(li) {
        padding: 4px 20px;

        font-weight: 400;
        line-height: var(--default-line-height);

        color: var(--c-text);
        text-align: left;

        border-radius: var(--default-small-border-radius);

        &:hover {
            cursor: pointer;

            background-color: var(--c-neutral-12) !important;
        }
    }
}
</style>
