import { makeHttpRequest } from "@/shared";

import { mapCalculatedRentFromServer } from "./mappers/mapCalculatedRentFromServer";
import { mapInventoryBreakingToServer } from "./mappers/mapInventoryBreakingToServer";

export const addInventoryBreaking = async (sessionKey, data) => {
    const response = (
        await makeHttpRequest({
            url: `rent/builder/${sessionKey}/add/broken`,
            method: "PUT",
            data: mapInventoryBreakingToServer(data),
        })
    ).data;

    return mapCalculatedRentFromServer(response.data);
};
