<script setup>
import { useVModel } from "@vueuse/core";
import { ref } from "vue";

import { IconEyeTabler, IconEyeOffTabler } from "../../icons";

import BaseInput from "./BaseInput.vue";
import importedProps from "./config/props";

const props = defineProps({
    modelValue: null,
    ...importedProps,
});
const emit = defineEmits(["update:modelValue", "blur", "change"]);

const modelValue = useVModel(props, "modelValue", emit);

const type = ref(props.type);

const changeTypeField = () => {
    if (type.value === "password") {
        type.value = "text";
    } else {
        type.value = "password";
    }
};
</script>

<template>
    <div class="base-form-group" :class="`base-form-group__${props.size}`">
        <label v-if="label" class="base-form-group__label" :for="id">
            {{ label }}
            <span v-if="required">*</span>
        </label>
        <div class="base-form-group__input">
            <slot name="input" v-bind="props">
                <BaseInput
                    v-bind="$attrs"
                    v-model="modelValue"
                    :inputClass="inputClass"
                    :size="size"
                    :type="type"
                    :id="id"
                    :name="name"
                    :placeholder="placeholder"
                    :autocomplete="autocomplete"
                    :disabled="disabled"
                    :is-error="isError"
                    @blur="(e) => $emit('blur', e)"
                    @change="(e) => $emit('change', e)"
                />
            </slot>
            <div
                v-if="props.type === 'password'"
                class="base-form-group__password-hide-icon"
                @click="changeTypeField"
            >
                <IconEyeTabler v-if="type === 'password'" />
                <IconEyeOffTabler v-else-if="type === 'text'" />
            </div>
        </div>
        <div class="base-form-group__additional">
            <slot name="additional" />
        </div>
    </div>
</template>

<style lang="scss" scoped>
.base-form-group {
    display: flex;

    flex-direction: column;

    width: 100%;

    line-height: var(--default-line-height);

    color: var(--input-text-color);

    &__label {
        padding-bottom: 2px;
        margin: 0;

        font-size: var(--base-form-group-label-text-size);
        text-align: left;
    }

    &__input {
        position: relative;

        width: 100%;
    }

    &__password-hide-icon {
        position: absolute;

        top: 50%;
        right: 20px;
        z-index: 1;

        width: 18px;
        height: 18px;

        cursor: pointer;

        transform: translateY(-50%);

        & > * {
            width: 100%;
            height: 100%;
        }
    }

    &__error {
        font-size: var(--base-form-group-text-size-error);
        color: var(--input-color-error);
        text-align: left;
    }

    &__additional {
        font-size: var(--base-form-group-text-size-error);
        text-align: left;

        & * {
            margin-bottom: 0;
        }
    }

    &__sm {
        --base-form-group-label-text-size: var(--input-label-text-size);
        --base-form-group-text-size-error: var(--input-text-size-error);
    }

    &__md {
        --base-form-group-label-text-size: var(--input-label-text-size);
        --base-form-group-text-size-error: var(--input-text-size-error);
    }

    &__lg {
        --base-form-group-label-text-size: calc(
            var(--input-label-text-size) + 4px
        );
        --base-form-group-text-size-error: calc(
            var(--input-text-size-error) + 4px
        );
    }
}
</style>
