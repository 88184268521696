import { defineStore } from "pinia";

import { useSelectEntityStore } from "@/shared";

import { getEmployeePositions } from "../api/getEmployeePositions";

export const useEmployeePositionsStore = defineStore(
    "employeePositions",
    () => {
        const { selectData, selectMeta, isSelectLoading, fetchSelect } =
            useSelectEntityStore({
                getEntities: getEmployeePositions,
            });

        return {
            // select
            selectData,
            selectMeta,
            isSelectLoading,
            fetchSelect,
        };
    }
);
