import { makeHttpRequest } from "@/shared";

import { mapSourceAttractionToServer } from "./mappers/mapSourceAttractionToServer";

export const editSourceAttraction = async (id, sourceAttraction) => {
    const response = (
        await makeHttpRequest({
            url: `/attraction/${id}`,
            method: "PUT",
            data: mapSourceAttractionToServer(sourceAttraction),
        })
    ).data;

    return response;
};
