import { makeHttpRequest } from "@/shared";

export const deleteInventories = async (ids) => {
    return (
        await makeHttpRequest({
            url: `/inventory/delete-multiple`,
            method: "DELETE",
            params: { items: ids },
        })
    ).data;
};
