import { getDataMapper, mapField, formatISODate, getObjectId } from "@/shared";

export const mapExpenseFiltersToServer = getDataMapper(
    {
        point_id: mapField("point", getObjectId),
        inventory_id: mapField("inventory", getObjectId),
        expense_type_id: mapField("expenseType", getObjectId),
        period_from: mapField("periodStart", formatISODate),
        period_to: mapField("periodEnd", formatISODate),
    },
    {
        shouldAvoidUndefined: true,
        shouldAvoidNull: true,
    }
);
