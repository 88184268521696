/* eslint-disable node/no-unsupported-features/es-syntax */
import { REPAIR_ROUTE_NAMES } from "@/shared";
export const REPAIR_ROUTES = [
    {
        name: REPAIR_ROUTE_NAMES.ORDER.LIST,
        path: "/repair",
        meta: {
            title: "Заявки",
        },
        props: (route) => ({
            ...route.params,
            ...route.query,
        }),
        component: () => import("../ui/RepairList.vue"),
    },
    {
        name: REPAIR_ROUTE_NAMES.ORDER.ADD,
        path: "/repair/add",
        meta: {
            title: "Добавление заявки",
        },
        component: () => import("../ui/RepairEdit.vue"),
    },
    {
        name: REPAIR_ROUTE_NAMES.ORDER.EDIT,
        path: "/repair/edit/:id",
        meta: {
            title: "Редактирование заявки",
        },
        props: true,
        component: () => import("../ui/RepairEdit.vue"),
    },
    {
        name: REPAIR_ROUTE_NAMES.SERVICE.LIST,
        path: "/repair/service",
        meta: {
            title: "Услуги",
        },
        component: () => import("../ui/ServicePage.vue"),
    },
    {
        name: REPAIR_ROUTE_NAMES.SERVICE.ADD,
        path: "/repair/service/add",
        meta: {
            title: "Добавить услугу",
        },
        component: () => import("../ui/ServiceEdit.vue"),
    },
    {
        name: REPAIR_ROUTE_NAMES.SERVICE.EDIT,
        path: "/repair/service/:id",
        meta: {
            title: "Добавить услугу",
        },
        props: true,
        component: () => import("../ui/ServiceEdit.vue"),
    },
];
