import { defineStore } from "pinia";

import { useEntityStore } from "@/shared";

import { getPaymentsHistory } from "../api/getPaymentsHistory.js";

export const usePaymentsHistoryStore = defineStore("paymentsHistory", () => {
    const { isManyLoading, listData, meta, fetchMany } = useEntityStore({
        getEntities: getPaymentsHistory,
    });

    return {
        // entity
        listData,
        meta,
        isManyLoading,
        fetchMany,
    };
});
