import { makeHttpRequest } from "@/shared";

export const deleteClients = async (ids) => {
    const response = (
        await makeHttpRequest({
            url: "/human/client/delete-multiple",
            params: { items: ids },
            method: "DELETE",
        })
    ).data;

    return response;
};
