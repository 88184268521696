import { makeHttpRequest } from "@/shared";

export const deleteRents = async (ids) => {
    const response = (
        await makeHttpRequest({
            url: `/rent/delete-multiple`,
            method: "DELETE",
            params: {
                items: ids,
            },
        })
    ).data;

    return response;
};
