import { getDataMapper, mapField, getObjectId } from "@/shared";

export const mapSellingAdditionalToServer = getDataMapper(
    {
        comment: mapField("comment"),
        admin_user_id: mapField("adminUser", (adminUser) => adminUser.userId),
        point_id: mapField("point", getObjectId),
    },
    { shouldAvoidUndefined: true, shouldAvoidNull: true }
);
