import { makeHttpRequest } from "@/shared";

import { mapCalculatedSellingFromServer } from "./mappers/mapCalculatedSellingFromServer";
import { mapPaymentSellingToServer } from "./mappers/mapPaymentSellingToServer";

export const removePaymentFromSelling = async (sessionKey, payment) => {
    const response = (
        await makeHttpRequest({
            url: `/stock/builder/${sessionKey}/remove/payment`,
            method: "DELETE",
            data: mapPaymentSellingToServer(payment),
        })
    ).data;

    return mapCalculatedSellingFromServer(response.data);
};
