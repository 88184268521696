import {
    mapHumanToServer,
    mapContactsToServer,
    mapCompanyToServer,
    mapMediaToServer,
    getDataMapper,
    mapField,
} from "@/shared";

export const mapClientToServer = getDataMapper({
    id: mapField("id", Number),
    created_at: mapField("createdAt"),
    updated_at: mapField("updatedAt"),
    address: mapField("address"),
    comment: mapField("comment"),
    black_list: mapField("blackList", Boolean),
    source_attraction_id: mapField("sourceAttraction", (obj) => obj.id),
    is_legal_entity: mapField("isLegalEntity", Boolean),
    human: mapField("human", mapHumanToServer),
    contacts: mapField("contacts", mapContactsToServer, {
        applyToElements: false,
    }),
    debts: mapField("debts", Number),
    discounts: mapField("discounts", (obj) => obj.id),
    number: mapField("number"),
    company: mapField("company", mapCompanyToServer),
    media: mapField("media", mapMediaToServer),
    has_contract: mapField("hasContract", Boolean),
    has_deposit: mapField("hasDeposit", Boolean),
});
