import { makeHttpRequest } from "@/shared";

import { mapCalculatedRentFromServer } from "./mappers/mapCalculatedRentFromServer";
import { mapDepositRentToServer } from "./mappers/mapDepositRentToServer";

export const updateRentDeposit = async (sessionKey, deposit) => {
    const response = (
        await makeHttpRequest({
            url: `/rent/builder/${sessionKey}/update/deposit`,
            method: "PUT",
            data: mapDepositRentToServer(deposit),
        })
    ).data;

    return mapCalculatedRentFromServer(response.data);
};
