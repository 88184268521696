import { getDataMapper, mapField, mapMediaToServer, format } from "@/shared";

export const mapExpenseToServer = getDataMapper(
    {
        id: mapField("id", Number),
        name: mapField("name"),
        value: mapField("value", Number),
        expense_type_id: mapField("expenseTypeId", Number),
        point_id: mapField("pointId", Number),
        inventory_id: mapField("inventoryId", Number),
        comment: mapField("comment"),
        date: mapField("date", (date) => format(date, "yyyy-MM-dd")),
        media: mapField("media", mapMediaToServer),
    },
    {
        shouldAvoidUndefined: true,
    }
);
