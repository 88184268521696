/* eslint-disable node/no-unsupported-features/es-syntax */
import { STORE_ROUTE_NAMES } from "@/shared";
export const STORE_ROUTES = [
    {
        name: STORE_ROUTE_NAMES.PRODUCT.MAIN,
        path: "/store/product",
        meta: {
            title: "Товары",
        },
        component: () => import("../ui/ProductsPage.vue"),
    },
    {
        name: STORE_ROUTE_NAMES.PRODUCT.ADD,
        path: "/store/product/add",
        meta: {
            title: "Добавление товара",
        },
        component: () => import("../ui/ProductsEdit.vue"),
    },
    {
        name: STORE_ROUTE_NAMES.PRODUCT.EDIT,
        path: "/store/product/edit/:id",
        meta: {
            title: "Редактирование товара",
        },
        component: () => import("../ui/ProductsEdit.vue"),
        props: true,
    },
    {
        name: STORE_ROUTE_NAMES.STOCK.MAIN,
        path: "/store",
        meta: {
            title: "Товары",
        },
        component: () => import("../ui/StorePage.vue"),
    },
    {
        name: STORE_ROUTE_NAMES.STOCK.ADD,
        path: "/store/add",
        meta: {
            title: "Добавление товара",
        },
        component: () => import("../ui/StoreEdit.vue"),
    },
    {
        name: STORE_ROUTE_NAMES.STOCK.EDIT,
        path: "/store/edit/:id",
        meta: {
            title: "Редактирование товара",
        },
        component: () => import("../ui/StoreEdit.vue"),
        props: true,
    },
];
