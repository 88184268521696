import { makeHttpRequest } from "@/shared";

import { mapHumanTariffFromServer } from "./mappers/mapHumanTariffFromServer";
import { mapTariffStatisticFromServer } from "./mappers/mapTariffStatisticFromServer";

export const getActiveTariff = async () => {
    const response = (
        await makeHttpRequest({
            url: "/director/active-tariff",
            method: "GET",
        })
    ).data.data;

    return {
        tariff: mapHumanTariffFromServer(response.tariff),
        statistic: mapTariffStatisticFromServer(response.statistics),
        isFree: response.is_free,
    };
};
