import { makeHttpRequest } from "@/shared";

import { mapRepairStatusFromServer } from "./mappers/mapRepairStatusesFromServer";

export const getRepairStatus = async (id) => {
    const response = (
        await makeHttpRequest({
            url: `/repair/state/${id}`,
            method: "GET",
        })
    ).data;

    return mapRepairStatusFromServer(response.data);
};
