import { makeHttpRequest } from "@/shared";

import { mapPointToServer } from "./mappers/mapPointToServer";

export const addPoint = async (point) => {
    const response = (
        await makeHttpRequest({
            url: "/point",
            method: "POST",
            data: mapPointToServer(point),
        })
    ).data;

    return response;
};
