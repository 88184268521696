import { makeHttpRequest, mapEmployeeFromServer } from "@/shared";

import { mapEmployeeToServer } from "./mappers/mapEmployeesToServer";

export const editEmployee = async (employeeId, employee) => {
    const response = (
        await makeHttpRequest({
            url: `/human/employee/${employeeId}`,
            method: "PUT",
            data: mapEmployeeToServer(employee),
        })
    ).data;

    return { employee: mapEmployeeFromServer(response.data) };
};
