import { makeHttpRequest } from "@/shared";

import { mapAdditionalServiceToServer } from "./mappers/mapAdditionalServiceToServer";

export const editAdditionalService = async (id, breaking) => {
    const response = (
        await makeHttpRequest({
            url: `/service/additional/${id}`,
            method: "PUT",
            data: mapAdditionalServiceToServer(breaking),
        })
    ).data;

    return response;
};
