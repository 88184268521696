import { makeHttpRequest } from "@/shared";

import { mapInventoryStatusFromServer } from "./mappers/mapInventoryStatusesFromServer";

export const getInventoryStatus = async (id) => {
    const response = (
        await makeHttpRequest({
            url: `/inventory/state/${id}`,
            method: "GET",
        })
    ).data;

    return mapInventoryStatusFromServer(response.data);
};
