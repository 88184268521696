export const mountEmptyElement = () => {
    const emptyElement = document.querySelector(".education__empty-element");

    if (emptyElement) return emptyElement;

    const element = document.createElement("div");

    element.classList.add("education__empty-element");
    document.body.appendChild(element);

    return element;
};
