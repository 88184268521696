import { makeHttpRequest } from "@/shared";

import { mapCalculatedSellingFromServer } from "./mappers/mapCalculatedSellingFromServer";

export const prepareSelling = async (id) => {
    const response = (
        await makeHttpRequest({
            url: `/stock/builder/prepare${id ? `/${id}` : ""}`,
            method: "POST",
            data: {
                currency_id: null,
            },
        })
    ).data;

    return {
        data: mapCalculatedSellingFromServer(response.stock),
        sellingSessionKey: response.stockSessionKey,
    };
};
