import { makeHttpRequest } from "@/shared";

import { mapProductToServer } from "./mappers/mapProductToServer";

export const addProduct = async (product) => {
    const response = (
        await makeHttpRequest({
            url: "/product",
            method: "POST",
            data: mapProductToServer(product),
        })
    ).data;

    return response;
};
