import { getDataMapper, mapField } from "@/shared";

export const mapFDocLeadDataToServer = getDataMapper({
    mailing_check: mapField("mailingCheck", Boolean),
    comment: mapField("comment"),
    company_inn: mapField("companyInn"),
    comp_name: mapField("compName"),
    contact_phone: mapField("contactPhone"),
    contact_name: mapField("contactName"),
    contact_email: mapField("contactEmail"),
});
