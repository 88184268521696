import {
    makeHttpRequest,
    mapDatabaseParamsFromServer,
    mapPaginationParamsToServer,
} from "@/shared";

import { mapDepositsFromServer } from "./mappers/mapDepositsFromServer";

export const getDeposits = async (paginationParams = {}) => {
    const response = (
        await makeHttpRequest({
            url: "/rent/deposit",
            method: "GET",
            params: mapPaginationParamsToServer(paginationParams),
        })
    ).data;

    return {
        data: mapDepositsFromServer(response.data),
        ...mapDatabaseParamsFromServer(response),
    };
};
