import { defineStore } from "pinia";

import { useEntityStore } from "@/shared";

import { addService } from "../api/addService";
import { deleteService } from "../api/deleteService";
import { deleteServices } from "../api/deleteServices";
import { editService } from "../api/editService";
import { getService } from "../api/getService";
import { getServices } from "../api/getServices";

export const useServicesStore = defineStore("servicesStore", () => {
    const {
        isManyLoading,
        isOneLoading,
        listData,
        singleData,
        meta,
        permissions,
        fetchMany,
        addOne,
        getOne,
        deleteOne,
        deleteMany,
        editOne,
        setManyLoading,
    } = useEntityStore({
        addEntity: addService,
        getEntity: getService,
        getEntities: getServices,
        deleteEntity: deleteService,
        deleteEntities: deleteServices,
        editEntity: editService,
    });

    return {
        listData,
        singleData,
        isManyLoading,
        isOneLoading,
        meta,
        permissions,
        fetchMany,
        addOne,
        getOne,
        deleteOne,
        deleteMany,
        editOne,
        setManyLoading,
    };
});
