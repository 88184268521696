import {
    makeHttpRequest,
    mapPaginationParamsToServer,
    mapDatabaseParamsFromServer,
} from "@/shared";

import { mapRentFiltersToServer } from "./mappers/mapRentFiltersToServer";
import { mapRentsFromServer } from "./mappers/mapRentsFromServer";

export const getRents = async (paginationParams = {}, filters = {}) => {
    const response = (
        await makeHttpRequest({
            url: "/rent",
            method: "GET",
            params: {
                ...mapPaginationParamsToServer(paginationParams),
                ...mapRentFiltersToServer(filters),
            },
        })
    ).data;

    return {
        data: mapRentsFromServer(response.data),
        ...mapDatabaseParamsFromServer(response),
    };
};
