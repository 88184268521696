import { makeHttpRequest } from "@/shared";

import { mapContractToServer } from "./mappers/mapContractToServer";

export const editContract = async (id, contract) => {
    const response = (
        await makeHttpRequest({
            url: `order/${id}`,
            method: "PUT",
            data: mapContractToServer(contract),
        })
    ).data;

    return response;
};
