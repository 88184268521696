import {
    makeHttpRequest,
    mapDatabaseParamsFromServer,
    mapPaginationParamsToServer,
} from "@/shared";

import { mapServicesFromServer } from "./mappers/mapServicesFromServer";

export const getServices = async (paginationParams = {}) => {
    const response = (
        await makeHttpRequest({
            url: "/service",
            method: "GET",
            params: mapPaginationParamsToServer(paginationParams),
        })
    ).data;

    return {
        data: mapServicesFromServer(response.data),
        ...mapDatabaseParamsFromServer(response),
    };
};
