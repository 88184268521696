import { defineStore } from "pinia";

import { useSelectEntityStore, useEntityStore } from "@/shared";

import { getCategoriesProduct } from "../api/getCategoriesProduct";

export const useCategoriesProductStore = defineStore(
    "productCategories",
    () => {
        const { listData, meta, isManyLoading, permissions, fetchMany } =
            useEntityStore({
                getEntities: getCategoriesProduct,
            });

        const {
            selectMeta,
            selectData,
            isSelectLoading,
            selectPrevPaginationParams,
            fetchSelect,
        } = useSelectEntityStore({
            getEntities: getCategoriesProduct,
        });

        return {
            // entity
            listData,
            meta,
            isManyLoading,
            permissions,
            fetchMany,

            // select
            selectData,
            selectMeta,
            isSelectLoading,
            selectPrevPaginationParams,
            fetchSelect,
        };
    }
);
