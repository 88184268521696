import { makeHttpRequest } from "@/shared";

import { mapRentsToCancelToServer } from "./mappers/mapRentsToCancelToServer";

export const cancelRents = async (rents) => {
    const response = (
        await makeHttpRequest({
            url: "/rent/cancel-multiple",
            method: "POST",
            data: {
                rents: mapRentsToCancelToServer(rents),
            },
        })
    ).data;

    return response;
};
