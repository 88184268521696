import { getDataMapper, mapField } from "@/shared";

const mapServiceReviseFromServer = getDataMapper({
    sumTotal: mapField("sum_total", Number),
    sumCard: mapField("sum_card", Number),
    sumCash: mapField("sum_cash", Number),
    count: mapField("count"),
});

export const mapReviseFromServer = getDataMapper({
    cleanIncome: mapField("clean_income", Number),
    countContracts: mapField("count_contracts", Number),
    expense: mapField("expense", Number),
    income: mapField("income", mapServiceReviseFromServer),
    rent: mapField("rent", mapServiceReviseFromServer),
    repairService: mapField("repair_service", mapServiceReviseFromServer),
    stock: mapField("stock", mapServiceReviseFromServer),
});
