import { defineStore } from "pinia";

import { useEntityStore } from "@/shared";

import { addExpense } from "../api/addExpense";
import { deleteExpense } from "../api/deleteExpense";
import { deleteExpenses } from "../api/deleteExpenses";
import { editExpense } from "../api/editExpense";
import { getExpense } from "../api/getExpense";
import { getExpenses } from "../api/getExpenses";

export const useExpensesStore = defineStore("expenses", () => {
    const {
        isManyLoading,
        isOneLoading,
        listData,
        singleData,
        meta,
        permissions,
        addOne,
        deleteOne,
        deleteMany,
        editOne,
        fetchMany,
        getOne,
        setManyLoading,
    } = useEntityStore({
        addEntity: addExpense,
        getEntity: getExpense,
        getEntities: getExpenses,
        deleteEntity: deleteExpense,
        deleteEntities: deleteExpenses,
        editEntity: editExpense,
    });

    return {
        listData,
        singleData,
        isManyLoading,
        isOneLoading,
        meta,
        permissions,
        fetchMany,
        deleteOne,
        deleteMany,
        getOne,
        editOne,
        addOne,
        setManyLoading,
    };
});
