import { makeHttpRequest } from "@/shared";

import { mapKitFromServer } from "./mappers/mapKitsFromServer";
import { mapKitToServer } from "./mappers/mapKitToServer";

export const addKit = async (kit) => {
    const response = (
        await makeHttpRequest({
            url: "/kit",
            method: "POST",
            data: mapKitToServer(kit),
        })
    ).data;

    return { kit: mapKitFromServer(response.data) };
};
