/* eslint-disable node/no-unsupported-features/es-syntax */
import { INVENTORY_ROUTE_NAMES } from "@/shared";
export const INVENTORY_ROUTES = [
    {
        name: INVENTORY_ROUTE_NAMES.LIST,
        path: "/inventory",
        meta: {
            title: "Инвентарь",
        },
        component: () => import("../ui/InventoryList.vue"),
        props: (route) => ({
            ...route.params,
            ...route.query,
        }),
    },
    {
        name: INVENTORY_ROUTE_NAMES.CARD,
        path: "/inventory/:id",
        meta: {
            title: "Карточка инвентаря",
        },
        component: () => import("../ui/InventoryCard.vue"),
        props: true,
    },
    {
        name: INVENTORY_ROUTE_NAMES.ADD,
        path: "/inventory/add",
        meta: {
            title: "Добавление инвентаря",
        },
        component: () => import("../ui/InventoryEdit.vue"),
        props: true,
    },
    {
        name: INVENTORY_ROUTE_NAMES.EDIT,
        path: "/inventory/edit/:id",
        meta: {
            title: "Редактирование инвентаря",
        },
        component: () => import("../ui/InventoryEdit.vue"),
        props: true,
    },
    {
        name: INVENTORY_ROUTE_NAMES.COPY,
        path: "/inventory/copy/:id",
        meta: {
            title: "Копирование инвентаря",
        },
        component: () => import("../ui/InventoryEdit.vue"),
        props: true,
    },
];
