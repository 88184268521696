import { makeHttpRequest } from "@/shared";

import { mapAdditionalServiceToServer } from "./mappers/mapAdditionalServiceToServer";

export const addAdditionalService = async (breaking) => {
    const response = (
        await makeHttpRequest({
            url: "/service/additional",
            method: "POST",
            data: mapAdditionalServiceToServer(breaking),
        })
    ).data;

    return response;
};
