import { makeHttpRequest } from "@/shared";

import { mapDiscountFromServer } from "./mappers/mapDiscountsFromServer";

export const getDiscount = async (id) => {
    const response = (
        await makeHttpRequest({
            url: `/discount/${id}`,
            method: "GET",
        })
    ).data;

    return mapDiscountFromServer(response.data);
};
