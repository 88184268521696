import { makeHttpRequest } from "@/shared";

import { mapCalculatedRepairFromServer } from "./mappers/mapCalculatedRepairFromServer";
import { mapRepairTimeToServer } from "./mappers/mapRepairTimeToServer";
export const setRepairPeriod = async (sessionKey, repair) => {
    const response = (
        await makeHttpRequest({
            url: `/repair/builder/${sessionKey}/add/time`,
            method: "PUT",
            data: mapRepairTimeToServer(repair),
        })
    ).data;

    return mapCalculatedRepairFromServer(response.data);
};
