import {
    makeHttpRequest,
    mapPaginationParamsToServer,
    mapDatabaseParamsFromServer,
} from "@/shared";

import { mapClientsFromServer } from "./mappers/from/mapClientsFromServer";
import { mapClientFiltersToServer } from "./mappers/to/mapClientFiltersToServer";

export const getClients = async (paginationParams, filters) => {
    const response = (
        await makeHttpRequest({
            url: "/human/client",
            method: "GET",
            params: {
                ...mapPaginationParamsToServer(paginationParams),
                ...mapClientFiltersToServer(filters),
            },
        })
    ).data;

    return {
        data: mapClientsFromServer(response.data),
        ...mapDatabaseParamsFromServer(response),
    };
};
