import { makeHttpRequest } from "@/shared";

import { mapExpenseTypeToServer } from "./mappers/mapExpenseTypeToServer";

export const editExpenseType = async (id, sourceAttraction) => {
    const response = (
        await makeHttpRequest({
            url: `/expense/type/${id}`,
            method: "PUT",
            data: mapExpenseTypeToServer(sourceAttraction),
        })
    ).data;

    return response;
};
