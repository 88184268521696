import { getDataMapper, mapField } from "@/shared";

export const mapDeliveryFiltersToServer = getDataMapper(
    {
        delivery_state_id: mapField("statusIds", Number),
        category_id: mapField("categoryIds", Number),
        point_id: mapField("pointId", Number),
    },
    {
        shouldAvoidUndefined: true,
        shouldAvoidNull: true,
    }
);
