import {
    makeHttpRequest,
    mapDatabaseParamsFromServer,
    mapPaginationParamsToServer,
} from "@/shared";

import { mapDeliveryFromServer } from "./mappers/mapDeliveriesFromServer";
import { mapDeliveryFiltersToServer } from "./mappers/mapDeliveryFiltersToServer";

export const getDeliveries = async (paginationParams, filters) => {
    const response = (
        await makeHttpRequest({
            url: "/delivery",
            method: "GET",
            params: {
                ...mapPaginationParamsToServer(paginationParams),
                ...mapDeliveryFiltersToServer(filters),
            },
        })
    ).data;

    return {
        data: mapDeliveryFromServer(response.data),
        ...mapDatabaseParamsFromServer(response),
    };
};
