// TODO: Вернуть заявки на доставку их иконка IconPeopleCarryBox, когда будут выполнены
import { h } from "vue";

import {
    // icons
    IconBook,
    IconBox,
    IconBoxesStacked,
    IconCalendarDays,
    IconChartArea,
    IconCoins,
    IconFileLines,
    IconGear,
    IconHelmetSafety,
    // IconListUl,
    // IconMoneyBill,
    IconUsers,
    IconCartShopping,
    IconWrench,
    IconScaleBalanced,
    IconCartFlatbed,
    IconFileArrowDown,
    IconScrewdriverWrench,
    IconPlug,

    // route names
    SETTINGS_ROUTE_NAMES,
    CLIENT_ROUTE_NAMES,
    EMPLOYEE_ROUTE_NAMES,
    EXPENSE_ROUTE_NAMES,
    INVENTORY_ROUTE_NAMES,
    KIT_ROUTE_NAMES,
    RENT_ROUTE_NAMES,
    REPAIR_ROUTE_NAMES,
    REPORTS_ROUTE_NAMES,
    STORE_ROUTE_NAMES,
    GENERAL_ROUTE_NAMES,
    // SALARY_ROUTE_NAMES,
    RENT_BOOKING_ROUTE_NAMES,
} from "@/shared";

import SidebarTooltipNoAccess from "../ui/SidebarTooltipNoAccess.vue";
export default {
    MENU_LK_DASHBOARD: {
        icon: IconChartArea,
        title: "Аналитика",
        to: { name: GENERAL_ROUTE_NAMES.DASHBOARD },
        activeRoutes: { DASHBOARD: GENERAL_ROUTE_NAMES.DASHBOARD },
    },
    MENU_EXPENSE: {
        icon: IconCoins,
        title: "Расходы",
        to: { name: EXPENSE_ROUTE_NAMES.LIST },
        activeRoutes: EXPENSE_ROUTE_NAMES,
    },
    MENU_H_CLIENT: {
        icon: IconUsers,
        title: "Клиенты",
        to: { name: CLIENT_ROUTE_NAMES.LIST },
        activeRoutes: CLIENT_ROUTE_NAMES,
    },
    MENU_H_REPORTS: {
        icon: IconFileLines,
        title: "Отчёты",
        to: { name: REPORTS_ROUTE_NAMES.REPORTS },
        activeRoutes: REPORTS_ROUTE_NAMES,
    },
    MENU_H_EMPLOYEE: {
        icon: IconHelmetSafety,
        title: "Сотрудники",
        // activeRoutes: [EMPLOYEE_ROUTE_NAMES, SALARY_ROUTE_NAMES],
        to: { name: EMPLOYEE_ROUTE_NAMES.LIST },
        activeRoutes: EMPLOYEE_ROUTE_NAMES,
        /* children: {
            MENU_H_EMPLOYEE_LIST: {
                icon: IconListUl,
                title: "Список",
                to: { name: EMPLOYEE_ROUTE_NAMES.LIST },
                activeRoutes: EMPLOYEE_ROUTE_NAMES,
            },
            MENU_H_EMPLOYEE_WAGE: {
                icon: IconMoneyBill,
                title: "Зарплаты",
                to: { name: SALARY_ROUTE_NAMES.LIST },
                activeRoutes: SALARY_ROUTE_NAMES,
            },
        }, */
    },
    MENU_INVENTORY: {
        icon: IconBox,
        title: "Инвентарь",
        to: { name: INVENTORY_ROUTE_NAMES.LIST },
        activeRoutes: INVENTORY_ROUTE_NAMES,
    },
    MENU_KIT: {
        icon: IconBoxesStacked,
        title: "Комплекты",
        to: { name: KIT_ROUTE_NAMES.LIST },
        activeRoutes: KIT_ROUTE_NAMES,
    },
    MENU_INVENTORY_BOOKING: {
        icon: IconCalendarDays,
        title: "Календарь аренд",
        to: { name: RENT_BOOKING_ROUTE_NAMES.APP },
        activeRoutes: RENT_BOOKING_ROUTE_NAMES,
    },
    MENU_RENT: {
        icon: IconBook,
        title: "Аренды",
        to: { name: RENT_ROUTE_NAMES.LIST },
        activeRoutes: RENT_ROUTE_NAMES,
    },
    MENU_STOCK: {
        icon: IconCartShopping,
        title: "Магазин",
        shouldDisplayWithoutAccess: true,
        tooltipContent: h(SidebarTooltipNoAccess, {
            tooltipText: "Магазин доступен в тарифе",
            tariffTitle: "Профи",
        }),
        activeRoutes: [STORE_ROUTE_NAMES.PRODUCT, STORE_ROUTE_NAMES.STOCK],
        children: {
            MENU_STOCK_LIST: {
                icon: IconScaleBalanced,
                title: "Продажи",
                to: { name: STORE_ROUTE_NAMES.STOCK.MAIN },
                activeRoutes: STORE_ROUTE_NAMES.STOCK,
            },
            MENU_STOCK_PRODUCT: {
                icon: IconCartFlatbed,
                title: "Товары",
                to: { name: STORE_ROUTE_NAMES.PRODUCT.MAIN },
                activeRoutes: STORE_ROUTE_NAMES.PRODUCT,
            },
        },
    },
    MENU_REPAIR: {
        icon: IconWrench,
        title: "Мастерская",
        shouldDisplayWithoutAccess: true,
        tooltipContent: h(SidebarTooltipNoAccess, {
            tooltipText: "Мастерская доступна в тарифе",
            tariffTitle: "Профи",
        }),
        activeRoutes: [REPAIR_ROUTE_NAMES.ORDER, REPAIR_ROUTE_NAMES.SERVICE],
        children: {
            MENU_REPAIR_LIST: {
                icon: IconFileArrowDown,
                title: "Заявки",
                to: { name: REPAIR_ROUTE_NAMES.ORDER.LIST },
                activeRoutes: REPAIR_ROUTE_NAMES.ORDER,
            },
            MENU_REPAIR_SERVICE: {
                icon: IconScrewdriverWrench,
                title: "Услуги",
                to: { name: REPAIR_ROUTE_NAMES.SERVICE.LIST },
                activeRoutes: REPAIR_ROUTE_NAMES.SERVICE,
            },
        },
    },
    /* MENU_DELIVERY: {
        icon: IconPeopleCarryBox,
        title: "Заявки на доставку",
        to: "delivery",
    }, */
    MENU_LK_INTEGRATIONS: {
        icon: IconPlug,
        title: "Интеграции",
        to: { name: SETTINGS_ROUTE_NAMES.INTEGRATIONS },
        activeRoutes: SETTINGS_ROUTE_NAMES.INTEGRATIONS,
    },
    MENU_LK_SETTINGS: {
        icon: IconGear,
        title: "Настройки",
        to: { name: SETTINGS_ROUTE_NAMES.INDEX },
        activeRoutes: SETTINGS_ROUTE_NAMES,
    },
};
