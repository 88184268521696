import { makeHttpRequest } from "@/shared";

import { mapCalculatedRentFromServer } from "./mappers/mapCalculatedRentFromServer";
import { mapRentInventoryUnionToServer } from "./mappers/mapRentInventoryUnionToServer";

export const unionRentInventories = async (sessionKey, payload) => {
    const response = (
        await makeHttpRequest({
            url: `/rent/builder/${sessionKey}/kit/unite`,
            method: "PUT",
            data: mapRentInventoryUnionToServer(payload),
        })
    ).data;

    return mapCalculatedRentFromServer(response.data);
};
