import { makeHttpRequest } from "@/shared";

import { mapRepairStatusFromServer } from "./mappers/mapRepairStatusesFromServer";

export const addRepairStatus = async (repairStatus) => {
    const response = (
        await makeHttpRequest({
            url: "/repair/state",
            method: "POST",
            data: mapRepairStatusFromServer(repairStatus),
        })
    ).data;

    return response;
};
