import { makeHttpRequest } from "@/shared";

import { mapRentToCloseToServer } from "./mappers/mapRentsToCloseToServer";

export const closeRent = async (sessionKey, rent) => {
    const response = (
        await makeHttpRequest({
            url: "/rent/close",
            method: "POST",
            data: {
                session_key: sessionKey,
                ...mapRentToCloseToServer(rent),
            },
        })
    ).data;

    return response;
};
