import { getDataMapper, mapField, isPrimitive, isNil } from "@/shared";

export const mapReviseFiltersToServer = getDataMapper({
    period_interval: mapField("period", (period) => {
        return isPrimitive(period.interval) ? period.interval : null;
    }),
    period_from: mapField("period", (period) => {
        return isPrimitive(period.interval) || isNil(period.interval)
            ? null
            : period.interval.from;
    }),
    period_to: mapField("period", (period) => {
        return isPrimitive(period.interval) || isNil(period.interval)
            ? null
            : period.interval.to;
    }),
    open_point_id: mapField("pointId", Number),
    user_id: mapField("user", (user) => user.id),
});
