import { makeHttpRequest } from "@/shared";

import { mapDeliveryFromServer } from "./mappers/mapDeliveriesFromServer";
import { mapDeliveryToServer } from "./mappers/mapDeliveryToServer";

export const addDelivery = async (delivery) => {
    const response = (
        await makeHttpRequest({
            url: `/delivery/`,
            method: "POST",
            data: mapDeliveryToServer(delivery),
        })
    ).data;

    return { delivery: mapDeliveryFromServer(response.data) };
};
