/* eslint-disable node/no-unsupported-features/es-syntax */
import { RENT_BOOKING_ROUTE_NAMES } from "@/shared";
export const RENT_BOOKING_ROUTES = [
    {
        name: RENT_BOOKING_ROUTE_NAMES.APP,
        path: "/rent/booking",
        meta: {
            title: "Календарь аренд",
        },
        component: () => import("../ui/RentBooking.vue"),
    },
];
