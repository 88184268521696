import {
    makeHttpRequest,
    mapDatabaseParamsFromServer,
    mapPaginationParamsToServer,
} from "@/shared";

import { mapRepairStatusesFromServer } from "./mappers/mapRepairStatusesFromServer";

export const getRepairStatuses = async (paginationParams = {}) => {
    const response = (
        await makeHttpRequest({
            url: "/repair/state",
            method: "GET",
            params: mapPaginationParamsToServer(paginationParams),
        })
    ).data;

    return {
        data: mapRepairStatusesFromServer(response.data),
        ...mapDatabaseParamsFromServer(response),
    };
};
