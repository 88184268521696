import { makeHttpRequest } from "@/shared";

import { mapCalculatedRepairFromServer } from "./mappers/mapCalculatedRepairFromServer";
import { mapRepairProductToServer } from "./mappers/mapRepairProductToServer";
export const addRepairProduct = async (sessionKey, product) => {
    const response = (
        await makeHttpRequest({
            url: `/repair/builder/${sessionKey}/add/product`,
            method: "PUT",
            data: mapRepairProductToServer(product),
        })
    ).data;

    return mapCalculatedRepairFromServer(response.data);
};
