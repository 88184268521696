import { getDataMapper, mapField } from "@/shared";

const mapMyCallsCall = getDataMapper({
    direction: mapField("direction", Number),
    clientNumber: mapField("client_number", String),
    startTime: mapField("start_time", Number),
    clientName: mapField("client_name"),
});

export const mapMyCallsClientCallsFromServer = getDataMapper({
    incoming: mapField("incoming", mapMyCallsCall),
    outgoing: mapField("outgoing", mapMyCallsCall),
});
