import {
    getDataMapper,
    mapField,
    composeFields,
    isNil,
    mapCategoryFromServer,
} from "@/shared";

import { mapInventoryFromServer } from "@/entities/inventory/@x";

export const mapObjectFromServer = getDataMapper({
    id: mapField("id", Number),
    count: mapField("count", Number),
    inventory: mapField("inventory", mapInventoryFromServer),
    category: mapField("category", mapCategoryFromServer),
    title: composeFields(
        ["inventory", "category"],
        ([inventory, category]) => inventory?.title ?? category?.title
    ),
    type: composeFields(["inventory", "category"], ([inventory, category]) => {
        return !isNil(inventory)
            ? "INVENTORY"
            : !isNil(category)
            ? "CATEGORY"
            : null;
    }),
});
