import { makeHttpRequest } from "@/shared";

import { mapKitFromServer } from "./mappers/mapKitsFromServer";
import { mapKitToServer } from "./mappers/mapKitToServer";

export const editKit = async (kitId, inventory) => {
    const response = (
        await makeHttpRequest({
            url: `/kit/${kitId}`,
            method: "PUT",
            data: mapKitToServer(inventory),
        })
    ).data;

    return mapKitFromServer(response.data);
};
