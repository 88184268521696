import {
    makeHttpRequest,
    mapDatabaseParamsFromServer,
    mapPaginationParamsToServer,
} from "@/shared";

import { mapAdditionalServicesFromServer } from "./mappers/mapAdditionalServicesFromServer";

export const getAdditionalServices = async (paginationParams = {}) => {
    const response = (
        await makeHttpRequest({
            url: "/service/additional",
            method: "GET",
            params: mapPaginationParamsToServer(paginationParams),
        })
    ).data;

    return {
        data: mapAdditionalServicesFromServer(response.data),
        ...mapDatabaseParamsFromServer(response),
    };
};
