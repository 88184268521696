import { getDataMapper, mapField, formatISO } from "@/shared";

export const mapInventoryFiltersToServer = getDataMapper(
    {
        inventory_state_id: mapField("statusIds"),
        category_id: mapField("categoryIds"),
        point_id: mapField("pointId"),
        empty_point_id: mapField("emptyPointId", Boolean),
        time_start: mapField("timeStart", formatISO),
        time_end: mapField("timeEnd", formatISO),
    },
    {
        shouldAvoidUndefined: true,
        shouldAvoidNull: true,
    }
);
