import { getDataMapper, mapField, mapArray } from "@/shared";

export const mapCalendarEventFromServer = getDataMapper({
    id: mapField("rent_id"),
    title: mapField("inventory_title"),
    start: mapField("time_start"),
    end: mapField("time_end"),
});

export const mapCalendarEventsFromServer = mapArray(mapCalendarEventFromServer);
