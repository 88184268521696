import { defineStore } from "pinia";

import { useSelectEntityStore, useEntityStore } from "@/shared";

import { getCategoriesInventory } from "../api/getCategoriesInventory";

export const useCategoriesInventoryStore = defineStore(
    "inventoryCategories",
    () => {
        const { listData, meta, isManyLoading, permissions, fetchMany } =
            useEntityStore({
                getEntities: getCategoriesInventory,
            });

        const {
            selectMeta,
            selectData,
            isSelectLoading,
            selectPrevPaginationParams,
            fetchSelect,
        } = useSelectEntityStore({
            getEntities: getCategoriesInventory,
        });

        return {
            // entity
            listData,
            meta,
            isManyLoading,
            permissions,
            fetchMany,

            // select
            selectData,
            selectMeta,
            isSelectLoading,
            selectPrevPaginationParams,
            fetchSelect,
        };
    }
);
