import {
    makeHttpRequest,
    mapPaginationParamsToServer,
    mapDatabaseParamsFromServer,
} from "@/shared";

import { mapContractsFromServer } from "./mappers/mapContractsFromServer";

export const getContracts = async (paginationParams = {}) => {
    const response = (
        await makeHttpRequest({
            url: "order",
            method: "GET",
            params: mapPaginationParamsToServer(paginationParams),
        })
    ).data;

    return {
        data: mapContractsFromServer(response.data),
        ...mapDatabaseParamsFromServer(response),
    };
};
