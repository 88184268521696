import { makeHttpRequest } from "@/shared";

import { mapSourceAttractionFromServer } from "./mappers/mapSourcesAttractionFromServer";

export const getSourceAttraction = async (id) => {
    const response = (
        await makeHttpRequest({
            url: `/attraction/${id}`,
            method: "GET",
        })
    ).data;

    return mapSourceAttractionFromServer(response.data);
};
