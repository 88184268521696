import { makeHttpRequest } from "@/shared";

export const deleteSalaryHistory = async (id) => {
    return (
        await makeHttpRequest({
            url: `/human/employee/salary/pay/${id}`,
            method: "DELETE",
        })
    ).data;
};
