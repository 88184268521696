import { makeHttpRequest } from "@/shared";

import { mapDepositToServer } from "./mappers/mapDepositToServer";

export const addDeposit = async (deposit) => {
    return (
        await makeHttpRequest({
            url: "/rent/deposit",
            method: "POST",
            data: mapDepositToServer(deposit),
        })
    ).data;
};
