import { makeHttpRequest } from "@/shared";

import { mapRepairStatusToServer } from "./mappers/mapRepairStatusToServer";

export const editRepairStatus = async (id, repairStatus) => {
    const response = (
        await makeHttpRequest({
            url: `/repair/state/${id}`,
            method: "PUT",
            data: mapRepairStatusToServer(repairStatus),
        })
    ).data;

    return response;
};
