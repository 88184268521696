import { makeHttpRequest } from "@/shared";

import { mapContractCodesFromServer } from "./mappers/mapContractCodesFromServer";

export const getContractCodes = async () => {
    const response = (
        await makeHttpRequest({
            url: "order/code",
            method: "GET",
        })
    ).data;

    return mapContractCodesFromServer(response.data);
};
