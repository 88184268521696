/* eslint-disable node/no-unsupported-features/es-syntax */
import { useEducationStore } from "@/entities";
import { GENERAL_ROUTE_NAMES, PROFILE_ROUTE_NAMES } from "@/shared";

export const GENERAL_ROUTES = [
    {
        path: "/:pathMatch(.*)*",
        name: GENERAL_ROUTE_NAMES.NOT_FOUND,
        meta: {
            title: "Страница не найдена",
        },
        component: () => import("../ui/NotFound.vue"),
    },
    {
        path: "/tariff-extend",
        name: GENERAL_ROUTE_NAMES.BUY_TARIFF,
        meta: {
            title: "Продление тарифа",
        },
        component: () => import("../ui/BuyTariffPage.vue"),
    },
    {
        path: "/",
        name: GENERAL_ROUTE_NAMES.DEFAULT,
        redirect: { name: PROFILE_ROUTE_NAMES.PROFILE },
    },
    {
        path: "/help",
        name: "RIH_HELP",
        beforeEnter() {
            console.debug("RIH_HELP");
            window.open("https://rentinhand.ru/help", "_blank");

            return false;
        },
    },
    {
        path: "/changes",
        name: "RIH_CHANGELOG",
        beforeEnter() {
            console.debug("RIH_CHANGELOG");
            window.open("https://rentinhand.ru/whatsnew", "_blank");

            return false;
        },
    },
    {
        path: "/chatra",
        name: "RIH_CHATRA",
        beforeEnter() {
            window.Chatra("expandWidget");
            console.debug("RIH_CHATRA");

            return false;
        },
    },
    {
        path: "/intro",
        name: "RIH_INTRO",
        beforeEnter() {
            console.debug("RIH_INTRO");
            useEducationStore().showEducation();

            return false;
        },
    },
];
