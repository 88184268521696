<script setup>
import { ref, computed } from "vue";

import { RIHLogo, useUserStore } from "@/entities";
import {
    BaseHeaderTemplate,
    BaseButton,
    BaseLinkButton,
    BaseDropdown,
    IconBars,
    IconUser,
    IconClockRotateLeft,
    IconLifebuoy,
    // IconBell,
    IconArrowRightFromBracket,
    RENT_ROUTE_NAMES,
    PROFILE_ROUTE_NAMES,
    REPAIR_ROUTE_NAMES,
    vTooltip,
    SALARY_ROUTE_NAMES,
} from "@/shared";

import TheHeaderExitModal from "./TheHeaderExitModal.vue";

defineProps({
    isSidebarCollapsed: {
        type: Boolean,
        default: false,
    },
});

defineEmits(["changeSidebarMode"]);

const userStore = useUserStore();

const fastCreateLinks = computed(() => {
    const res = [];
    const rentRights = userStore.rights?.RENT;
    const stockRights = userStore.rights?.STOCK;
    const repairRights = userStore.rights?.REPAIR;

    rentRights?.canAdd &&
        res.push({
            title: "Создать аренду",
            link: { name: RENT_ROUTE_NAMES.ADD },
        });

    repairRights?.canAdd &&
        res.push({
            title: "Создать заявку",
            link: { name: REPAIR_ROUTE_NAMES.ORDER.ADD },
        });

    stockRights?.canAdd &&
        res.push({
            title: "Оформить продажу",
            link: { name: SALARY_ROUTE_NAMES.ADD },
        });

    return res;
});

const HELP_LINKS = [
    {
        title: "Справочник",
        link: { name: "RIH_HELP" },
        const: "DOCS",
    },
    {
        title: "Поддержка",
        link: { name: "RIH_CHATRA" },
        const: "SUPPORT",
    },
    {
        title: "Что нового?",
        link: { name: "RIH_CHANGELOG" },
        const: "CHANGELOG",
    },
    {
        title: "Обучение",
        link: { name: "RIH_INTRO" },
        const: "INTRO",
    },
];

const exitModal = ref(null);
</script>

<template>
    <BaseHeaderTemplate
        class="the-header"
        :is-sidebar-collapsed="isSidebarCollapsed"
    >
        <template #logo>
            <RIHLogo :is-collapsed="isSidebarCollapsed" />
        </template>
        <template #content>
            <BaseButton
                class="the-header__collapse-btn the-header__btn"
                variant="primary"
                type="square"
                @click="$emit('changeSidebarMode')"
            >
                <IconBars />
            </BaseButton>
            <BaseDropdown
                v-if="fastCreateLinks.length > 0"
                class="the-header__fast-create-btn the-header__btn"
                button-variant="secondary"
                button-size="sm"
                button-type="rounded"
                :links="fastCreateLinks"
            >
                <template #button-content> Создать </template>
            </BaseDropdown>
            <BaseLinkButton
                class="the-header__btn"
                variant="primary"
                type="circle"
                :to="{ name: PROFILE_ROUTE_NAMES.PROFILE }"
                v-tooltip.bottom="'Профиль'"
            >
                <IconUser class="the-header__icon" />
            </BaseLinkButton>
            <BaseButton
                v-if="false"
                class="the-header__btn"
                variant="primary"
                type="circle"
                v-tooltip.bottom="'История изменений'"
            >
                <IconClockRotateLeft class="the-header__icon" />
            </BaseButton>
            <BaseDropdown
                ref="menuDropdown"
                button-variant="primary"
                button-type="circle"
                button-class="the-header__btn"
                :links="HELP_LINKS"
                tooltip-text="Помощь"
            >
                <template #button-content>
                    <IconLifebuoy
                        class="the-header__icon the-header__icon--lifebuoy"
                    />
                </template>
            </BaseDropdown>
            <!--<BaseDropdown
                button-variant="primary"
                button-type="circle"
                button-class="the-header__btn"
                tooltip-text="Уведомления"
            >
                <template #button-content>
                    <IconBell class="the-header__icon" />
                </template>
            </BaseDropdown>-->
            <BaseButton
                class="the-header__btn"
                variant="primary"
                type="circle"
                v-tooltip.bottom="'Выход'"
                @click="exitModal.show()"
            >
                <IconArrowRightFromBracket class="the-header__icon" />
            </BaseButton>
            <TheHeaderExitModal ref="exitModal" />
        </template>
    </BaseHeaderTemplate>
</template>

<style lang="scss" scoped>
.the-header {
    &__collapse-btn {
        --button-border-color: var(--button-color);
        --button-border-color-hover: var(--button-color-hover);
        --button-width: var(--header-height);
        --button-height: var(--header-height);

        flex-shrink: 0;

        margin-right: auto;
    }

    &__fast-create-btn {
        margin-right: 10px;
        margin-left: 10px;
    }

    &__icon {
        &--lifebuoy {
            width: 20px !important;
            height: auto;
        }
    }

    &__btn {
        flex-shrink: 0;

        &:active {
            svg {
                color: var(--c-green-10) !important;
            }
        }
    }
}
</style>
