export const AUTHENTICATION_ROUTE_NAMES = {
    PAGE: "AuthenticationPage",
    LOGIN: "AuthenticationLogin",
    REGISTRATION: "AuthenticationRegistration",
    WAIT_MAIL: "AuthenticationWaitMail",
    CONFIRM_REGISTRATION: "AuthenticationConfirmRegistration",
    RESTORE: "AuthenticationRestore",
    RESTORE_ENTER_MAIL: "RestoreEnterMail",
    RESTORE_CONFIRM_MAIL: "RestoreConfirmMail",
    RESTORE_CHANGE_PASSWORD: "RestoreChangePassword",
};
