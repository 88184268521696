import { makeHttpRequest } from "@/shared";

import { mapDeliveryFromServer } from "./mappers/mapDeliveriesFromServer";
import { mapDeliveryToServer } from "./mappers/mapDeliveryToServer";

export const editDelivery = async (deliveryId, delivery) => {
    const response = (
        await makeHttpRequest({
            url: `/delivery/${deliveryId}`,
            method: "PUT",
            data: mapDeliveryToServer(delivery),
        })
    ).data;

    return { delivery: mapDeliveryFromServer(response.data) };
};
