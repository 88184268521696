import { makeHttpRequest } from "@/shared";

import { mapKitFromServer } from "./mappers/mapKitsFromServer";

export const getKit = async (kitId) => {
    const response = (
        await makeHttpRequest({
            url: `/kit/${kitId}`,
            method: "GET",
        })
    ).data;

    return mapKitFromServer(response.data);
};
