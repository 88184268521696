import { getDataMapper, mapField, mapArray } from "@/shared";

export const mapIncomeFromServer = getDataMapper({
    count: mapField("count", Number),
    date: mapField("date"),
    income: mapField("income", Number),
    incomeCard: mapField("income_card", Number),
    incomeCash: mapField("income_cash", Number),
    pointTitle: mapField("point_title", String),
    fio: mapField("fio", String),
});

export const mapIncomesFromServer = mapArray(mapIncomeFromServer);
