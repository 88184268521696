import {
    makeHttpRequest,
    mapDatabaseParamsFromServer,
    mapPaginationParamsToServer,
} from "@/shared";

import { mapPointsFromServer } from "./mappers/mapPointsFromServer";

export const getPoints = async (paginationParams = {}) => {
    const response = (
        await makeHttpRequest({
            url: "/point",
            method: "GET",
            params: mapPaginationParamsToServer(paginationParams),
        })
    ).data;

    return {
        data: mapPointsFromServer(response.data),
        ...mapDatabaseParamsFromServer(response),
    };
};
