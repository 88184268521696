import { makeHttpRequest } from "@/shared";

import { mapTariffsListFromServer } from "./mappers/mapTariffsListFromServer";

export const getTariffsList = async (promocode) => {
    const response = (
        await makeHttpRequest({
            url: "/tariff/list",
            method: "GET",
            params: { promocode },
        })
    ).data;

    return { tariffs: mapTariffsListFromServer(response.data) };
};
