import {
    makeHttpRequest,
    mapDatabaseParamsFromServer,
    mapPaginationParamsToServer,
} from "@/shared";

import { mapSalariesHistoryFiltersToServer } from "./mappers/mapSalariesHistoryFiltersToServer";
import { mapSalariesHistoryFromServer } from "./mappers/mapSalariesHistoryFromServer";

export const getSalariesHistory = async (paginationParams, filters) => {
    const response = (
        await makeHttpRequest({
            url: "/human/employee/salary/pay",
            method: "GET",
            params: {
                ...mapPaginationParamsToServer(paginationParams),
                ...mapSalariesHistoryFiltersToServer(filters),
            },
        })
    ).data;

    return {
        data: mapSalariesHistoryFromServer(response.data),
        ...mapDatabaseParamsFromServer(response),
    };
};
