/* eslint-disable node/no-unsupported-features/es-syntax */
import { DELIVERY_ROUTE_NAMES } from "@/shared";

export const DELIVERY_ROUTES = [
    {
        name: DELIVERY_ROUTE_NAMES.LIST,
        path: "/delivery",
        meta: {
            title: "Доставка",
        },
        component: () => import("../ui/DeliveryList.vue"),
    },
    {
        name: DELIVERY_ROUTE_NAMES.ADD,
        path: "/delivery/add",
        meta: {
            title: "Добавление доставки",
        },
        component: () => import("../ui/DeliveryEdit.vue"),
    },
    {
        name: DELIVERY_ROUTE_NAMES.EDIT,
        path: "/delivery/edit",
        meta: {
            title: "Добавление доставки",
        },
        component: () => import("../ui/DeliveryEdit.vue"),
        props: true,
    },
];
