<template>
    <div
        :id="id"
        ref="dropzoneElement"
        :class="{ 'vue-dropzone dropzone': includeStyling }"
    >
        <div v-if="useCustomSlot" class="dz-message">
            <slot></slot>
        </div>
    </div>
</template>

<script>
import Dropzone from "dropzone";

import awsEndpoint from "./services/urlsigner";

Dropzone.autoDiscover = false;

export default {
    props: {
        id: {
            type: String,
            required: true,
            default: "dropzone",
        },
        options: {
            type: Object,
            required: true,
        },
        includeStyling: {
            type: Boolean,
            default: true,
            required: false,
        },
        awss3: {
            type: Object,
            required: false,
            default: null,
        },
        destroyDropzone: {
            type: Boolean,
            default: true,
            required: false,
        },
        duplicateCheck: {
            type: Boolean,
            default: false,
            required: false,
        },
        useCustomSlot: {
            type: Boolean,
            default: false,
            required: false,
        },
    },
    data() {
        return {
            isS3: false,
            isS3OverridesServerPropagation: false,
            wasQueueAutoProcess: true,
        };
    },
    computed: {
        dropzoneSettings() {
            const defaultValues = {
                thumbnailWidth: 200,
                thumbnailHeight: 200,
            };

            Object.keys(this.options).forEach((key) => {
                defaultValues[key] = this.options[key];
            }, this);

            if (this.awss3 !== null) {
                defaultValues.autoProcessQueue = false;
        this.isS3 = true; //eslint-disable-line
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.isS3OverridesServerPropagation = this.awss3.sendFileToServer === false; //eslint-disable-line

                if (this.options.autoProcessQueue !== undefined)
          this.wasQueueAutoProcess = this.options.autoProcessQueue; //eslint-disable-line

                if (this.isS3OverridesServerPropagation) {
                    defaultValues.url = (files) => files[0].s3Url;
                }
            }

            return defaultValues;
        },
    },
    mounted() {
        if (this.$isServer && this.hasBeenMounted) {
            return;
        }

        this.hasBeenMounted = true;
        this.dropzone = new Dropzone(
            this.$refs.dropzoneElement,
            this.dropzoneSettings
        );
        const vm = this;

        this.dropzone.on("thumbnail", (file, dataUrl) => {
            vm.$emit("vdropzone-thumbnail", file, dataUrl);
        });
        this.dropzone.on("addedfile", (file) => {
            let isDuplicate = false;

            if (vm.duplicateCheck) {
                if (this.files.length) {
                    // eslint-disable-next-line no-underscore-dangle
                    let _i;
                    let _len;

                    for (
                        _i = 0, _len = this.files.length;
                        _i < _len - 1;
                        // eslint-disable-next-line no-plusplus
                        _i++ // -1 to exclude current file
                    ) {
                        if (
                            // eslint-disable-next-line no-underscore-dangle
                            this.files[_i].name === file.name &&
                            // eslint-disable-next-line no-underscore-dangle
                            this.files[_i].size === file.size &&
                            // eslint-disable-next-line no-underscore-dangle
                            this.files[_i].lastModifiedDate.toString() ===
                                file.lastModifiedDate.toString()
                        ) {
                            this.removeFile(file);
                            // eslint-disable-next-line no-unused-vars
                            isDuplicate = true;
                            vm.$emit("vdropzone-duplicate-file", file);
                        }
                    }
                }
            }

            vm.$emit("vdropzone-file-added", file);

            if (vm.isS3 && vm.wasQueueAutoProcess && !file.manuallyAdded) {
                vm.getSignedAndUploadToS3(file);
            }
        });
        this.dropzone.on("addedfiles", (files) => {
            vm.$emit("vdropzone-files-added", files);
        });
        this.dropzone.on("removedfile", (file) => {
            vm.$emit("vdropzone-removed-file", file);

            if (file.manuallyAdded && vm.dropzone.options.maxFiles !== null) {
                // eslint-disable-next-line no-plusplus
                vm.dropzone.options.maxFiles++;
            }
        });
        this.dropzone.on("success", (file, response) => {
            vm.$emit("vdropzone-success", file, response);

            if (vm.isS3) {
                if (vm.isS3OverridesServerPropagation) {
                    const xmlResponse = new window.DOMParser().parseFromString(
                        response,
                        "text/xml"
                    );
                    const s3ObjectLocation =
                        xmlResponse.firstChild.children[0].innerHTML;

                    vm.$emit("vdropzone-s3-upload-success", s3ObjectLocation);
                }

                if (vm.wasQueueAutoProcess)
                    vm.setOption("autoProcessQueue", false);
            }
        });
        this.dropzone.on("successmultiple", (file, response) => {
            vm.$emit("vdropzone-success-multiple", file, response);
        });
        this.dropzone.on("error", (file, message, xhr) => {
            vm.$emit("vdropzone-error", file, message, xhr);

            if (this.isS3) vm.$emit("vdropzone-s3-upload-error");
        });
        this.dropzone.on("errormultiple", (files, message, xhr) => {
            vm.$emit("vdropzone-error-multiple", files, message, xhr);
        });
        this.dropzone.on("sending", (file, xhr, formData) => {
            if (vm.isS3) {
                if (vm.isS3OverridesServerPropagation) {
                    const signature = file.s3Signature;

                    Object.keys(signature).forEach((key) => {
                        formData.append(key, signature[key]);
                    });
                } else {
                    formData.append("s3ObjectLocation", file.s3ObjectLocation);
                }
            }

            vm.$emit("vdropzone-sending", file, xhr, formData);
        });
        this.dropzone.on("sendingmultiple", (file, xhr, formData) => {
            vm.$emit("vdropzone-sending-multiple", file, xhr, formData);
        });
        this.dropzone.on("complete", (file) => {
            vm.$emit("vdropzone-complete", file);
        });
        this.dropzone.on("completemultiple", (files) => {
            vm.$emit("vdropzone-complete-multiple", files);
        });
        this.dropzone.on("canceled", (file) => {
            vm.$emit("vdropzone-canceled", file);
        });
        this.dropzone.on("canceledmultiple", (files) => {
            vm.$emit("vdropzone-canceled-multiple", files);
        });
        this.dropzone.on("maxfilesreached", (files) => {
            vm.$emit("vdropzone-max-files-reached", files);
        });
        this.dropzone.on("maxfilesexceeded", (file) => {
            vm.$emit("vdropzone-max-files-exceeded", file);
        });
        this.dropzone.on("processing", (file) => {
            vm.$emit("vdropzone-processing", file);
        });
        this.dropzone.on("processingmultiple", (files) => {
            vm.$emit("vdropzone-processing-multiple", files);
        });
        this.dropzone.on("uploadprogress", (file, progress, bytesSent) => {
            vm.$emit("vdropzone-upload-progress", file, progress, bytesSent);
        });
        this.dropzone.on(
            "totaluploadprogress",
            (totaluploadprogress, totalBytes, totalBytesSent) => {
                vm.$emit(
                    "vdropzone-total-upload-progress",
                    totaluploadprogress,
                    totalBytes,
                    totalBytesSent
                );
            }
        );
        this.dropzone.on("reset", () => {
            vm.$emit("vdropzone-reset");
        });
        this.dropzone.on("queuecomplete", () => {
            vm.$emit("vdropzone-queue-complete");
        });
        this.dropzone.on("drop", (event) => {
            vm.$emit("vdropzone-drop", event);
        });
        this.dropzone.on("dragstart", (event) => {
            vm.$emit("vdropzone-drag-start", event);
        });
        this.dropzone.on("dragend", (event) => {
            vm.$emit("vdropzone-drag-end", event);
        });
        this.dropzone.on("dragenter", (event) => {
            vm.$emit("vdropzone-drag-enter", event);
        });
        this.dropzone.on("dragover", (event) => {
            vm.$emit("vdropzone-drag-over", event);
        });
        this.dropzone.on("dragleave", (event) => {
            vm.$emit("vdropzone-drag-leave", event);
        });
        vm.$emit("vdropzone-mounted");
    },
    beforeUnmounted() {
        if (this.destroyDropzone) this.dropzone.destroy();
    },
    methods: {
        manuallyAddFile(file, fileUrl) {
            file.manuallyAdded = true;
            this.dropzone.emit("addedfile", file);
            let containsImageFileType = false;

            if (
                fileUrl.indexOf(".svg") > -1 ||
                fileUrl.indexOf(".png") > -1 ||
                fileUrl.indexOf(".jpg") > -1 ||
                fileUrl.indexOf(".jpeg") > -1 ||
                fileUrl.indexOf(".gif") > -1 ||
                fileUrl.indexOf(".webp") > -1
            ) {
                containsImageFileType = true;
            }

            if (
                this.dropzone.options.createImageThumbnails &&
                containsImageFileType &&
                file.size <=
                    this.dropzone.options.maxThumbnailFilesize * 1024 * 1024
            ) {
                // eslint-disable-next-line no-unused-expressions
                fileUrl && this.dropzone.emit("thumbnail", file, fileUrl);
                const thumbnails = file.previewElement.querySelectorAll(
                    "[data-dz-thumbnail]"
                );

                // eslint-disable-next-line no-plusplus
                for (let i = 0; i < thumbnails.length; i++) {
                    thumbnails[
                        i
                    ].style.width = `${this.dropzoneSettings.thumbnailWidth}px`;
                    thumbnails[
                        i
                    ].style.height = `${this.dropzoneSettings.thumbnailHeight}px`;
                    thumbnails[i].style["object-fit"] = "contain";
                }
            }

            this.dropzone.emit("complete", file);

            // eslint-disable-next-line no-plusplus
            if (this.dropzone.options.maxFiles)
                this.dropzone.options.maxFiles--;

            this.dropzone.files.push(file);
            this.$emit("vdropzone-file-added-manually", file);
        },
        setOption(option, value) {
            this.dropzone.options[option] = value;
        },
        removeAllFiles(bool) {
            this.dropzone.removeAllFiles(bool);
        },
        processQueue() {
            const dropzoneEle = this.dropzone;

            if (this.isS3 && !this.wasQueueAutoProcess) {
                this.getQueuedFiles().forEach((file) => {
                    this.getSignedAndUploadToS3(file);
                });
            } else {
                this.dropzone.processQueue();
            }

            this.dropzone.on("success", () => {
                dropzoneEle.options.autoProcessQueue = true;
            });
            this.dropzone.on("queuecomplete", () => {
                dropzoneEle.options.autoProcessQueue = false;
            });
        },
        init() {
            return this.dropzone.init();
        },
        destroy() {
            return this.dropzone.destroy();
        },
        updateTotalUploadProgress() {
            return this.dropzone.updateTotalUploadProgress();
        },
        getFallbackForm() {
            return this.dropzone.getFallbackForm();
        },
        getExistingFallback() {
            return this.dropzone.getExistingFallback();
        },
        setupEventListeners() {
            return this.dropzone.setupEventListeners();
        },
        removeEventListeners() {
            return this.dropzone.removeEventListeners();
        },
        disable() {
            return this.dropzone.disable();
        },
        enable() {
            return this.dropzone.enable();
        },
        filesize(size) {
            return this.dropzone.filesize(size);
        },
        accept(file, done) {
            return this.dropzone.accept(file, done);
        },
        addFile(file) {
            return this.dropzone.addFile(file);
        },
        removeFile(file) {
            this.dropzone.removeFile(file);
        },
        getAcceptedFiles() {
            return this.dropzone.getAcceptedFiles();
        },
        getRejectedFiles() {
            return this.dropzone.getRejectedFiles();
        },
        getFilesWithStatus() {
            return this.dropzone.getFilesWithStatus();
        },
        getQueuedFiles() {
            return this.dropzone.getQueuedFiles();
        },
        getUploadingFiles() {
            return this.dropzone.getUploadingFiles();
        },
        getAddedFiles() {
            return this.dropzone.getAddedFiles();
        },
        getActiveFiles() {
            return this.dropzone.getActiveFiles();
        },
        getSignedAndUploadToS3(file) {
            const promise = awsEndpoint.sendFile(
                file,
                this.awss3,
                this.isS3OverridesServerPropagation
            );

            if (!this.isS3OverridesServerPropagation) {
                promise.then((response) => {
                    if (response.success) {
                        file.s3ObjectLocation = response.message;
                        setTimeout(() => this.dropzone.processFile(file));
                        this.$emit(
                            "vdropzone-s3-upload-success",
                            response.message
                        );
                    } else if (typeof response.message !== "undefined") {
                        this.$emit(
                            "vdropzone-s3-upload-error",
                            response.message
                        );
                    } else {
                        this.$emit(
                            "vdropzone-s3-upload-error",
                            "Network Error : Could not send request to AWS. (Maybe CORS error)"
                        );
                    }
                });
            } else {
                promise.then(() => {
                    setTimeout(() => this.dropzone.processFile(file));
                });
            }

            promise.catch((error) => {
                // eslint-disable-next-line no-alert
                alert(error);
            });
        },
        setAWSSigningURL(location) {
            if (this.isS3) {
                // eslint-disable-next-line vue/no-mutating-props
                this.awss3.signingURL = location;
            }
        },
    },
};
</script>

<style>
.vue-dropzone {
    font-family: Arial, sans-serif;
    color: #777;
    letter-spacing: 0.2px;

    border: 2px solid #e5e5e5;

    transition: 0.2s linear;
}

.vue-dropzone:hover {
    background-color: #f6f6f6;
}

.vue-dropzone > i {
    color: #ccc;
}

.vue-dropzone > .dz-preview .dz-image {
    width: 100%;
    height: 100%;

    border-radius: 0;
}

.vue-dropzone > .dz-preview .dz-image img:not([src]) {
    width: 200px;
    height: 200px;
}

.vue-dropzone > .dz-preview .dz-image:hover img {
    filter: none;

    transform: none;
}

.vue-dropzone > .dz-preview .dz-details {
    top: 0;
    bottom: 0;

    color: white;
    text-align: left;

    background-color: rgb(33 150 243 / 80%);

    transition: opacity 0.2s linear;
}

.vue-dropzone > .dz-preview .dz-details .dz-filename {
    overflow: hidden;
}

.vue-dropzone > .dz-preview .dz-details .dz-filename span,
.vue-dropzone > .dz-preview .dz-details .dz-size span {
    background-color: transparent;
}

.vue-dropzone > .dz-preview .dz-details .dz-filename:not(:hover) span {
    border: none;
}

.vue-dropzone > .dz-preview .dz-details .dz-filename:hover span {
    background-color: transparent;
    border: none;
}

.vue-dropzone > .dz-preview .dz-progress .dz-upload {
    background: #ccc;
}

.vue-dropzone > .dz-preview .dz-remove {
    position: absolute;
    top: inherit;
    bottom: 15px;
    z-index: 30;

    padding: 10px;

    margin-left: 15px;

    font-size: 0.8rem;
    font-weight: 800;

    color: white;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 1.1px;

    border: 2px white solid;
    opacity: 0;
}

.vue-dropzone > .dz-preview:hover .dz-remove {
    opacity: 1;
}

.vue-dropzone > .dz-preview .dz-success-mark,
.vue-dropzone > .dz-preview .dz-error-mark {
    top: 35%;
    left: 0;

    width: 100%;
    margin-top: auto;
    margin-left: auto;
}

.vue-dropzone > .dz-preview .dz-success-mark svg,
.vue-dropzone > .dz-preview .dz-error-mark svg {
    margin-right: auto;
    margin-left: auto;
}

.vue-dropzone > .dz-preview .dz-error-message {
    left: 0;

    width: 100%;
    margin-right: auto;
    margin-left: auto;

    text-align: center;
}

.vue-dropzone > .dz-preview .dz-error-message::after {
    display: none;
}
</style>
