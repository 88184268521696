import { defineStore } from "pinia";
import { ref } from "vue";
import { useRouter } from "vue-router";

import { RENT_ROUTE_NAMES, cloneDeep } from "@/shared";

import { addAdditionalServiceToRent } from "../api/addAdditionalServiceToRent";
import { addClientToRent } from "../api/addClientToRent";
import { addDepositToRent } from "../api/addDepositToRent";
import { addDiscountToRentByPromo } from "../api/addDiscountToRentByPromo";
import { addDiscountToRentByValue } from "../api/addDiscountToRentByValue";
import { addInventoryBreaking } from "../api/addInventoryBreaking";
import { addInventoryToRent } from "../api/addInventoryToRent";
import { addKitToRent } from "../api/addKitToRent";
import { addPaymentToRent } from "../api/addPaymentToRent";
import { addProductToRent } from "../api/addProductToRent";
import { cancelRents } from "../api/cancelRents";
import { closeRent } from "../api/closeRent";
import { finishRentInventory } from "../api/finishRentInventory";
import { finishRentInventoryGroup } from "../api/finishRentInventoryGroup";
import { prepareRent } from "../api/prepareRent";
import { removeAdditionalServiceFromRent } from "../api/removeAdditionalServiceFromRent";
import { removeClientFromRent } from "../api/removeClientFromRent";
import { removeDepositFromRent } from "../api/removeDepositFromRent";
import { removeDiscountFromRent } from "../api/removeDiscountFromRent";
import { removeInventoryBreaking } from "../api/removeInventoryBreaking";
import { removeInventoryFromRent } from "../api/removeInventoryFromRent";
import { removeKitFromRent } from "../api/removeKitFromRent";
import { removePaymentFromRent } from "../api/removePaymentFromRent";
import { removeProductFromRent } from "../api/removeProductFromRent";
import { saveRent } from "../api/saveRent";
import { setRentPeriod } from "../api/setRentPeriod";
import { unionRentInventories } from "../api/unionRentInventories";
import { updateRentAdditional } from "../api/updateRentAdditional";
import { updateRentAdditionalService } from "../api/updateRentAdditionalService";
import { updateRentDeposit } from "../api/updateRentDeposit";
import { updateRentInventory } from "../api/updateRentInventory";
import { updateRentKit } from "../api/updateRentKit";
import { updateRentProduct } from "../api/updateRentProduct";

const DEFAULT_RENT_DATA = {
    timeStart: null,
    timeEnd: null,
    client: null,
    inventories: [],
    kits: [],
    discounts: [],
    products: [],
    additionalServices: [],
    deposit: {
        depositInstance: null,
        sum: null,
        isAccepted: false,
        isReturned: false,
    },
};

export const useRentEditorStore = defineStore("rentEditorStore", () => {
    const sessionKey = ref(null);
    const rentData = ref(cloneDeep(DEFAULT_RENT_DATA));
    const isLoading = ref(false);
    const rentId = ref(null);
    const queue = ref([]);

    const router = useRouter();

    const setPeriod = doAsyncAction(async () => {
        return await setRentPeriod(sessionKey.value, rentData.value);
    });

    const addClient = doAsyncAction(async () => {
        return await addClientToRent(
            sessionKey.value,
            rentData.value.client.human.id
        );
    });

    const removeClient = doAsyncAction(async () => {
        return await removeClientFromRent(sessionKey.value);
    });

    const addInventory = doAsyncAction(async (rentInventory) => {
        return await addInventoryToRent(sessionKey.value, rentInventory);
    });

    const removeInventory = doAsyncAction(async (rentInventory) => {
        return await removeInventoryFromRent(sessionKey.value, rentInventory);
    });

    const updateInventory = doAsyncAction(async (rentInventory) => {
        return await updateRentInventory(sessionKey.value, rentInventory);
    });

    const addKit = doAsyncAction(async (kit) => {
        return await addKitToRent(sessionKey.value, kit);
    });

    const removeKit = doAsyncAction(async (kit) => {
        return await removeKitFromRent(sessionKey.value, kit);
    });

    const updateKit = doAsyncAction(async (kit) => {
        return await updateRentKit(sessionKey.value, kit);
    });

    const finishInventory = doAsyncAction(async (rentInventory) => {
        return await finishRentInventory(sessionKey.value, rentInventory);
    });

    const finishInventoryGroup = doAsyncAction(async (rentInventory, count) => {
        return await finishRentInventoryGroup(
            sessionKey.value,
            rentInventory,
            count
        );
    });

    const addBreaking = doAsyncAction(async (breaking) => {
        return await addInventoryBreaking(sessionKey.value, breaking);
    });

    const removeBreaking = doAsyncAction(async (breaking) => {
        return await removeInventoryBreaking(sessionKey.value, breaking);
    });

    const addProduct = doAsyncAction(async (rentProduct) => {
        return await addProductToRent(sessionKey.value, rentProduct);
    });

    const removeProduct = doAsyncAction(async (rentProduct) => {
        return await removeProductFromRent(sessionKey.value, rentProduct);
    });

    const updateProduct = doAsyncAction(async (rentProduct) => {
        return await updateRentProduct(sessionKey.value, rentProduct);
    });

    const addAdditionalService = doAsyncAction(
        async (rentAdditionalService) => {
            return await addAdditionalServiceToRent(
                sessionKey.value,
                rentAdditionalService
            );
        }
    );

    const removeAdditionalService = doAsyncAction(
        async (rentAdditionalService) => {
            return await removeAdditionalServiceFromRent(
                sessionKey.value,
                rentAdditionalService
            );
        }
    );

    const updateAdditionalService = doAsyncAction(
        async (rentAdditionalService) => {
            return await updateRentAdditionalService(
                sessionKey.value,
                rentAdditionalService
            );
        }
    );

    const addPayment = doAsyncAction(async (payment) => {
        return await addPaymentToRent(sessionKey.value, payment);
    });

    const removePayment = doAsyncAction(async (payment) => {
        rentData.value.payments = rentData.value?.payments?.filter(
            ({ date }) => date === payment.date
        );

        return await removePaymentFromRent(sessionKey.value, payment);
    });

    const addDiscountByValue = doAsyncAction(async (value) => {
        return await addDiscountToRentByValue(sessionKey.value, value);
    });

    const addDiscountByPromo = doAsyncAction(async (promo) => {
        return await addDiscountToRentByPromo(sessionKey.value, promo);
    });

    const removeDiscount = doAsyncAction(async (tempKey) => {
        rentData.value.discounts = rentData.value?.discounts?.filter(
            ({ temporaryKey }) => temporaryKey !== tempKey
        );

        return await removeDiscountFromRent(sessionKey.value, tempKey);
    });

    const addDeposit = doAsyncAction(async (id) => {
        return await addDepositToRent(sessionKey.value, id);
    });

    const removeDeposit = doAsyncAction(async (id) => {
        return await removeDepositFromRent(sessionKey.value, id);
    });

    const updateDeposit = doAsyncAction(async (deposit) => {
        return await updateRentDeposit(sessionKey.value, deposit);
    });

    const updateAdditional = doAsyncAction(async (additional) => {
        return await updateRentAdditional(sessionKey.value, additional);
    });

    const unionInventories = doAsyncAction(async (payload) => {
        return await unionRentInventories(sessionKey.value, payload);
    });

    const save = doAsyncAction(async () => {
        await saveRent(sessionKey.value);
        await router.push({ name: RENT_ROUTE_NAMES.LIST });
    });

    const close = doAsyncAction(async (payload) => {
        await closeRent(sessionKey.value, payload);
    });

    const prepare = doAsyncAction(async (id) => {
        const { rentSessionKey, rent } = await prepareRent(id);

        rentId.value = id;

        if (!rentSessionKey || !rent) {
            throw new Error();
        }

        sessionKey.value = rentSessionKey;

        return rent;
    });

    const cancel = doAsyncAction(async () => {
        await cancelRents([{ id: rentId.value }]);
        await prepare(rentId.value);
    });

    function doAsyncAction(action) {
        return async function asyncActionWrapper(...params) {
            const func = async function () {
                return await action(...params);
            };

            queue.value.push(func);

            if (isLoading.value) return;

            isLoading.value = true;

            let resultedRentData = cloneDeep(DEFAULT_RENT_DATA);

            while (queue.value.length > 0) {
                const funcToExecute = queue.value.at(0);

                try {
                    resultedRentData = await funcToExecute();
                } catch (e) {
                    console.error(e);
                }

                queue.value.shift();
            }

            rentData.value = resultedRentData;
            isLoading.value = false;
        };
    }

    function $reset() {
        rentData.value = cloneDeep(DEFAULT_RENT_DATA);
        sessionKey.value = null;
    }

    return {
        // data
        rentData,
        sessionKey,
        isLoading,

        // preparing
        prepare,

        // calculating
        setPeriod,

        addClient,
        removeClient,

        addInventory,
        removeInventory,
        updateInventory,
        finishInventory,
        finishInventoryGroup,

        addKit,
        removeKit,
        updateKit,

        addBreaking,
        removeBreaking,

        addProduct,
        removeProduct,
        updateProduct,

        addAdditionalService,
        removeAdditionalService,
        updateAdditionalService,

        addPayment,
        removePayment,

        addDiscountByPromo,
        addDiscountByValue,
        removeDiscount,

        addDeposit,
        removeDeposit,
        updateDeposit,

        updateAdditional,

        unionInventories,

        // saving
        save,

        // closing
        close,

        // state changing
        cancel,

        // mics
        $reset,
    };
});
