import {
    getDataMapper,
    mapField,
    mapMediaToServer,
    getObjectId,
} from "@/shared";

export const mapKitToServer = getDataMapper({
    id: mapField("id"),
    title: mapField("title"),
    article_number: mapField("articleNumber", String),
    rent_number: mapField("rentNumber", String),
    prices: mapField("prices", getObjectId),
    description: mapField("description"),
    discounts: mapField("discounts", getObjectId),
    objects: mapField("objects", {
        id: mapField("id", Number),
        category_id: mapField("category", getObjectId),
        inventory_id: mapField("inventory", getObjectId),
        count: mapField("count", Number),
    }),
    option: mapField("options", JSON.stringify, { applyToElements: false }),
    media: mapField("media", mapMediaToServer),
    // category_resource_id: mapField("categoryResourceId", Number),
});
