import { getDataMapper, mapField } from "../../../lib";

import { mapContactFromServer } from "./mapContactsFromServer";
import { mapHumanFromServer } from "./mapHumanFromServer";
import { mapMediaFromServer } from "./mapMediaFromServer";
import { mapPointFromServer } from "./mapPointsFromServer";
import { mapShiftFromServer } from "./mapShiftsFromServer";
import { mapUserFromServer } from "./mapUserFromServer";

export const mapEmployeeFromServer = getDataMapper({
    id: mapField("id", Number),
    createdAt: mapField("created_at"),
    updatedAt: mapField("updated_at"),
    address: mapField("address"),
    employeePositionId: mapField("employee_position", (elem) => elem?.id),
    employeePosition: mapField("employee_position", {
        id: mapField("id", Number),
        title: mapField("title"),
        const: mapField("const"),
    }),
    user: mapField("user", mapUserFromServer),
    userId: mapField("user_id", Number),
    humanId: mapField("human_id", Number),
    human: mapField("human", mapHumanFromServer),
    contacts: mapField("contacts", mapContactFromServer),
    points: mapField("points", mapPointFromServer),
    shift: mapField("shift", mapShiftFromServer),
    fio: mapField("fio"),
    isAdmin: mapField("is_admin"),
    isClient: mapField("is_client"),
    isDirector: mapField("is_director"),
    isEmployee: mapField("is_employee"),
    number: mapField("number"),
    shortFio: mapField("short_fio"),
    rentsSum: mapField("rents_sum", Number),
    shiftsCount: mapField("shifts_count", Number),
    media: mapField("media", mapMediaFromServer),
});
