import { getDataMapper, mapField, mapArray, isNil } from "../../../lib";

function CategoryMetaData() {
    this.body = mapField("body");
    this.cashDeposit = mapField("sum_deposit", Number);
    this.children = mapField("children", this);
    this.const = mapField("const");
    this.controllerId = mapField("controller_id");
    this.description = mapField("description");
    this.dictId = mapField("dict_id", Number);
    this.disabled = mapField("disabled", Boolean);
    this.discounts = mapField(
        "discounts",
        (discounts) => {
            if (isNil(discounts)) return [];

            return discounts.map((discount) => Number(discount));
        },
        { applyToElements: false }
    );
    this.id = mapField("id", Number);
    this.orderId = mapField("order_id");
    this.parentId = mapField("parent_id");
    this.tariffs = mapField(
        "prices",
        (prices) => {
            if (isNil(prices)) return [];

            return prices.map((price) => Number(price));
        },
        { applyToElements: false, applyToNil: true }
    );
    this.selected = mapField("selected", Boolean);
    this.title = mapField("title", String);
    this.count = mapField("count_inventory", Number);
}

export const mapCategoryFromServer = getDataMapper(new CategoryMetaData());

export const mapCategoriesFromServer = mapArray(mapCategoryFromServer);
