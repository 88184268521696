import { makeHttpRequest } from "@/shared";

import { mapRentsToCloseToServer } from "./mappers/mapRentsToCloseToServer";

export const closeRents = async (rents) => {
    const response = (
        await makeHttpRequest({
            url: "/rent/close-multiple",
            method: "POST",
            data: {
                rents: mapRentsToCloseToServer(rents),
            },
        })
    ).data;

    return response;
};
