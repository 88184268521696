import { makeHttpRequest } from "@/shared";

import { mapCalculatedRepairFromServer } from "./mappers/mapCalculatedRepairFromServer";
export const addRepairDiscountByValue = async (sessionKey, value) => {
    const response = (
        await makeHttpRequest({
            url: `/repair/builder/${sessionKey}/add/discount/custom`,
            method: "PUT",
            data: {
                value: Number(value),
            },
        })
    ).data;

    return mapCalculatedRepairFromServer(response.data);
};
