import { makeHttpRequest } from "@/shared";

import { mapExpenseFromServer } from "./mappers/mapExpensesFromServer";

export const getExpense = async (id) => {
    const response = (
        await makeHttpRequest({
            url: `/expense/${id}`,
            method: "GET",
        })
    ).data;

    return mapExpenseFromServer(response.data);
};
