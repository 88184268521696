import { getDataMapper, mapArray, mapField } from "@/shared";

export const mapEmployeePositionFromServer = getDataMapper({
    id: mapField("id"),
    title: mapField("title"),
    const: mapField("const"),
});

export const mapEmployeePositionsFromServer = mapArray(
    mapEmployeePositionFromServer
);
