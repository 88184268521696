import { makeHttpRequest } from "@/shared";

import { mapCalculatedRentFromServer } from "./mappers/mapCalculatedRentFromServer";
import { mapRentAdditionalServiceToServer } from "./mappers/mapRentAdditionalServiceToServer";

export const removeAdditionalServiceFromRent = async (sessionKey, service) => {
    const response = (
        await makeHttpRequest({
            url: `/rent/builder/${sessionKey}/remove/service`,
            method: "DELETE",
            data: mapRentAdditionalServiceToServer(service),
        })
    ).data;

    return mapCalculatedRentFromServer(response.data);
};
