import { getDataMapper, mapField, getObjectId } from "@/shared";

export const mapClientFiltersToServer = getDataMapper(
    {
        source_attraction_id: mapField("sourcesAttraction", getObjectId),
        black_list: mapField("isOnlyBlacklisted", Number),
        has_debts: mapField("clientDebtType", (type) => {
            switch (type) {
                case "debtors":
                    return true;
                case "withoutDebts":
                    return false;
                default:
                    return null;
            }
        }),
        is_legal_entity: mapField("clientType", (type) => {
            switch (type) {
                case "legalEntity":
                    return true;
                case "naturalPerson":
                    return false;
                default:
                    return null;
            }
        }),
    },
    {
        shouldAvoidUndefined: true,
        shouldAvoidNull: true,
    }
);
