import { makeHttpRequest } from "@/shared";

import { mapRepairsToCloseToServer } from "./mappers/mapRepairsToCloseToServer";

export const closeRepairs = async (repairs) => {
    const response = (
        await makeHttpRequest({
            url: "/repair/close-multiple",
            method: "POST",
            data: {
                repairs: mapRepairsToCloseToServer(repairs),
            },
        })
    ).data;

    return response;
};
