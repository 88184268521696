import { defineStore } from "pinia";

import { useEntityStore, useSelectEntityStore } from "@/shared";

import { addInventoryStatus } from "../api/addInventoryStatus";
import { deleteInventoryStatus } from "../api/deleteInventoryStatus";
import { deleteInventoryStatuses } from "../api/deleteInventoryStatuses";
import { editInventoryStatus } from "../api/editInventoryStatus";
import { getInventoryStatus } from "../api/getInventoryStatus";
import { getInventoryStatuses } from "../api/getInventoryStatuses";

export const useInventoryStatusesStore = defineStore(
    "inventoryStatuses",
    () => {
        const {
            isManyLoading,
            isOneLoading,
            listData,
            singleData,
            meta,
            permissions,
            addOne,
            deleteOne,
            deleteMany,
            editOne,
            fetchMany,
            getOne,
            setManyLoading,
        } = useEntityStore({
            addEntity: addInventoryStatus,
            getEntity: getInventoryStatus,
            getEntities: getInventoryStatuses,
            deleteEntity: deleteInventoryStatus,
            deleteEntities: deleteInventoryStatuses,
            editEntity: editInventoryStatus,
        });

        const {
            selectMeta,
            selectData,
            isSelectLoading,
            selectPrevPaginationParams,
            fetchSelect,
        } = useSelectEntityStore({
            getEntities: getInventoryStatuses,
        });

        return {
            // entity
            listData,
            singleData,
            isManyLoading,
            isOneLoading,
            meta,
            permissions,
            fetchMany,
            deleteOne,
            deleteMany,
            getOne,
            editOne,
            addOne,
            setManyLoading,

            // select
            selectData,
            selectMeta,
            isSelectLoading,
            selectPrevPaginationParams,
            fetchSelect,
        };
    }
);
