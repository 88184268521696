import {
    makeHttpRequest,
    mapPaginationParamsToServer,
    mapDatabaseParamsFromServer,
} from "@/shared";

import { mapInventoriesFromServer } from "./mappers/mapInventoriesFromServer";
import { mapInventoryFiltersToServer } from "./mappers/mapInventoryFiltersToServer";

export const getInventories = async (paginationParams, filters) => {
    const response = (
        await makeHttpRequest({
            url: "/inventory",
            method: "GET",
            params: {
                ...mapPaginationParamsToServer(paginationParams),
                ...mapInventoryFiltersToServer(filters),
            },
        })
    ).data;

    return {
        data: mapInventoriesFromServer(response.data),
        ...mapDatabaseParamsFromServer(response),
    };
};
