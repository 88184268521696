import { getDataMapper, mapField } from "@/shared";

export const mapRepairAdditionalToServer = getDataMapper(
    {
        comment: mapField("comment"),
        need_calc_repair_delay: mapField("shouldCalculateRepairDelay", Boolean),
        need_calc_breaking: mapField("shouldCalculateBreaking", Boolean),
        admin_user_id: mapField("adminUser", (user) => user.userId),
        open_point_id: mapField("point", Number),
        close_point_id: mapField("point", Number),
    },
    { shouldAvoidUndefined: true, shouldAvoidNull: true }
);
