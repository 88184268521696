import {
    makeHttpRequest,
    mapDatabaseParamsFromServer,
    mapPaginationParamsToServer,
} from "@/shared";

import { mapSalariesFromServer } from "./mappers/mapSalariesFromServer";

export const getSalaries = async (paginationParams = {}) => {
    const response = (
        await makeHttpRequest({
            url: "/human/employee/salary",
            method: "GET",
            params: mapPaginationParamsToServer(paginationParams),
        })
    ).data;

    return {
        data: mapSalariesFromServer(response.data),
        ...mapDatabaseParamsFromServer(response),
    };
};
