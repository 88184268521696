import { makeHttpRequest } from "@/shared";

import { mapCalculatedRentFromServer } from "./mappers/mapCalculatedRentFromServer";

export const addDepositToRent = async (sessionKey, id) => {
    const response = (
        await makeHttpRequest({
            url: `/rent/builder/${sessionKey}/add/deposit`,
            method: "PUT",
            data: {
                deposit_id: Number(id),
            },
        })
    ).data;

    return mapCalculatedRentFromServer(response.data);
};
