import { defineStore } from "pinia";
import { ref } from "vue";
import { useRouter } from "vue-router";

import { REPAIR_ROUTE_NAMES, cloneDeep } from "@/shared";

import { addRepairAdditionalService } from "../api/addRepairAdditionalService";
import { addRepairBreaking } from "../api/addRepairBreaking";
import { addRepairClient } from "../api/addRepairClient";
import { addRepairDiscountByPromo } from "../api/addRepairDiscountByPromo";
import { addRepairDiscountByValue } from "../api/addRepairDiscountByValue";
import { addRepairInventory } from "../api/addRepairInventory";
import { addRepairPayment } from "../api/addRepairPayment";
import { addRepairProduct } from "../api/addRepairProduct";
import { addRepairService } from "../api/addRepairService";
import { changeRepairStatus } from "../api/changeRepairStatus";
import { prepareRepair } from "../api/prepareRepair";
import { removeRepairAdditionalService } from "../api/removeRepairAdditionalService";
import { removeRepairBreaking } from "../api/removeRepairBreaking";
import { removeRepairClient } from "../api/removeRepairClient";
import { removeRepairDiscount } from "../api/removeRepairDiscount";
import { removeRepairInventory } from "../api/removeRepairInventory";
import { removeRepairPayment } from "../api/removeRepairPayment";
import { removeRepairProduct } from "../api/removeRepairProduct";
import { removeRepairService } from "../api/removeRepairService";
import { saveRepair } from "../api/saveRepair";
import { setRepairPeriod } from "../api/setRepairPeriod";
import { updateRepairAdditional } from "../api/updateRepairAdditional";
import { updateRepairAdditionalService } from "../api/updateRepairAdditionalService";
import { updateRepairInventory } from "../api/updateRepairInventory";
import { updateRepairProduct } from "../api/updateRepairProduct";

const DEFAULT_REPAIR_DATA = {
    timeStart: null,
    timeEnd: null,
    humanId: null,
    client: null,
    inventories: [],
    discounts: [],
    products: [],
    additionalServices: [],
};

export const useRepairEditorStore = defineStore("repairEditor", () => {
    const sessionKey = ref(null);
    const repairData = ref(cloneDeep(DEFAULT_REPAIR_DATA));
    const isLoading = ref(false);
    const repairId = ref(null);
    const queue = ref([]);

    const router = useRouter();

    const setPeriod = doAsyncAction(async () => {
        return await setRepairPeriod(sessionKey.value, repairData.value);
    });

    const addClient = doAsyncAction(async () => {
        return await addRepairClient(
            sessionKey.value,
            repairData.value.client.human.id
        );
    });

    const removeClient = doAsyncAction(async () => {
        return await removeRepairClient(sessionKey.value);
    });

    const addInventory = doAsyncAction(async (repairInventory) => {
        return await addRepairInventory(sessionKey.value, repairInventory);
    });

    const removeInventory = doAsyncAction(async (repairInventory) => {
        return await removeRepairInventory(sessionKey.value, repairInventory);
    });

    const updateInventory = doAsyncAction(async (repairInventory) => {
        return await updateRepairInventory(sessionKey.value, repairInventory);
    });

    const addBreaking = doAsyncAction(async (repairBreaking) => {
        return await addRepairBreaking(sessionKey.value, repairBreaking);
    });
    const removeBreaking = doAsyncAction(async (repairBreaking) => {
        return await removeRepairBreaking(sessionKey.value, repairBreaking);
    });

    const addService = doAsyncAction(async (repairService) => {
        return await addRepairService(sessionKey.value, repairService);
    });
    const removeService = doAsyncAction(async (repairService) => {
        return await removeRepairService(sessionKey.value, repairService);
    });

    const addProduct = doAsyncAction(async (repairProduct) => {
        return await addRepairProduct(sessionKey.value, repairProduct);
    });

    const removeProduct = doAsyncAction(async (repairProduct) => {
        return await removeRepairProduct(sessionKey.value, repairProduct);
    });

    const updateProduct = doAsyncAction(async (repairProduct) => {
        return await updateRepairProduct(sessionKey.value, repairProduct);
    });

    const addAdditionalService = doAsyncAction(
        async (repairAdditionalService) => {
            return await addRepairAdditionalService(
                sessionKey.value,
                repairAdditionalService
            );
        }
    );

    const removeAdditionalService = doAsyncAction(
        async (repairAdditionalService) => {
            return await removeRepairAdditionalService(
                sessionKey.value,
                repairAdditionalService
            );
        }
    );

    const updateAdditionalService = doAsyncAction(
        async (repairAdditionalService) => {
            return await updateRepairAdditionalService(
                sessionKey.value,
                repairAdditionalService
            );
        }
    );

    const changeStatus = doAsyncAction(async (status) => {
        return await changeRepairStatus(sessionKey.value, status);
    });

    const addPayment = doAsyncAction(async (payment) => {
        return await addRepairPayment(sessionKey.value, payment);
    });

    const removePayment = doAsyncAction(async (payment) => {
        repairData.value.payments = repairData.value?.payments?.filter(
            ({ date }) => date === payment.date
        );

        return await removeRepairPayment(sessionKey.value, payment);
    });

    const addDiscountByValue = doAsyncAction(async (value) => {
        return await addRepairDiscountByValue(sessionKey.value, value);
    });

    const addDiscountByPromo = doAsyncAction(async (promo) => {
        return await addRepairDiscountByPromo(sessionKey.value, promo);
    });

    const removeDiscount = doAsyncAction(async (tempKey) => {
        repairData.value.discounts = repairData.value?.discounts?.filter(
            ({ temporaryKey }) => temporaryKey !== tempKey
        );

        return await removeRepairDiscount(sessionKey.value, tempKey);
    });

    const updateAdditional = doAsyncAction(async (additional) => {
        return await updateRepairAdditional(sessionKey.value, additional);
    });

    const save = doAsyncAction(async () => {
        await saveRepair(sessionKey.value);
        await router.push({ name: REPAIR_ROUTE_NAMES.ORDER.LIST });
    });

    const prepare = doAsyncAction(async (id) => {
        const { repairSessionKey, repair } = await prepareRepair(id);

        repairId.value = id;

        if (!repairSessionKey || !repair) {
            throw new Error();
        }

        sessionKey.value = repairSessionKey;

        return repair;
    });

    function doAsyncAction(action) {
        return async function asyncActionWrapper(...params) {
            const func = async function () {
                return await action(...params);
            };

            queue.value.push(func);

            if (isLoading.value) return;

            isLoading.value = true;

            let resultedRepairData = cloneDeep(DEFAULT_REPAIR_DATA);

            while (queue.value.length > 0) {
                const funcToExecute = queue.value.at(0);

                try {
                    resultedRepairData = await funcToExecute();
                } catch (e) {
                    console.error(e);
                }

                queue.value.shift();
            }

            repairData.value = resultedRepairData;
            isLoading.value = false;
        };
    }

    function $reset() {
        repairData.value = cloneDeep(DEFAULT_REPAIR_DATA);
        sessionKey.value = null;
    }

    return {
        // data
        repairData,
        sessionKey,
        isLoading,

        // preparing
        prepare,

        // calculating
        setPeriod,

        addClient,
        removeClient,

        addInventory,
        removeInventory,
        updateInventory,

        addBreaking,
        removeBreaking,

        addService,
        removeService,

        addProduct,
        removeProduct,
        updateProduct,

        addAdditionalService,
        removeAdditionalService,
        updateAdditionalService,

        changeStatus,

        addPayment,
        removePayment,

        addDiscountByPromo,
        addDiscountByValue,
        removeDiscount,

        updateAdditional,

        // saving
        save,

        // mics
        $reset,
    };
});
