import {
    getDataMapper,
    mapArray,
    mapCategoryFromServer,
    mapField,
    mapMediaFromServer,
    parseISO,
} from "@/shared";

import { mapInventoryPriceFromServer } from "@/entities/inventory-prices/@x";

import { mapObjectFromServer } from "./mapObjectFromServer";

const mapOptionFromServer = getDataMapper({
    title: mapField("title"),
    value: mapField("value"),
});

export const mapKitFromServer = getDataMapper({
    id: mapField("id"),
    updatedAt: mapField("updated_at", parseISO),
    title: mapField("title"),
    description: mapField("description"),
    articleNumber: mapField("article_number"),
    categoryResourceId: mapField("category_resource_id", Number),
    rentNumber: mapField("rent_number", Number),
    avatar: mapField("avatar"),
    prices: mapField("prices", mapInventoryPriceFromServer),
    resource: mapField("resource"),
    category: mapField("category", mapCategoryFromServer),
    inventories: mapField(
        "objects",
        (objects) => {
            if (!objects) {
                return [];
            }

            const inventories = objects.filter((object) => object.inventory);

            return inventories.map(mapObjectFromServer);
        },
        { applyToElements: false }
    ),
    categories: mapField(
        "objects",
        (objects) => {
            if (!objects) {
                return [];
            }

            const categories = objects.filter((object) => object.category);

            return categories.map(mapObjectFromServer);
        },
        { applyToElements: false }
    ),
    objects: mapField("objects", mapObjectFromServer),
    option: mapField("option", mapOptionFromServer),
    media: mapField("media", mapMediaFromServer),
    isOccupied: mapField("is_occupied", (flag) => {
        // TODO: убрать потом эту проверку, это из-за того, что бек раньше строку присылал
        if (flag === "false") {
            return false;
        }

        return Boolean(flag);
    }),
});

export const mapKitsFromServer = mapArray(mapKitFromServer);
