import { defineStore } from "pinia";

import { useEntityStore, useSelectEntityStore } from "@/shared";

import { addSalary } from "../api/addSalary";
import { deleteSalariesHistory } from "../api/deleteSalariesHistory";
import { deleteSalaryHistory } from "../api/deleteSalaryHistory";
import { editSalary } from "../api/editSalary";
import { getSalaries } from "../api/getSalaries";
import { getSalariesHistory } from "../api/getSalariesHistory";
import { getSalary } from "../api/getSalary";

export const useSalariesStore = defineStore("points", () => {
    const {
        isManyLoading,
        isOneLoading,
        listData,
        singleData,
        meta,
        permissions,
        addOne,
        editOne,
        fetchMany,
        getOne,
        setManyLoading,
    } = useEntityStore({
        addEntity: addSalary,
        getEntity: getSalary,
        getEntities: getSalaries,
        editEntity: editSalary,
    });

    const { selectMeta, selectData, isSelectLoading, fetchSelect } =
        useSelectEntityStore({
            getEntities: getSalaries,
        });

    const {
        isManyLoading: isSalariesHistoryLoading,
        listData: salariesHistory,
        meta: salariesHistoryMeta,
        fetchMany: fetchSalariesHistory,
        deleteOne: deleteOneHistory,
        deleteMany: deleteManyHistory,
    } = useEntityStore({
        getEntities: getSalariesHistory,
        deleteEntity: deleteSalaryHistory,
        deleteEntities: deleteSalariesHistory,
    });

    return {
        // entity
        listData,
        singleData,
        isManyLoading,
        isOneLoading,
        meta,
        permissions,
        fetchMany,
        getOne,
        editOne,
        addOne,
        setManyLoading,

        // select
        selectData,
        selectMeta,
        isSelectLoading,
        fetchSelect,

        // salaries history
        isSalariesHistoryLoading,
        salariesHistory,
        salariesHistoryMeta,
        fetchSalariesHistory,
        deleteOneHistory,
        deleteManyHistory,
    };
});
