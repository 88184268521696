import { defineStore } from "pinia";

import { useEntityStore, useSelectEntityStore } from "@/shared";

import { addSourceAttraction } from "../api/addSourceAttraction";
import { deleteSourceAttraction } from "../api/deleteSourceAttraction";
import { deleteSourcesAttraction } from "../api/deleteSourcesAttraction";
import { editSourceAttraction } from "../api/editSourceAttraction";
import { getSourceAttraction } from "../api/getSourceAttraction";
import { getSourcesAttraction } from "../api/getSourcesAttraction";

export const useSourcesAttractionStore = defineStore(
    "sourcesAttraction",
    () => {
        const {
            isManyLoading,
            isOneLoading,
            listData,
            singleData,
            meta,
            permissions,
            addOne,
            deleteOne,
            deleteMany,
            editOne,
            fetchMany,
            getOne,
            setManyLoading,
        } = useEntityStore({
            addEntity: addSourceAttraction,
            getEntity: getSourceAttraction,
            getEntities: getSourcesAttraction,
            deleteEntity: deleteSourceAttraction,
            deleteEntities: deleteSourcesAttraction,
            editEntity: editSourceAttraction,
        });

        const { selectMeta, selectData, isSelectLoading, fetchSelect } =
            useSelectEntityStore({
                getEntities: getSourcesAttraction,
            });

        return {
            // entity
            listData,
            singleData,
            isManyLoading,
            isOneLoading,
            meta,
            permissions,
            fetchMany,
            deleteOne,
            deleteMany,
            getOne,
            editOne,
            addOne,
            setManyLoading,

            // select
            selectData,
            selectMeta,
            isSelectLoading,
            fetchSelect,
        };
    }
);
