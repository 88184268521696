import { makeHttpRequest } from "@/shared";

import { mapProductToServer } from "./mappers/mapProductToServer";

export const editProduct = async (id, product) => {
    const response = (
        await makeHttpRequest({
            url: `/product/${id}`,
            method: "PUT",
            data: mapProductToServer(product),
        })
    ).data;

    return response;
};
