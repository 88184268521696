import { makeHttpRequest } from "@/shared";

import { mapCalculatedRentFromServer } from "./mappers/mapCalculatedRentFromServer";
export const prepareRent = async (id) => {
    const response = (
        await makeHttpRequest({
            url: `/rent/builder/prepare${id ? `/${id}` : ""}`,
            method: "POST",
            data: {
                time_start: null,
                time_end: null,
                currency_id: null,
                rent_state_id: null,
            },
        })
    ).data;

    return {
        rent: mapCalculatedRentFromServer(response.rent),
        rentSessionKey: response.rentSessionKey,
    };
};
