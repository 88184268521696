import { makeHttpRequest } from "@/shared";

import { mapSourceAttractionToServer } from "./mappers/mapSourceAttractionToServer";

export const addSourceAttraction = async (sourceAttraction) => {
    const response = (
        await makeHttpRequest({
            url: "/attraction",
            method: "POST",
            data: mapSourceAttractionToServer(sourceAttraction),
        })
    ).data;

    return response;
};
