import { makeHttpRequest } from "@/shared";

import { mapSalaryToServer } from "./mappers/mapSalariesToServer";

export const editSalary = async (id, salary) => {
    const response = (
        await makeHttpRequest({
            url: `/human/employee/salary/${id}`,
            method: "PUT",
            data: mapSalaryToServer(salary),
        })
    ).data;

    return response;
};
