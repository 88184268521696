import { makeHttpRequest } from "@/shared";

import { mapCalculatedRepairFromServer } from "./mappers/mapCalculatedRepairFromServer";
export const addRepairClient = async (sessionKey, humanId) => {
    const response = (
        await makeHttpRequest({
            url: `/repair/builder/${sessionKey}/add/client/${humanId}`,
            method: "PUT",
        })
    ).data;

    return mapCalculatedRepairFromServer(response.data);
};
