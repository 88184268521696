import { getDataMapper, mapField, getObjectId } from "@/shared";

export const mapRentInventoryToServer = getDataMapper(
    {
        inventory_id: mapField("inventory", getObjectId),
        price_id: mapField("price", getObjectId),
        count: mapField("count", Number),
    },
    { shouldAvoidUndefined: true }
);
