import { makeHttpRequest } from "@/shared";

import { mapCalculatedRentFromServer } from "./mappers/mapCalculatedRentFromServer";
import { mapRentAdditionalToServer } from "./mappers/mapRentAdditionalToServer";

export const updateRentAdditional = async (sessionKey, rentAdditional) => {
    const response = (
        await makeHttpRequest({
            url: `/rent/builder/${sessionKey}/add/config`,
            method: "PUT",
            data: mapRentAdditionalToServer(rentAdditional),
        })
    ).data;

    return mapCalculatedRentFromServer(response.data);
};
