import { getDataMapper, mapArray, mapField } from "@/shared";

export const mapSourceAttractionFromServer = getDataMapper({
    id: mapField("id", Number),
    title: mapField("title"),
    const: mapField("const"),
});
export const mapSourcesAttractionFromServer = mapArray(
    mapSourceAttractionFromServer
);
