import {
    makeHttpRequest,
    mapDatabaseParamsFromServer,
    mapPaginationParamsToServer,
} from "@/shared";

import { mapExpenseTypesFromServer } from "./mappers/mapExpenseTypesFromServer";

export const getExpenseTypes = async (paginationParams = {}) => {
    const response = (
        await makeHttpRequest({
            url: "/expense/type",
            method: "GET",
            params: mapPaginationParamsToServer(paginationParams),
        })
    ).data;

    return {
        data: mapExpenseTypesFromServer(response.data),
        ...mapDatabaseParamsFromServer(response),
    };
};
