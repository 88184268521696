import { getDataMapper, mapField, mapArray } from "@/shared";

const mapDepositFromServer = getDataMapper({
    id: mapField("id", Number),
    title: mapField("title"),
    const: mapField("const"),
    isMoney: mapField("is_money", Boolean),
});

export const mapDepositsFromServer = mapArray(mapDepositFromServer);
