<script setup>
import { computed } from "vue";
import { RouterLink } from "vue-router";

import BaseButton from "./BaseButton.vue";
import buttonProps from "./config/props";

const props = defineProps({
    to: Object,
    ...buttonProps,
});

const buttonClasses = computed(() => {
    const bntType = `base-link-button--${props.type}`;
    const bntSize = `base-link-button--${props.size}`;

    const arrayClasses = [];

    props.type && arrayClasses.push(bntType);
    props.size && arrayClasses.push(bntSize);

    return arrayClasses;
});
</script>

<template>
    <RouterLink :to="to" class="base-link-button" :class="buttonClasses">
        <BaseButton v-bind="props">
            <slot />
        </BaseButton>
    </RouterLink>
</template>

<style scoped lang="scss">
.base-link-button {
    display: inline-block;

    flex-shrink: 0;

    border-radius: 50%;

    &:deep(button) {
        width: 100%;
    }

    &--circle {
        width: var(--button-width);
        height: var(--button-height);

        --button-border-radius: 50%;

        &.base-link-button {
            &--sm {
                --button-width: 28px;
                --button-height: 28px;
            }

            &--md {
                --button-width: 40px;
                --button-height: 40px;
            }

            &--lg {
                --button-width: 50px;
                --button-height: 50px;
            }
        }
    }
}
</style>
