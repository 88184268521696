<script setup>
import { useVModel } from "@vueuse/core";

const props = defineProps({
    modelValue: {
        type: String,
        default: "",
    },
    isError: {
        type: Boolean,
        default: false,
    },
    name: {
        type: String,
        default: "",
    },
    readonly: { type: Boolean, default: false },
});

const emit = defineEmits(["update:modelValue", "input", "blur", "change"]);

const modelValue = useVModel(props, "modelValue", emit);
</script>

<template>
    <textarea
        class="base-textarea"
        :class="{ 'base-textarea--error': isError }"
        :name="name"
        v-model="modelValue"
        @input="$emit('input', $event)"
        @blur="(e) => $emit('blur', e)"
        @change="(e) => $emit('change', e)"
        :disabled="readonly"
    />
</template>

<style scoped lang="scss">
.base-textarea {
    display: block;

    width: 100%;
    min-height: 40px;
    padding: var(--input-padding-y) var(--input-padding-x);

    font-size: var(--base-input-text-size);

    color: inherit;

    resize: vertical;

    background-color: var(--c-neutral-10);
    background-image: none;
    border: var(--input-border);
    border-radius: var(--default-border-radius) var(--default-border-radius)
        var(--default-small-border-radius);

    transition: border-color 0.15s ease-in-out 0s,
        box-shadow 0.15s ease-in-out 0s;

    &:focus {
        border-color: var(--c-primary);
        outline: var(--input-outline);
    }

    &--error {
        border-color: var(--input-color-error);

        &:focus {
            outline: var(--input-outline-error);
        }
    }
}
</style>
