import { defineStore } from "pinia";

import { useEntityStore, useSelectEntityStore } from "@/shared";

import { addInventory } from "../api/addInventory";
import { deleteInventories } from "../api/deleteInventories";
import { deleteInventory } from "../api/deleteInventory";
import { editInventory } from "../api/editInventory";
import { getInventories } from "../api/getInventories";
import { getInventory } from "../api/getInventory";

export const useInventoriesStore = defineStore("inventories", () => {
    const {
        isManyLoading,
        isOneLoading,
        listData,
        singleData,
        meta,
        permissions,
        addOne,
        deleteOne,
        deleteMany,
        editOne,
        fetchMany,
        getOne,
        setManyLoading,
    } = useEntityStore({
        addEntity: addInventory,
        getEntities: getInventories,
        getEntity: getInventory,
        deleteEntity: deleteInventory,
        deleteEntities: deleteInventories,
        editEntity: editInventory,
    });

    const {
        selectMeta,
        selectData,
        isSelectLoading,
        selectPrevPaginationParams,
        fetchSelect,
    } = useSelectEntityStore({
        getEntities: getInventories,
    });

    return {
        // entity
        listData,
        singleData,
        isManyLoading,
        isOneLoading,
        meta,
        permissions,
        fetchMany,
        getOne,
        addOne,
        deleteOne,
        deleteMany,
        editOne,
        setManyLoading,

        // select
        selectData,
        selectMeta,
        isSelectLoading,
        selectPrevPaginationParams,
        fetchSelect,
    };
});
