<script setup>
import { storeToRefs } from "pinia";
import { onMounted, ref, watchEffect } from "vue";
import { useRouter } from "vue-router";

import {
    useEducationStore,
    useOverlayStore,
    trackActiveElement,
    transitionStage,
    refreshOverlay,
    destroyOverlay,
    mountEmptyElement,
    bringInView,
    repositionEducationStep,
} from "@/entities";
import { isNil, PROFILE_ROUTE_NAMES } from "@/shared";

import educationSteps from "../config/educationSteps";

const emit = defineEmits(["changeSidebarMode"]);

const router = useRouter();

const educationStore = useEducationStore();
const overlayStore = useOverlayStore();
const { activeStep, previousStep } = storeToRefs(educationStore);
const {
    activeElement,
    previousElement,
    overlayConfig,
    transitionCallback,
    resizeTimeout,
} = storeToRefs(overlayStore);

const stepNumber = ref(0);
const stepElement = ref(null);
const stepArrow = ref(null);
const stepHide = ref(null);

const getSidebarLink = (element, link) => {
    const links = document.querySelectorAll(element);
    let elemObj;

    links.forEach((item) => {
        if (item.pathname === link) elemObj = item;
    });

    return elemObj;
};

const getElementObject = (step) => {
    const { element, link } = step;

    if (link) {
        return getSidebarLink(element, link);
    }

    if (element) {
        return document.querySelector(element);
    }

    return mountEmptyElement();
};

const changeStep = (element, step) => {
    const duration = 400;
    const start = Date.now();

    const fromElement = activeElement.value || element;

    const isFirstHighlight = isNil(previousStep) && isNil(previousElement);
    const isAnimatedTour = overlayConfig.value.animate;

    const hasDelayedPopover = !isFirstHighlight && isAnimatedTour;
    let isPopoverRendered = false;

    const animate = () => {
        if (transitionCallback.value !== animate) return;

        const elapsed = Date.now() - start;
        const remaining = duration - elapsed;
        const isHalfwayThrough = remaining <= duration / 2;

        if (
            stepElement.value &&
            isHalfwayThrough &&
            !isPopoverRendered &&
            hasDelayedPopover
        ) {
            repositionEducationStep(element, step);
            isPopoverRendered = true;
        }

        if (overlayConfig.value.animate && elapsed < duration) {
            transitionStage(elapsed, duration, fromElement, element);
        } else {
            trackActiveElement(element);

            transitionCallback.value = null;
        }

        window.requestAnimationFrame(animate);
    };

    transitionCallback.value = animate;

    window.requestAnimationFrame(animate);

    if (step.scroll) {
        document.body.style.overflow = "auto";
    } else {
        document.body.style.overflow = "hidden";
    }

    bringInView(element);
};

const nextStep = () => {
    if (stepHide.value.classList.contains("education__hide--open")) {
        stepHide.value.classList.remove("education__hide--open");
        stepElement.value.style.transform = null;
    }

    stepNumber.value++;

    if (
        window.matchMedia("(max-width: 576px)").matches &&
        (stepNumber.value === 3 || stepNumber.value === 12)
    ) {
        emit("changeSidebarMode", false);
    } else if (window.matchMedia("(max-width: 576px)").matches) {
        emit("changeSidebarMode", true);
    }

    const elemObj = getElementObject(educationSteps[stepNumber.value]);

    changeStep(elemObj, educationSteps[stepNumber.value]);
    educationStore.changeActiveStep(educationSteps[stepNumber.value]);
    overlayStore.changeActiveElement(elemObj);
};

const prevStep = () => {
    if (stepHide.value.classList.contains("education__hide--open")) {
        stepHide.value.classList.remove("education__hide--open");
        stepElement.value.style.transform = null;
    }

    switch (stepNumber.value) {
        case 4:
            stepNumber.value -= 2;
            router.push({
                name: PROFILE_ROUTE_NAMES.PROFILE,
            });
            break;

        case 5:
        case 11:
        case 14:
            stepNumber.value -= 2;
            break;

        case 12:
        case 13:
            stepNumber.value = 4;
            break;

        case 16:
            stepNumber.value = 13;
            break;

        default:
            stepNumber.value--;
    }

    if (
        window.matchMedia("(max-width: 576px)").matches &&
        (stepNumber.value === 3 || stepNumber.value === 12)
    ) {
        emit("changeSidebarMode", false);
    } else if (window.matchMedia("(max-width: 576px)").matches) {
        emit("changeSidebarMode", true);
    }

    const elemObj = getElementObject(educationSteps[stepNumber.value]);

    changeStep(elemObj, educationSteps[stepNumber.value]);
    educationStore.changeActiveStep(educationSteps[stepNumber.value]);
    overlayStore.changeActiveElement(elemObj);
};

const refreshActiveStep = () => {
    if (!activeElement.value) return;

    trackActiveElement(activeElement.value);
    refreshOverlay();
    repositionEducationStep(activeElement.value, activeStep.value);
};

const refresh = () => {
    if (resizeTimeout.value) {
        window.cancelAnimationFrame(resizeTimeout.value);
    }

    resizeTimeout.value = window.requestAnimationFrame(refreshActiveStep);
};

const unwatch = watchEffect(
    () => {
        educationStore.setStepPopoverElement({
            wrapper: stepElement.value,
            arrow: stepArrow.value,
        });
    },
    {
        deep: true,
    }
);

const closeEducation = () => {
    destroyOverlay();
    document.body.style.overflow = "auto";
    document.body.classList.remove("education-active");
    document.body.classList.remove("education-fade");

    unwatch();
    window.removeEventListener("resize", refresh);
    window.removeEventListener("scroll", refresh);

    educationStore.clearEvents();
    educationStore.closeEducation();
};

const askQuestion = () => {
    window.Chatra("expandWidget");

    closeEducation();
};

const hideStep = () => {
    stepHide.value.classList.toggle("education__hide--open");
    const width = stepElement.value.getBoundingClientRect().width;

    if (stepHide.value.classList.contains("education__hide--open")) {
        stepElement.value.style.transform = `translate(${width}px)`;
    } else {
        stepElement.value.style.transform = null;
    }
};

onMounted(() => {
    document.body.style.overflow = "hidden";
    document.body.classList.add("education-active");
    document.body.classList.add("education-fade");
    router.push({
        name: PROFILE_ROUTE_NAMES.PROFILE,
    });

    const elemObj = getElementObject(educationSteps[stepNumber.value]);

    changeStep(elemObj, educationSteps[stepNumber.value]);
    educationStore.changeActiveStep(educationSteps[stepNumber.value]);
    overlayStore.changeActiveElement(elemObj);

    educationStore.listen("nextStep", nextStep);
    educationStore.listen("saveInventory", () => {
        stepNumber.value = 11;
    });
    educationStore.listen("saveRent", () => {
        stepNumber.value = 15;
    });
    educationStore.listen("createNewTariff", () => {
        stepNumber.value = 9;
        nextStep();
    });

    window.addEventListener("resize", refresh);
    window.addEventListener("scroll", refresh);
});
</script>

<template>
    <Teleport to="body">
        <div ref="stepElement" class="education__step">
            <div
                ref="stepArrow"
                v-show="educationSteps[stepNumber].arrow"
                class="education__step-arrow"
            />
            <div
                ref="stepHide"
                v-show="educationSteps[stepNumber].canHide"
                class="education__hide"
                @click="hideStep"
            />
            <Component
                :is="educationSteps[stepNumber].component"
                :steps-count="educationSteps.length"
                :current-step="stepNumber"
                @next-step="nextStep"
                @prev-step="prevStep"
                @close-education="closeEducation"
                @ask-question="askQuestion"
            />
        </div>
    </Teleport>
</template>

<style lang="scss" scoped>
.education {
    &__step {
        transition: all 0.2s ease-in-out;
    }

    &__hide {
        position: absolute;
        top: 0;
        left: -20px;

        z-index: 1;

        display: flex;

        align-items: center;
        justify-content: center;

        width: 20px;
        height: 50px;

        cursor: pointer;

        background-color: var(--c-foreground);
        filter: drop-shadow(-3px 3px 3px rgb(var(--c-rgb-neutral-30) / 30%));
        border-bottom-left-radius: 20px;

        &::before {
            content: "";

            border: 5px solid transparent;
            border-left: 10px solid var(--c-text);

            transition: all 0.3s ease;
        }

        &--open::before {
            transform: rotate(180deg);
        }
    }
}
</style>
