import { getDataMapper, mapField, getObjectId } from "@/shared";

export const mapRentAdditionalToServer = getDataMapper(
    {
        comment: mapField("comment"),
        need_calc_rent_delay: mapField("shouldCalculateRentDelay", Boolean),
        need_calc_breaking: mapField("shouldCalculateBreaking", Boolean),
        admin_user_id: mapField("adminUser", (adminUser) => adminUser.userId),
        open_point_id: mapField("openPoint", getObjectId),
        close_point_id: mapField("closePoint", getObjectId),
    },
    { shouldAvoidUndefined: true, shouldAvoidNull: true }
);
