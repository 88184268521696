import { makeHttpRequest } from "@/shared";

import { mapCalculatedSellingFromServer } from "./mappers/mapCalculatedSellingFromServer";
export const addClientToSelling = async (sessionKey, humanId) => {
    const response = (
        await makeHttpRequest({
            url: `/stock/builder/${sessionKey}/add/client/${humanId}`,
            method: "PUT",
        })
    ).data;

    return mapCalculatedSellingFromServer(response.data);
};
