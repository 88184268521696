const LANGUAGES = {
    ru: "ru",
    en: "en",
    es: "es",
    fr: "fr",
    de: "de",
    pl: "pl",
    ua: "ua",
};

export function loadWebRTC(KEY, ATC) {
    const checkZadarmaFn = new Promise((resolve, reject) => {
        if (window.zadarmaWidgetFn !== undefined) {
            resolve();
        }

        reject();
    });

    const checking = setInterval(
        checkZadarmaFn.then(() => {
            window.zadarmaWidgetFn(
                KEY,
                ATC,
                "square",
                LANGUAGES[window.defaultLocale] || LANGUAGES.ru,
                true,
                { left: "10px", bottom: "5px" }
            );
            clearInterval(checking);
        }),
        1000
    );
}
