/* eslint-disable node/no-unsupported-features/es-syntax */
import { SETTINGS_ROUTE_NAMES } from "@/shared";

export const SETTINGS_ROUTES = [
    {
        name: SETTINGS_ROUTE_NAMES.INDEX,
        component: () => import("../ui/SettingsPage.vue"),
        meta: {
            title: "Настройки",
        },
        path: "/settings",
    },
    {
        name: SETTINGS_ROUTE_NAMES.CATEGORY_EDITOR,
        component: () => import("../ui/SettingsCategoriesPage.vue"),
        meta: {
            title: "Категории",
        },
        path: "/settings/categories",
    },
    {
        name: SETTINGS_ROUTE_NAMES.CONTRACTS_LIST,
        component: () => import("../ui/SettingsContractsPage.vue"),
        meta: {
            title: "Шаблоны договоров",
        },
        path: "/settings/contracts",
    },
    {
        name: SETTINGS_ROUTE_NAMES.CONTRACTS_ADD,
        component: () => import("../ui/SettingsContractsAddPage.vue"),
        meta: {
            title: "Добавление шаблона",
        },
        path: "/settings/contracts/add",
    },
    {
        name: SETTINGS_ROUTE_NAMES.CONTRACTS_EDIT,
        component: () => import("../ui/SettingsContractsEditPage.vue"),
        path: "/settings/contracts/edit/:id",
        meta: {
            title: "Редактирование шаблона",
        },
        props: true,
    },
    {
        name: SETTINGS_ROUTE_NAMES.INTEGRATIONS,
        component: () => import("../ui/SettingsIntegrations.vue"),
        meta: {
            title: "Интеграции",
        },
        path: "/settings/integrations",
    },
];
