import { makeHttpRequest } from "@/shared";

export const deleteEmployee = async (employeeId) => {
    return (
        await makeHttpRequest({
            url: `/human/employee/${employeeId}`,
            method: "DELETE",
        })
    ).data;
};
