import {
    getDataMapper,
    mapArray,
    mapCategoryFromServer,
    mapField,
    getObjectId,
    mapMediaFromServer,
    mapPointFromServer,
    parseISO,
    isArray,
} from "@/shared";

import { mapInventoryPriceFromServer } from "@/entities/inventory-prices/@x";
import { mapInventoryStatusFromServer } from "@/entities/inventory-statuses/@x";

const mapOptionFromServer = getDataMapper({
    title: mapField("title"),
    value: mapField("value"),
});

export const mapInventoryFromServer = getDataMapper({
    id: mapField("id"),
    updatedAt: mapField("updated_at", parseISO),
    title: mapField("title"),
    description: mapField("description"),
    stateId: mapField("state_id", Number),
    point: mapField("point", mapPointFromServer),
    pointId: mapField("point", getObjectId),
    articleNumber: mapField("article_number"),
    category: mapField("category", mapCategoryFromServer),
    categoryId: mapField("category", getObjectId),
    rentNumber: mapField("rent_number", String),
    isHidden: mapField("hidden", Boolean),
    another: mapField("another"),
    amountRentSum: mapField("sum_amount_payment", Number),
    amountRentTime: mapField("amount_rent_time", Number),
    amountRentCount: mapField("amount_rent_count", Number),
    parentId: mapField("parent_id", Number),
    childrenCount: mapField("children_count", Number),
    expenseSum: mapField("expense_sum", Number),
    cashDeposit: mapField("cash_deposit"),
    avatar: mapField("avatar"),
    prices: mapField("prices", mapInventoryPriceFromServer),
    isGroup: mapField("is_group", Boolean),
    // todo: просмотреть все использования этого свойства, поменять на status и удалить
    state: mapField("state", {
        id: mapField("id", Number),
        title: mapField("title"),
        textСolor: mapField("text_color"),
        color: mapField("color"),
        const: mapField("const"),
    }),
    status: mapField("state", {
        title: mapField("title"),
        color: mapField("color"),
        const: mapField("const"),
    }),
    statusId: mapField("state", getObjectId),
    activeDelivery: mapField("active_delivery"),
    resource: mapField("resource"),
    children: mapField(
        "children",
        (children) => {
            const res = [];

            if (!isArray(children)) return res;

            children.forEach((child) => {
                for (let i = 0; i < child.count; i++) {
                    res.push({
                        state: mapInventoryStatusFromServer(child?.state),
                        stateId: child?.state?.id,
                    });
                }
            });

            return res;
        },
        { applyToElements: false }
    ),
    childrenGrouped: mapField("children", {
        state: mapField("state", mapInventoryStatusFromServer),
        stateId: mapField("state", getObjectId),
        count: mapField("count", Number),
    }),
    discounts: mapField("discounts"),
    option: mapField("option", mapOptionFromServer),
    media: mapField("media", mapMediaFromServer),
    buyPrice: mapField("buy_price", Number),
    buyDate: mapField("buy_date", parseISO),
    isOccupied: mapField("is_occupied", (flag) => {
        // TODO: убрать потом эту проверку, это из-за того, что бек раньше строку присылал
        if (flag === "false") {
            return false;
        }

        return Boolean(flag);
    }),
    countFree: mapField("count_free_children", Number),
});

export const mapInventoriesFromServer = mapArray(mapInventoryFromServer);
