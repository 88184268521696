import { getDataMapper, mapField, mapArray, formatISODate } from "@/shared";

export const mapSalaryHistoryFromServer = getDataMapper({
    id: mapField("id"),
    salary: mapField("salary", Number),
    employee: mapField("employee", String),
    bonus: mapField("bonus", Number),
    sum: mapField("sum", Number),
    wage: mapField("wage", Number),
    periodStart: mapField("period_start"),
    periodEnd: mapField("period_end"),
    createdAt: mapField("created_at", formatISODate),
});

export const mapSalariesHistoryFromServer = mapArray(
    mapSalaryHistoryFromServer
);
