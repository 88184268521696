import { getDataMapper, mapField, mapArray } from "@/shared";

const mapMessageTemplateFromServer = getDataMapper({
    name: mapField("name"),
    event: mapField("event"),
    text: mapField("text"),
    isActive: mapField("is_active"),
    remainPeriod: mapField("remain_period"),
});

export const mapMessagesTemplatesFromServer = mapArray(
    mapMessageTemplateFromServer
);
