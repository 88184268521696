import { makeHttpRequest } from "@/shared";

import { mapExpenseTypeToServer } from "./mappers/mapExpenseTypeToServer";

export const addExpenseType = async (sourceAttraction) => {
    const response = (
        await makeHttpRequest({
            url: "/expense/type",
            method: "POST",
            data: mapExpenseTypeToServer(sourceAttraction),
        })
    ).data;

    return response;
};
