import { makeHttpRequest } from "@/shared";

export const deleteRefusalType = async (id) => {
    return (
        await makeHttpRequest({
            url: `/delivery/refusal-type/${id}`,
            method: "DELETE",
        })
    ).data;
};
