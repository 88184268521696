import { makeHttpRequest } from "@/shared";

import { mapClientFromServer } from "./mappers/from/mapClientFromServer";

export const getClient = async (clientId) => {
    const response = (
        await makeHttpRequest({
            url: `/human/client/${clientId}`,
            method: "GET",
        })
    ).data;

    return mapClientFromServer(response.data);
};
