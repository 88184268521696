import { defineStore } from "pinia";

import { useEntityStore } from "@/shared";

import { getFreeInventoriesByRent } from "../api/getFreeInventoriesByRent";

export const useFreeInventoriesStore = defineStore("freeInventories", () => {
    const {
        isManyLoading,
        listData,
        meta,
        permissions,
        fetchMany,
        setManyLoading,
    } = useEntityStore({
        getEntities: getFreeInventoriesByRent,
    });

    return {
        listData,
        isManyLoading,
        meta,
        permissions,
        fetchMany,
        setManyLoading,
    };
});
