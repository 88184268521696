import { getDataMapper, mapField, mapArray } from "../../../lib";
export const mapPointFromServer = getDataMapper({
    id: mapField("id", Number),
    placeId: mapField("place_id", Number),
    title: mapField("title"),
    email: mapField("email"),
    website: mapField("website"),
    phone: mapField("phone"),
    placeText: mapField("place_text"),
});

export const mapPointsFromServer = mapArray(mapPointFromServer);
