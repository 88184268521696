import {
    getDataMapper,
    mapField,
    mapArray,
    mapContactsToServer,
    mapHumanToServer,
    mapUserToServer,
    mapMediaToServer,
    getObjectId,
} from "@/shared";

export const mapEmployeeToServer = getDataMapper({
    id: mapField("id", Number),
    created_at: mapField("createdAt"),
    updated_at: mapField("updatedAt"),
    address: mapField("address"),
    employee_position_id: mapField("employeePositionId", Number),
    employee_position: mapField("employeePosition", {
        id: mapField("id", Number),
        title: mapField("title"),
        const: mapField("const"),
    }),
    user: mapField("user", mapUserToServer),
    human_id: mapField("human", (item) => item.id),
    human: mapField("human", mapHumanToServer),
    contacts: mapField("contacts", mapContactsToServer, {
        applyToElements: false,
    }),
    points: mapField("points", getObjectId),
    fio: mapField("fio"),
    is_admin: mapField("isAdmin"),
    is_client: mapField("isClient"),
    is_director: mapField("isDirector"),
    is_employee: mapField("isEmployee"),
    number: mapField("number"),
    media: mapField("media", mapMediaToServer),
});

export const mapEmployeesToServer = mapArray(mapEmployeeToServer);
