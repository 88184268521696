import { defineStore } from "pinia";
import { ref } from "vue";

export const useOverlayStore = defineStore("overlayStore", () => {
    const previousElement = ref(null);
    const activeElement = ref(null);

    const activeStagePosition = ref(null);
    const overlaySvg = ref(null);
    const overlayConfig = ref({
        animate: true,
        overlayOpacity: 0.7,
        smoothScroll: true,
        stagePadding: 10,
        stageRadius: 5,
        overlayColor: "#000",
    });

    const transitionCallback = ref(null);
    const resizeTimeout = ref(null);

    const setActiveStagePosition = (position) => {
        activeStagePosition.value = position;
    };

    const setOverlaySvg = (element) => {
        overlaySvg.value = element;
    };

    const changeActiveElement = (element) => {
        previousElement.value = activeElement.value;

        activeElement.value = element;
    };

    return {
        previousElement,
        activeElement,

        activeStagePosition,
        overlaySvg,
        overlayConfig,

        transitionCallback,
        resizeTimeout,

        setActiveStagePosition,
        setOverlaySvg,
        changeActiveElement,
    };
});
