import { defineStore } from "pinia";

import { useEntityStore, useSelectEntityStore } from "@/shared";

import { addInventoryPrice } from "../api/addInventoryPrice";
import { deleteInventoryPrice } from "../api/deleteInventoryPrice";
import { deleteInventoryPrices } from "../api/deleteInventoryPrices";
import { editInventoryPrice } from "../api/editInventoryPrice";
import { getInventoryPrice } from "../api/getInventoryPrice";
import { getInventoryPrices } from "../api/getInventoryPrices";

export const useInventoryPricesStore = defineStore("inventoryPrices", () => {
    const {
        isManyLoading,
        isOneLoading,
        listData,
        singleData,
        meta,
        permissions,
        addOne,
        deleteOne,
        deleteMany,
        editOne,
        fetchMany,
        getOne,
        setManyLoading,
    } = useEntityStore({
        addEntity: addInventoryPrice,
        getEntity: getInventoryPrice,
        getEntities: getInventoryPrices,
        deleteEntity: deleteInventoryPrice,
        deleteEntities: deleteInventoryPrices,
        editEntity: editInventoryPrice,
    });

    const { selectData, selectMeta, isSelectLoading, fetchSelect } =
        useSelectEntityStore({
            getEntities: getInventoryPrices,
        });

    return {
        // entity
        listData,
        singleData,
        isManyLoading,
        isOneLoading,
        meta,
        permissions,
        fetchMany,
        deleteOne,
        deleteMany,
        getOne,
        editOne,
        addOne,
        setManyLoading,

        // select
        selectData,
        selectMeta,
        isSelectLoading,
        fetchSelect,
    };
});
