import { getDataMapper, mapArray, mapField } from "@/shared";

export const mapInventoryStatusFromServer = getDataMapper({
    id: mapField("id", Number),
    title: mapField("title"),
    const: mapField("const"),
    color: mapField("color"),
    isSystemStatus: mapField("is_system_status", Boolean),
});

export const mapInventoryStatusesFromServer = mapArray(
    mapInventoryStatusFromServer
);
