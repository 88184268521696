import { makeHttpRequest } from "@/shared";

import { mapInventoryFromServer } from "./mappers/mapInventoriesFromServer";
import { mapInventoryToServer } from "./mappers/mapInventoryToServer";

export const editInventory = async (inventoryId, inventory) => {
    const response = (
        await makeHttpRequest({
            url: `/inventory/${inventoryId}`,
            method: "PUT",
            data: mapInventoryToServer(inventory),
        })
    ).data;

    return { inventory: mapInventoryFromServer(response.data) };
};
