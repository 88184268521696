export const detectKkmServer = () => {
    return window.KkmServer !== undefined;
};

export const executeCommand = async (Data, successFunction) => {
    return new Promise((resolve, reject) => {
        window.KkmServer.Execute(
            successFunction !== undefined
                ? successFunction
                : (message) => {
                      if (message.Status < 2) return resolve();

                      reject(
                          new Error(
                              message.Status === 2
                                  ? message.Error
                                  : "Данные не найдены!"
                          )
                      );
                  },
            Data
        );
    });
};
