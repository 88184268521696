import { defineStore } from "pinia";
import { ref } from "vue";

import { getContractCodes } from "../api/getContractCodes";

export const useContractCodesStore = defineStore("contractCodes", () => {
    const codes = ref([]);

    async function fetchCodes() {
        codes.value = await getContractCodes();
    }

    return { codes, fetchCodes };
});
