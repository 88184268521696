import { defineStore } from "pinia";

import { useEntityStore, useSelectEntityStore } from "@/shared";

import { addRepairStatus } from "../api/addRepairStatus";
import { deleteRepairStatus } from "../api/deleteRepairStatus";
import { deleteRepairStatuses } from "../api/deleteRepairStatuses";
import { editRepairStatus } from "../api/editRepairStatus";
import { getRepairStatus } from "../api/getRepairStatus";
import { getRepairStatuses } from "../api/getRepairStatuses";

export const useRepairStatusesStore = defineStore("repairStatuses", () => {
    const {
        isManyLoading,
        isOneLoading,
        listData,
        singleData,
        meta,
        permissions,
        addOne,
        deleteOne,
        deleteMany,
        editOne,
        fetchMany,
        getOne,
        setManyLoading,
    } = useEntityStore({
        addEntity: addRepairStatus,
        deleteEntity: deleteRepairStatus,
        deleteEntities: deleteRepairStatuses,
        editEntity: editRepairStatus,
        getEntity: getRepairStatus,
        getEntities: getRepairStatuses,
    });

    const {
        selectMeta,
        selectData,
        isSelectLoading,
        selectPrevPaginationParams,
        fetchSelect,
    } = useSelectEntityStore({
        getEntities: getRepairStatuses,
    });

    return {
        // entity
        listData,
        singleData,
        isManyLoading,
        isOneLoading,
        meta,
        permissions,
        fetchMany,
        deleteOne,
        deleteMany,
        getOne,
        editOne,
        addOne,
        setManyLoading,

        // select
        selectData,
        selectMeta,
        isSelectLoading,
        selectPrevPaginationParams,
        fetchSelect,
    };
});
