import { makeHttpRequest } from "@/shared";

import { mapExpenseToServer } from "./mappers/mapExpenseToServer";

export const addExpense = async (expense) => {
    const response = (
        await makeHttpRequest({
            url: "/expense",
            method: "POST",
            data: mapExpenseToServer(expense),
        })
    ).data;

    return response;
};
