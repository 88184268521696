import { ref } from "vue";

import { isEqual } from "@/shared";

export const useSelectEntityStore = ({ getEntities }) => {
    const selectData = ref([]);
    const isSelectLoading = ref(false);
    const selectMeta = ref({
        total: 1,
        page: 1,
    });
    const selectPrevPaginationParams = ref({});

    async function fetchSelect(
        paginationParams = selectPrevPaginationParams.value,
        options = { forceFetching: false, filtersData: {} }
    ) {
        if (!isEqual(paginationParams, selectPrevPaginationParams.value)) {
            options.forceFetching = true;
        }

        if (
            !options.forceFetching &&
            (isSelectLoading.value ||
                (selectMeta.value.total > 0 &&
                    selectData.value.length >= selectMeta.value.total))
        )
            return;

        try {
            isSelectLoading.value = true;
            selectPrevPaginationParams.value = paginationParams;

            const fetchResponse = await getEntities(
                paginationParams,
                options.filtersData
            );

            selectData.value = fetchResponse.data;
            selectMeta.value = fetchResponse.meta;
        } finally {
            isSelectLoading.value = false;
        }
    }

    return {
        selectData,
        isSelectLoading,
        selectMeta,
        selectPrevPaginationParams,
        fetchSelect,
    };
};
