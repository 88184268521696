import { makeHttpRequest } from "@/shared";

import { mapCalculatedRentFromServer } from "./mappers/mapCalculatedRentFromServer";
import { mapRentKitToServer } from "./mappers/mapRentKitToServer";

export const removeKitFromRent = async (sessionKey, kit) => {
    const response = (
        await makeHttpRequest({
            url: `/rent/builder/${sessionKey}/remove/kit`,
            method: "DELETE",
            data: mapRentKitToServer(kit),
        })
    ).data;

    return mapCalculatedRentFromServer(response.data);
};
