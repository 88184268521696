import { getDataMapper, mapField } from "@/shared";

export const mapIncomesFiltersToServer = getDataMapper({
    period: mapField("period", {
        from: mapField("from", String),
        to: mapField("to", String),
    }),
    point_id: mapField("pointId", Number),
    human_id: mapField("human", (human) => human.human.id),
    type: mapField("type", String),
});
