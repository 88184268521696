import { makeHttpRequest } from "@/shared";

export const deleteClient = async (clientId) => {
    const response = (
        await makeHttpRequest({
            url: `/human/client/${clientId}`,
            method: "DELETE",
        })
    ).data;

    return { client: response };
};
