import { defineStore } from "pinia";

import { useEntityStore, useSelectEntityStore } from "@/shared";

import { createEmployee } from "../api/createEmployee";
import { deleteEmployee } from "../api/deleteEmployee";
import { deleteEmployees } from "../api/deleteEmployees";
import { editEmployee } from "../api/editEmployee";
import { getEmployee } from "../api/getEmployee";
import { getEmployees } from "../api/getEmployees";
import { getEmployeesWithDirector } from "../api/getEmployeesWithDirector";

export const useEmployeesStore = defineStore("employees", () => {
    const {
        isManyLoading,
        isOneLoading,
        listData,
        singleData,
        meta,
        permissions,
        addOne,
        deleteOne,
        deleteMany,
        editOne,
        fetchMany,
        getOne,
        setManyLoading,
    } = useEntityStore({
        addEntity: createEmployee,
        getEntities: getEmployees,
        getEntity: getEmployee,
        deleteEntity: deleteEmployee,
        deleteEntities: deleteEmployees,
        editEntity: editEmployee,
    });

    const { selectData, selectMeta, isSelectLoading, fetchSelect } =
        useSelectEntityStore({
            getEntities: getEmployeesWithDirector,
        });

    return {
        // entity
        listData,
        singleData,
        isManyLoading,
        isOneLoading,
        meta,
        permissions,
        fetchMany,
        getOne,
        addOne,
        deleteOne,
        deleteMany,
        editOne,
        setManyLoading,

        // select
        selectData,
        selectMeta,
        isSelectLoading,
        fetchSelect,
    };
});
