import { makeHttpRequest } from "@/shared";

import { mapCalculatedSellingFromServer } from "./mappers/mapCalculatedSellingFromServer";

export const addDiscountToSellingByPromo = async (sessionKey, promocode) => {
    const response = (
        await makeHttpRequest({
            url: `/stock/builder/${sessionKey}/add/discount/promocode`,
            method: "PUT",
            data: {
                promocode,
            },
        })
    ).data;

    return mapCalculatedSellingFromServer(response.data);
};
