import { makeHttpRequest } from "@/shared";

import { mapCalculatedRentFromServer } from "./mappers/mapCalculatedRentFromServer";
import { mapRentTimeToServer } from "./mappers/mapRentTimeToServer";
export const setRentPeriod = async (sessionKey, rent) => {
    const response = (
        await makeHttpRequest({
            url: `/rent/builder/${sessionKey}/add/time`,
            method: "PUT",
            data: mapRentTimeToServer(rent),
        })
    ).data;

    return mapCalculatedRentFromServer(response.data);
};
