import { defineStore } from "pinia";
import { ref } from "vue";

export const useEducationStore = defineStore("educationStore", () => {
    const isShowEducation = ref(false);
    const shouldShowEducation = ref(true);
    const isFirstEnter = ref(false);

    const activeStep = ref(null);
    const previousStep = ref(null);
    const stepElement = ref(null);

    const educationEvents = ref({
        nextStep: null,
        saveInventory: null,
        saveRent: null,
        createNewTariff: null,
    });

    const showEducation = () => {
        isShowEducation.value = true;
    };

    const closeEducation = () => {
        isShowEducation.value = false;
    };

    const changeActiveStep = (step) => {
        previousStep.value = activeStep.value;
        activeStep.value = step;
    };

    const setStepPopoverElement = (element) => {
        stepElement.value = element;
    };

    const listen = (name, callback) => {
        educationEvents.value[name] = callback;
    };

    const emit = (name) => {
        educationEvents.value[name]?.();
    };

    const clearEvents = () => {
        // eslint-disable-next-line guard-for-in
        for (const name in educationEvents.value) {
            educationEvents.value[name] = null;
        }
    };

    return {
        isShowEducation,
        shouldShowEducation,
        isFirstEnter,

        activeStep,
        previousStep,
        stepElement,

        showEducation,
        closeEducation,

        changeActiveStep,
        setStepPopoverElement,

        listen,
        emit,
        clearEvents,
    };
});
