import { makeHttpRequest } from "@/shared";

import { mapCalculatedSellingFromServer } from "./mappers/mapCalculatedSellingFromServer";
import { mapSellingProductToServer } from "./mappers/mapSellingProductToServer";

export const updateSellingProduct = async (sessionKey, product) => {
    const response = (
        await makeHttpRequest({
            url: `/stock/builder/${sessionKey}/update/product`,
            method: "PUT",
            data: mapSellingProductToServer(product),
        })
    ).data;

    return mapCalculatedSellingFromServer(response.data);
};
