/* eslint-disable node/no-unsupported-features/es-syntax */
import { TARIFF_ROUTE_NAMES } from "@/shared";
export const TARIFF_ROUTES = [
    {
        name: TARIFF_ROUTE_NAMES.PAY,
        path: "/my/tariff/pay",
        meta: {
            title: "Тарифы",
        },
        component: () => import("../ui/PaymentPage.vue"),
    },
];
