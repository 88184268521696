import {
    makeHttpRequest,
    mapDatabaseParamsFromServer,
    mapPaginationParamsToServer,
} from "@/shared";

import { mapEmployeePositionsFromServer } from "./mappers/mapEmployeePositionsFromServer";

export const getEmployeePositions = async (paginationParams) => {
    const response = (
        await makeHttpRequest({
            url: "/human/employee-position",
            method: "GET",
            params: mapPaginationParamsToServer(paginationParams),
        })
    ).data;

    return {
        positions: mapEmployeePositionsFromServer(response.data),
        ...mapDatabaseParamsFromServer(response),
    };
};
