import {
    EducationCongrats,
    EducationGreetings,
    EducationStep1,
    EducationStep10,
    EducationStep11,
    EducationStep12,
    EducationStep13,
    EducationStep14,
    EducationStep15,
    EducationStep16,
    EducationStep2,
    EducationStep3,
    EducationStep4,
    EducationStep5,
    EducationStep6,
    EducationStep7,
    EducationStep8,
    EducationStep9,
} from "@/entities";

export default [
    {
        element: null,
        component: EducationGreetings,
        arrow: false,
        side: "over",
        align: "center",
    },
    {
        element: ".app__authorized",
        component: EducationStep1,
        arrow: false,
        side: "self",
        align: "end",
        scroll: true,
        canHide: true,
    },
    {
        element: ".the-header__fast-create-btn",
        component: EducationStep2,
        arrow: true,
        side: "bottom",
        align: "end",
    },
    {
        element: ".sidebar-link",
        link: "/inventory",
        component: EducationStep3,
        arrow: true,
        side: "right",
        align: "start",
    },
    {
        element: ".inventory-list__add-inventory",
        component: EducationStep4,
        arrow: true,
        side: "bottom",
    },
    {
        element: ".app__authorized",
        component: EducationStep5,
        arrow: false,
        side: "self",
        align: "end",
        scroll: true,
        canHide: true,
    },
    {
        element: ".inventory-general__title",
        component: EducationStep6,
        arrow: true,
        scroll: true,
    },
    {
        element: ".inventory-general__status",
        component: EducationStep7,
        arrow: true,
        scroll: true,
    },
    {
        element: ".inventory-general__category",
        component: EducationStep8,
        arrow: true,
        scroll: true,
    },
    {
        element: ".inventory-price-field__button",
        component: EducationStep9,
        arrow: true,
        side: "top",
        scroll: true,
    },
    {
        element: ".inventory-price-field__modal",
        component: EducationStep10,
        side: "self",
        canHide: true,
    },
    {
        element: ".app__authorized",
        trackElement: ".action-buttons__save",
        component: EducationStep11,
        side: "self",
        scroll: true,
        canHide: true,
    },
    {
        element: ".sidebar-link",
        link: "/rent",
        component: EducationStep12,
        arrow: true,
        side: "right",
        align: "start",
    },
    {
        element: ".rent-list__add-rent",
        component: EducationStep13,
        arrow: true,
    },
    {
        element: "#app",
        component: EducationStep14,
        side: "self",
        scroll: true,
        canHide: true,
    },
    {
        element: "#app",
        trackElement: ".action-buttons__save",
        component: EducationStep15,
        side: "self",
        scroll: true,
        canHide: true,
    },
    {
        element: ".app__authorized",
        component: EducationStep16,
        side: "self",
        scroll: true,
        canHide: true,
    },
    {
        element: null,
        component: EducationCongrats,
        arrow: false,
        side: "over",
        align: "center",
    },
];
