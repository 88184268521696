import { defineStore } from "pinia";

import { useEntityStore, useSelectEntityStore } from "@/shared";

import { addExpenseType } from "../api/addExpenseType";
import { deleteExpenseType } from "../api/deleteExpenseType";
import { deleteExpenseTypes } from "../api/deleteExpenseTypes";
import { editExpenseType } from "../api/editExpenseType";
import { getExpenseType } from "../api/getExpenseType";
import { getExpenseTypes } from "../api/getExpenseTypes";

export const useExpenseTypesStore = defineStore("expenseTypes", () => {
    const {
        isManyLoading,
        isOneLoading,
        listData,
        singleData,
        meta,
        permissions,
        addOne,
        deleteOne,
        deleteMany,
        editOne,
        fetchMany,
        getOne,
        setManyLoading,
    } = useEntityStore({
        addEntity: addExpenseType,
        getEntity: getExpenseType,
        getEntities: getExpenseTypes,
        deleteEntity: deleteExpenseType,
        deleteEntities: deleteExpenseTypes,
        editEntity: editExpenseType,
    });

    const { selectMeta, selectData, isSelectLoading, fetchSelect } =
        useSelectEntityStore({
            getEntities: getExpenseTypes,
        });

    return {
        // entity
        listData,
        singleData,
        isManyLoading,
        isOneLoading,
        meta,
        permissions,
        fetchMany,
        deleteOne,
        deleteMany,
        getOne,
        editOne,
        addOne,
        setManyLoading,

        // select
        selectData,
        selectMeta,
        isSelectLoading,
        fetchSelect,
    };
});
