import { getDataMapper, mapArray, mapField } from "@/shared";

export const mapDiscountFromServer = getDataMapper({
    id: mapField("id", Number),
    title: mapField("title"),
    value: mapField("value", Number),
    type: mapField("discount_type", (obj) => obj.id),
    typeId: mapField("discount_type_id", Number),
    //isPromocode: mapField("only_promo", Boolean),
    isDiscount: mapField("is_discount", Boolean),
    limit: mapField("limits", Number),
    timeStart: mapField("time_start"),
    timeDuration: mapField("time_duration"),
    promocode: mapField("promocode"),
});
export const mapDiscountsFromServer = mapArray(mapDiscountFromServer);
