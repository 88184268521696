import { makeHttpRequest } from "@/shared";

import { mapInventoryFromServer } from "./mappers/mapInventoriesFromServer";
import { mapInventoryToServer } from "./mappers/mapInventoryToServer";

export const addInventory = async (inventory) => {
    const response = (
        await makeHttpRequest({
            url: "/inventory",
            method: "POST",
            data: mapInventoryToServer(inventory),
        })
    ).data;

    return { inventory: mapInventoryFromServer(response.data) };
};
