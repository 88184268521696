import { defineStore } from "pinia";

import { useEntityStore } from "@/shared";

import { getFreeKitsByRent } from "../api/getFreeKitsByRent";

export const useFreeKitsStore = defineStore("freeKits", () => {
    const {
        isManyLoading,
        listData,
        meta,
        permissions,
        fetchMany,
        setManyLoading,
    } = useEntityStore({
        getEntities: getFreeKitsByRent,
    });

    return {
        listData,
        isManyLoading,
        meta,
        permissions,
        fetchMany,
        setManyLoading,
    };
});
