import { getDataMapper, mapField } from "@/shared";

export const mapFDocCredsToServer = getDataMapper(
    {
        login: mapField("login", String),
        password: mapField("password", String),
        api_key: mapField("apiKey", String),
    },
    {
        shouldAvoidUndefined: true,
        shouldAvoidNull: true,
    }
);
