/* eslint-disable node/no-unsupported-features/es-syntax */
import { CLIENT_ROUTE_NAMES } from "@/shared";
export const CLIENT_ROUTES = [
    {
        name: CLIENT_ROUTE_NAMES.LIST,
        path: "/client",
        meta: {
            title: "Клиенты",
        },
        component: () => import("../ui/ClientPage.vue"),
        props: (route) => ({
            ...route.params,
            ...route.query,
        }),
    },
    {
        name: CLIENT_ROUTE_NAMES.PROFILE,
        path: "/client/:clientId",
        meta: {
            title: "Профиль клиента",
        },
        component: () => import("../ui/ClientProfile.vue"),
    },
    {
        name: CLIENT_ROUTE_NAMES.ADD,
        path: "/client/add",
        meta: {
            title: "Добавление клиента",
        },
        component: () => import("../ui/ClientEdit.vue"),
        props: (route) => ({
            ...route.params,
            ...route.query,
        }),
    },
    {
        name: CLIENT_ROUTE_NAMES.EDIT,
        path: "/client/edit/:clientId",
        meta: {
            title: "Редактирование клиента",
        },
        component: () => import("../ui/ClientEdit.vue"),
        props: true,
    },
];
