/* eslint-disable node/no-unsupported-features/es-syntax */
import { AUTHENTICATION_ROUTE_NAMES } from "@/shared";
export const AUTHENTICATION_ROUTES = [
    {
        name: AUTHENTICATION_ROUTE_NAMES.PAGE,
        path: "/auth",
        component: () => import("../ui/AuthenticationPage.vue"),
        children: [
            {
                name: AUTHENTICATION_ROUTE_NAMES.LOGIN,
                path: "login",
                meta: {
                    title: "Авторизация",
                },
                component: () => import("../ui/LoginPage.vue"),
            },
            {
                name: AUTHENTICATION_ROUTE_NAMES.REGISTRATION,
                path: "registration",
                meta: {
                    title: "Регистрация",
                },
                component: () => import("../ui/RegistrationPage.vue"),
            },
            {
                name: AUTHENTICATION_ROUTE_NAMES.WAIT_MAIL,
                path: "registration/confirm",
                meta: {
                    title: "Подтверждение почты",
                },
                component: () => import("../ui/MailConfirmPage.vue"),
            },
            {
                name: AUTHENTICATION_ROUTE_NAMES.CONFIRM_REGISTRATION,
                path: "contact/check/:guid",
                meta: {
                    title: "Проверка почты",
                },
                props: true,
                component: () => import("../ui/ConfirmRegistration.vue"),
            },
            {
                name: AUTHENTICATION_ROUTE_NAMES.RESTORE,
                path: "restore",
                meta: {
                    title: "Восстановление пароля",
                },
                component: () => import("../ui/RestorePassword.vue"),
                children: [
                    {
                        name: AUTHENTICATION_ROUTE_NAMES.RESTORE_ENTER_MAIL,
                        path: "",
                        component: () => import("../ui/RestoreEnterMail.vue"),
                    },
                    // {
                    //     name: AUTHENTICATION_ROUTE_NAMES.RESTORE_CONFIRM_MAIL,
                    //     path: ":guid",
                    //     props: true,
                    //     component: () => import("../ui/RestoreConfirmMail.vue"),
                    // },
                    {
                        name: AUTHENTICATION_ROUTE_NAMES.RESTORE_CHANGE_PASSWORD,
                        path: ":guid",
                        props: true,
                        component: () =>
                            import("../ui/RestoreChangePassword.vue"),
                    },
                ],
            },
        ],
    },
];
