import { makeHttpRequest } from "@/shared";

import { mapServiceFromServer } from "./mappers/mapServicesFromServer";

export const getService = async (id) => {
    const response = (
        await makeHttpRequest({
            url: `/service/${id}`,
            method: "GET",
        })
    ).data;

    return mapServiceFromServer(response.data);
};
