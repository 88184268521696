import { defineStore } from "pinia";

import { useEntityStore, useSelectEntityStore } from "@/shared";

import { addAdditionalService } from "../api/addAdditionalService";
import { deleteAdditionalService } from "../api/deleteAdditionalService";
import { deleteAdditionalServices } from "../api/deleteAdditionalServices";
import { editAdditionalService } from "../api/editAdditionalService";
import { getAdditionalService } from "../api/getAdditionalService";
import { getAdditionalServices } from "../api/getAdditionalServices";

export const useAdditionalServicesStore = defineStore(
    "additionalServices",
    () => {
        const {
            isManyLoading,
            isOneLoading,
            listData,
            singleData,
            meta,
            permissions,
            addOne,
            deleteOne,
            deleteMany,
            editOne,
            fetchMany,
            getOne,
            setManyLoading,
        } = useEntityStore({
            addEntity: addAdditionalService,
            getEntity: getAdditionalService,
            getEntities: getAdditionalServices,
            deleteEntity: deleteAdditionalService,
            deleteEntities: deleteAdditionalServices,
            editEntity: editAdditionalService,
        });

        const {
            selectData,
            isSelectLoading,
            selectMeta,
            selectPrevPaginationParams,
            fetchSelect,
        } = useSelectEntityStore({
            getEntities: getAdditionalServices,
        });

        return {
            // entity
            listData,
            singleData,
            isManyLoading,
            isOneLoading,
            meta,
            permissions,
            fetchMany,
            deleteOne,
            deleteMany,
            getOne,
            editOne,
            addOne,
            setManyLoading,

            // select
            selectData,
            isSelectLoading,
            selectMeta,
            selectPrevPaginationParams,
            fetchSelect,
        };
    }
);
