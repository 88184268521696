import {
    getDataMapper,
    mapField,
    mapArray,
    mapPointFromServer,
    parseISO,
} from "@/shared";

import { mapDiscountFromServer } from "@/entities/discounts/@x";

export const mapServiceFromServer = getDataMapper({
    id: mapField("id", Number),
    title: mapField("title", String),
    price: mapField("price", Number),
    point: mapField("point", mapPointFromServer),
    pointId: mapField("point", (point) => {
        return point?.id ? Number(point.id) : null;
    }),
    discounts: mapField("discounts", mapDiscountFromServer),
    updatedAt: mapField("updated_at", parseISO),
});

export const mapServicesFromServer = mapArray(mapServiceFromServer);
