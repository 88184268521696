import { defineStore } from "pinia";

import { useEntityStore, useSelectEntityStore } from "@/shared";

import { addDiscount } from "../api/addDiscount";
import { deleteDiscount } from "../api/deleteDiscount";
import { deleteDiscounts } from "../api/deleteDiscounts";
import { editDiscount } from "../api/editDiscount";
import { getDiscount } from "../api/getDiscount";
import { getDiscounts } from "../api/getDiscounts";

export const useDiscountsStore = defineStore("discounts", () => {
    const {
        isManyLoading,
        isOneLoading,
        listData,
        singleData,
        meta,
        permissions,
        addOne,
        deleteOne,
        deleteMany,
        editOne,
        fetchMany,
        getOne,
        setManyLoading,
    } = useEntityStore({
        addEntity: addDiscount,
        getEntity: getDiscount,
        getEntities: getDiscounts,
        deleteEntity: deleteDiscount,
        deleteEntities: deleteDiscounts,
        editEntity: editDiscount,
    });

    const { selectData, selectMeta, isSelectLoading, fetchSelect } =
        useSelectEntityStore({
            getEntities: getDiscounts,
        });

    return {
        // entity
        listData,
        singleData,
        isManyLoading,
        isOneLoading,
        meta,
        permissions,
        fetchMany,
        deleteOne,
        deleteMany,
        getOne,
        editOne,
        addOne,
        setManyLoading,

        // select
        selectData,
        selectMeta,
        isSelectLoading,
        fetchSelect,
    };
});
