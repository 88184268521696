import { makeHttpRequest } from "@/shared";

export const saveRepair = async (sessionKey) => {
    const response = (
        await makeHttpRequest({
            url: `/repair/builder/${sessionKey}/save`,
            method: "POST",
        })
    ).data;

    return response;
};
