import { getDataMapper, mapField, mapArray } from "@/shared";

export const mapInventoryStatusToServer = getDataMapper(
    {
        id: mapField("id", Number),
        title: mapField("title"),
        color: mapField("color"),
        text_color: mapField("textColor", () => "#000", { applyToNil: true }),
    },
    {
        shouldAvoidUndefined: true,
    }
);

export const mapInventoryStatusesToServer = mapArray(
    mapInventoryStatusToServer
);
