import { defineStore } from "pinia";

import { useEntityStore } from "@/shared";

import { closeRents as apiCloseRents } from "../api/closeRents";
import { deleteRent } from "../api/deleteRent";
import { deleteRents } from "../api/deleteRents";
import { getRents } from "../api/getRents";

export const useRentsStore = defineStore("rents", () => {
    const {
        isManyLoading,
        listData,
        meta,
        permissions,
        fetchMany,
        deleteOne,
        deleteMany,
        setManyLoading,
    } = useEntityStore({
        getEntities: getRents,
        deleteEntity: deleteRent,
        deleteEntities: deleteRents,
    });

    async function closeRents(rents) {
        await apiCloseRents(rents);
    }

    return {
        listData,
        isManyLoading,
        meta,
        permissions,
        fetchMany,
        deleteOne,
        deleteMany,
        setManyLoading,
        closeRents,
    };
});
