/* eslint-disable node/no-unsupported-features/es-syntax */
import { PROFILE_ROUTE_NAMES } from "@/shared";
export const PROFILE_ROUTES = [
    {
        name: PROFILE_ROUTE_NAMES.PROFILE,
        path: "/my/profile",
        meta: {
            title: "Профиль",
        },
        component: () => import("../ui/ProfilePage.vue"),
    },
    {
        name: PROFILE_ROUTE_NAMES.EDIT,
        path: "/my/profile/edit",
        meta: {
            title: "Редактирование профиля",
        },
        component: () => import("../ui/ProfileEdit.vue"),
    },
];
