import { defineStore } from "pinia";

import { useEntityStore, useSelectEntityStore } from "@/shared";

import { addClient } from "../api/addClient";
import { deleteClient } from "../api/deleteClient";
import { deleteClients } from "../api/deleteClients";
import { editClient } from "../api/editClient";
import { getClient } from "../api/getClient";
import { getClients } from "../api/getClients";

export const useClientsStore = defineStore("clients", () => {
    const {
        isManyLoading,
        isOneLoading,
        listData,
        singleData,
        meta,
        permissions,
        addOne,
        deleteOne,
        deleteMany,
        editOne,
        fetchMany,
        getOne,
        setManyLoading,
    } = useEntityStore({
        addEntity: addClient,
        getEntity: getClient,
        getEntities: getClients,
        deleteEntity: deleteClient,
        deleteEntities: deleteClients,
        editEntity: editClient,
    });

    const {
        selectMeta,
        selectData,
        isSelectLoading,
        selectPrevPaginationParams,
        fetchSelect,
    } = useSelectEntityStore({
        getEntities: getClients,
    });

    return {
        // entity
        listData,
        singleData,
        isManyLoading,
        isOneLoading,
        meta,
        permissions,
        fetchMany,
        deleteOne,
        deleteMany,
        getOne,
        editOne,
        addOne,
        setManyLoading,

        // select
        selectData,
        selectMeta,
        isSelectLoading,
        selectPrevPaginationParams,
        fetchSelect,
    };
});
