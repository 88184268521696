import {
    makeHttpRequest,
    mapPaginationParamsToServer,
    mapDatabaseParamsFromServer,
} from "@/shared";

import { mapKitsFromServer } from "./mappers/mapKitsFromServer";

export const getKits = async (paginationParams) => {
    const response = (
        await makeHttpRequest({
            url: "/kit",
            method: "GET",
            params: mapPaginationParamsToServer(paginationParams),
        })
    ).data;

    return {
        data: mapKitsFromServer(response.data),
        ...mapDatabaseParamsFromServer(response),
    };
};
