import { getDataMapper, mapField, mapArray } from "../../../lib";
export const mapPointToServer = getDataMapper({
    id: mapField("id", Number),
    place_id: mapField("placeId", Number),
    title: mapField("title"),
    email: mapField("email"),
    website: mapField("website"),
    phone: mapField("phone"),
    place_text: mapField("placeText"),
});

export const mapPointsToServer = mapArray(mapPointToServer);
