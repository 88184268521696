import { getDataMapper, mapField } from "@/shared";

import {
    mapInventoryState,
    mapIncomeRentState,
} from "./mapDashboardMainFromServer";
import {
    mapTopInventory,
    mapCategoriesIncome,
} from "./mapDashboardStartBusinessFromServer";

export const mapDashboardRentFromServer = getDataMapper({
    income: mapField("income", {
        sumTotal: mapField("sum_total", Number),
        sumCard: mapField("sum_card", Number),
        sumCash: mapField("sum_cash", Number),
    }),
    middleReceipt: mapField("middle_receipt", Number),
    countContracts: mapField("count_contracts", Number),
    countInventories: mapField("count_inventories", Number),
    graph: mapField("graph"),
    topInventory: mapField("top_inventory", mapTopInventory),
    inventoryStates: mapField("inventory_states", {
        states: mapField("states", mapInventoryState),
        noPointsCount: mapField("no_points_count", Number),
    }),
    incomeRentStates: mapField("income_rent_states", mapIncomeRentState),
    categoriesIncome: mapField("categories_income", mapCategoriesIncome),
});
