import { getDataMapper, mapField, mapArray } from "@/shared";

export const mapAdditionalServiceFromServer = getDataMapper({
    id: mapField("id", Number),
    title: mapField("title"),
    price: mapField("price", Number),
});

export const mapAdditionalServicesFromServer = mapArray(
    mapAdditionalServiceFromServer
);
