import {
    getDataMapper,
    mapArray,
    mapField,
    mapPointFromServer,
    mapCategoryFromServer,
    mapMediaFromServer,
    parseISO,
} from "@/shared";

import { mapDiscountFromServer } from "@/entities/discounts/@x";

export const mapProductFromServer = getDataMapper({
    articleNumber: mapField("artule"),
    avatar: mapField("avatar"),
    buyDate: mapField("buy_date", String),
    buyPrice: mapField("buy_price", Number),
    category: mapField("category", mapCategoryFromServer),
    discounts: mapField("discounts", mapDiscountFromServer),
    count: mapField("count", Number),
    description: mapField("description"),
    id: mapField("id", Number),
    media: mapField("media", mapMediaFromServer),
    point: mapField("point", mapPointFromServer),
    pointId: mapField("point", (point) => {
        return point?.id ? Number(point.id) : null;
    }),
    price: mapField("price", Number),
    title: mapField("title"),
    updatedAt: mapField("updated_at", parseISO),
});

export const mapProductsFromServer = mapArray(mapProductFromServer);
