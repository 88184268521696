<script setup>
import { computed } from "vue";

import importedProps from "./config/props";

const props = defineProps({ modelValue: null, ...importedProps });
const emit = defineEmits(["update:modelValue", "blur", "change"]);

const date = computed({
    get() {
        return props.modelValue;
    },

    set(value) {
        emit("update:modelValue", value);
    },
});

const totalInputClasses = computed(() => {
    if (props.isError) {
        return {
            "base-input__input--error": props.isError,
            ...props.inputClass,
        };
    }

    return props.inputClass;
});
</script>

<template>
    <div class="base-input" :class="`base-input--${props.size}`">
        <input
            v-bind="$attrs"
            class="base-input__input"
            :class="totalInputClasses"
            :type="type"
            :id="id"
            :name="name"
            :placeholder="placeholder"
            v-model="date"
            :autocomplete="autocomplete"
            :disabled="disabled"
            @blur="(e) => $emit('blur', e)"
            @change="(e) => $emit('change', e)"
        />
    </div>
</template>

<style lang="scss" scoped>
.base-input {
    display: flex;

    flex-direction: column;

    width: 100%;

    line-height: var(--default-line-height);

    color: var(--input-text-color);

    &__input {
        height: var(--base-input-height);
        padding: var(--input-padding-y) var(--input-padding-x);

        font-size: var(--base-input-text-size);

        background: var(--input-background);

        border: var(--input-border);
        border-radius: var(--base-input-border-radius);

        transition: var(--input-transition);

        &:focus {
            outline: var(--input-outline);
        }

        &--error {
            border-color: var(--input-color-error);

            &:focus {
                outline: var(--input-outline-error);
            }
        }

        &::placeholder {
            color: var(--input-placeholder-color);
        }

        &:disabled {
            cursor: not-allowed;

            background-color: var(--c-neutral-20);
        }
    }

    &--sm {
        --base-input-height: calc(var(--input-height) - 12px);
        --base-input-text-size: var(--input-text-size);
        --base-input-border-radius: var(--input-border-radius);
    }

    &--md {
        --base-input-height: var(--input-height);
        --base-input-text-size: var(--input-text-size);
        --base-input-border-radius: var(--input-border-radius);
    }

    &--lg {
        --base-input-height: calc(var(--input-height) + 10px);
        --base-input-text-size: calc(var(--input-text-size) + 4px);
        --base-input-border-radius: calc(var(--input-border-radius) + 10px);
    }
}
</style>
