import {
    makeHttpRequest,
    mapDatabaseParamsFromServer,
    mapPaginationParamsToServer,
} from "@/shared";

import { mapEmployeesFromServer } from "./mappers/mapEmployeesFromServer";

export const getEmployeesWithDirector = async (paginationParams) => {
    const response = (
        await makeHttpRequest({
            url: "/human/director-and-employees",
            method: "GET",
            params: mapPaginationParamsToServer(paginationParams),
        })
    ).data;

    return {
        data: mapEmployeesFromServer(response.data),
        ...mapDatabaseParamsFromServer(response),
    };
};
