import { makeHttpRequest } from "@/shared";

import { mapRefusalTypeToServer } from "./mappers/mapRefusalTypeToServer";

export const editRefusalType = async (id, sourceAttraction) => {
    const response = (
        await makeHttpRequest({
            url: `/delivery/refusal-type/${id}`,
            method: "PUT",
            data: mapRefusalTypeToServer(sourceAttraction),
        })
    ).data;

    return response;
};
