import { getDataMapper, mapField, mapArray } from "../../../lib";

export const mapShiftFromServer = getDataMapper({
    id: mapField("id", Number),
    countRent: mapField("count_rent", Number),
    countRepair: mapField("count_repair", Number),
    countStock: mapField("count_stock", Number),
    period: mapField("period"),
    humanId: mapField("human_id", Number),
    pointId: mapField("point_id", Number),
    sumExpense: mapField("sum_expense", Number),
    sumRent: mapField("sum_rent", Number),
    sumRepair: mapField("sum_repair", Number),
    sumStock: mapField("sum_stock", Number),
    sumTotal: mapField("sum_total", Number),
    timeEnd: mapField("time_end"),
    timeStart: mapField("time_start"),
});

export const mapShiftsFromServer = mapArray(mapShiftFromServer);
