import { getDataMapper, mapField, parseISO } from "@/shared";

export const mapDeliveryToServer = getDataMapper({
    id: mapField("id"),
    updatedAt: mapField("updated_at", parseISO),
    title: mapField("title"),
    stateId: mapField("state_id", Number),
    status: mapField("state", {
        title: mapField("title"),
        color: mapField("color"),
        const: mapField("const"),
    }),
});
