import { mapField, mapArray, getDataMapper } from "@/shared";

export const mapProductLeftoverFromServer = getDataMapper({
    categoryTitle: mapField("category_title", String),
    count: mapField("count", Number),
    leftover: mapField("leftover", Number),
    productTitle: mapField("product_title", String),
    pointTitle: mapField("point_title", String),
});

export const mapProductsLeftoverFromServer = mapArray(
    mapProductLeftoverFromServer
);
