import { makeHttpRequest } from "@/shared";

import { mapInventoryStatusToServer } from "./mappers/mapInventoryStatusToServer";

export const editInventoryStatus = async (id, inventoryStatus) => {
    const response = (
        await makeHttpRequest({
            url: `/inventory/state/${id}`,
            method: "PUT",
            data: mapInventoryStatusToServer(inventoryStatus),
        })
    ).data;

    return response;
};
