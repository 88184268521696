import { makeHttpRequest } from "@/shared";

import { mapContractFromServer } from "./mappers/mapContractsFromServer";

export const getContract = async (id) => {
    const response = (
        await makeHttpRequest({
            url: `order/${id}`,
            method: "GET",
        })
    ).data;

    return mapContractFromServer(response.data);
};
