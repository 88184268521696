import { makeHttpRequest } from "@/shared";

export const saveRent = async (sessionKey) => {
    const response = (
        await makeHttpRequest({
            url: `/rent/builder/${sessionKey}/save`,
            method: "POST",
            data: {
                service_from: null,
            },
        })
    ).data;

    return response;
};
