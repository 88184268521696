import { makeHttpRequest } from "@/shared";

import { mapExpenseToServer } from "./mappers/mapExpenseToServer";

export const editExpense = async (id, expense) => {
    const response = (
        await makeHttpRequest({
            url: `/expense/${id}`,
            method: "PUT",
            data: mapExpenseToServer(expense),
        })
    ).data;

    return response;
};
