import { storeToRefs } from "pinia";

import { useOverlayStore } from "../model/useOverlayStore";

import { easeInOutQuad } from "./easeInOutQuad";

const generateStageSvgPathString = (stage) => {
    const { overlayConfig } = storeToRefs(useOverlayStore());
    const windowX = window.innerWidth;
    const windowY = window.innerHeight;

    const stagePadding = overlayConfig.value.stagePadding || 0;
    const stageRadius = overlayConfig.value.stageRadius || 0;

    const stageWidth = stage.width + stagePadding * 2;
    const stageHeight = stage.height + stagePadding * 2;

    const limitedRadius = Math.min(
        stageRadius,
        stageWidth / 2,
        stageHeight / 2
    );

    const normalizedRadius = Math.floor(Math.max(limitedRadius, 0));

    const highlightBoxX = stage.x - stagePadding + normalizedRadius;
    const highlightBoxY = stage.y - stagePadding;
    const highlightBoxWidth = stageWidth - normalizedRadius * 2;
    const highlightBoxHeight = stageHeight - normalizedRadius * 2;

    return `M${windowX},0L0,0L0,${windowY}L${windowX},${windowY}L${windowX},0Z
    M${highlightBoxX},${highlightBoxY} h${highlightBoxWidth} a${normalizedRadius},${normalizedRadius} 0 0 1 ${normalizedRadius},${normalizedRadius} v${highlightBoxHeight} a${normalizedRadius},${normalizedRadius} 0 0 1 -${normalizedRadius},${normalizedRadius} h-${highlightBoxWidth} a${normalizedRadius},${normalizedRadius} 0 0 1 -${normalizedRadius},-${normalizedRadius} v-${highlightBoxHeight} a${normalizedRadius},${normalizedRadius} 0 0 1 ${normalizedRadius},-${normalizedRadius} z`;
};

const createOverlaySvg = (stage) => {
    const { overlayConfig } = storeToRefs(useOverlayStore());
    const windowX = window.innerWidth;
    const windowY = window.innerHeight;

    const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");

    svg.classList.add("education-overlay", "education-overlay-animated");

    svg.setAttribute("viewBox", `0 0 ${windowX} ${windowY}`);
    svg.setAttribute("xmlSpace", "preserve");
    svg.setAttribute("xmlnsXlink", "http://www.w3.org/1999/xlink");
    svg.setAttribute("version", "1.1");
    svg.setAttribute("preserveAspectRatio", "xMinYMin slice");

    svg.style.fillRule = "evenodd";
    svg.style.clipRule = "evenodd";
    svg.style.strokeLinejoin = "round";
    svg.style.strokeMiterlimit = "2";
    svg.style.zIndex = "100000";
    svg.style.position = "fixed";
    svg.style.top = "0";
    svg.style.left = "0";
    svg.style.width = "100%";
    svg.style.height = "100%";

    const stagePath = document.createElementNS(
        "http://www.w3.org/2000/svg",
        "path"
    );

    stagePath.setAttribute("d", generateStageSvgPathString(stage));

    stagePath.style.fill = overlayConfig.value.overlayColor || "rgb(0,0,0)";
    stagePath.style.opacity = `${overlayConfig.value.overlayOpacity}`;
    stagePath.style.pointerEvents = "auto";
    stagePath.style.cursor = "auto";

    svg.appendChild(stagePath);

    return svg;
};

const mountOverlay = (stagePosition) => {
    const overlaySvg = createOverlaySvg(stagePosition);

    document.body.appendChild(overlaySvg);

    useOverlayStore().setOverlaySvg(overlaySvg);
};

const renderOverlay = (stagePosition) => {
    const { overlaySvg } = storeToRefs(useOverlayStore());

    if (!overlaySvg.value) {
        mountOverlay(stagePosition);

        return;
    }

    const pathElement = overlaySvg.value.firstElementChild;

    if (pathElement?.tagName !== "path") {
        throw new Error("no path element found in stage svg");
    }

    pathElement.setAttribute("d", generateStageSvgPathString(stagePosition));
};

export const transitionStage = (elapsed, duration, from, to) => {
    const { activeStagePosition } = storeToRefs(useOverlayStore());

    const fromDefinition = activeStagePosition.value
        ? activeStagePosition.value
        : from.getBoundingClientRect();
    const toDefinition = to.getBoundingClientRect();

    const x = easeInOutQuad(
        elapsed,
        fromDefinition.x,
        toDefinition.x - fromDefinition.x,
        duration
    );
    const y = easeInOutQuad(
        elapsed,
        fromDefinition.y,
        toDefinition.y - fromDefinition.y,
        duration
    );
    const width = easeInOutQuad(
        elapsed,
        fromDefinition.width,
        toDefinition.width - fromDefinition.width,
        duration
    );
    const height = easeInOutQuad(
        elapsed,
        fromDefinition.height,
        toDefinition.height - fromDefinition.height,
        duration
    );

    renderOverlay({ x, y, width, height });
    activeStagePosition.value = { x, y, width, height };
};

export const trackActiveElement = (element) => {
    if (!element) return;

    const definition = element.getBoundingClientRect();

    const activeStagePosition = {
        x: definition.x,
        y: definition.y,
        width: definition.width,
        height: definition.height,
    };

    useOverlayStore().setActiveStagePosition(activeStagePosition);

    renderOverlay(activeStagePosition);
};

export const refreshOverlay = () => {
    const { activeStagePosition, overlaySvg } = storeToRefs(useOverlayStore());

    if (!activeStagePosition.value) return;

    if (!overlaySvg.value) {
        console.warn("No stage svg found.");

        return;
    }

    const windowX = window.innerWidth;
    const windowY = window.innerHeight;

    overlaySvg.value.setAttribute("viewBox", `0 0 ${windowX} ${windowY}`);
};

export const destroyOverlay = () => {
    const { overlaySvg } = storeToRefs(useOverlayStore());

    if (overlaySvg.value) {
        overlaySvg.value.remove();
        overlaySvg.value = null;
    }
};
