import { defineStore } from "pinia";

import { useEntityStore } from "@/shared";

import { closeRepairs as apiCloseRepairs } from "../api/closeRepairs";
import { deleteRepair } from "../api/deleteRepair";
import { deleteRepairs } from "../api/deleteRepairs";
import { getRepairs } from "../api/getRepairs";

export const useRepairsStore = defineStore("repairs", () => {
    const {
        isManyLoading,
        listData,
        meta,
        permissions,
        fetchMany,
        deleteOne,
        deleteMany,
        setManyLoading,
    } = useEntityStore({
        getEntities: getRepairs,
        deleteEntity: deleteRepair,
        deleteEntities: deleteRepairs,
    });

    async function closeRepairs(repairs) {
        await apiCloseRepairs(repairs);
    }

    return {
        listData,
        isManyLoading,
        meta,
        permissions,
        fetchMany,
        deleteOne,
        deleteMany,
        setManyLoading,
        closeRepairs,
    };
});
