import { getDataMapper, mapArray, mapField } from "../../../lib";

export const mapMediaToServer = getDataMapper({
    dict_id: mapField("dictId", Number),
    media_id: mapField("mediaId", Number),
    order_id: mapField("orderId", Number),
    resource_id: mapField("resourceId", Number),
    src: mapField("src", String),
    external_src: mapField("externalSrc", String),
});

export const mapMediasToServer = mapArray(mapMediaToServer);
