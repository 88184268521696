import { makeHttpRequest } from "@/shared";

export const deleteAdditionalService = async (id) => {
    const response = (
        await makeHttpRequest({
            url: `/service/additional/${id}`,
            method: "DELETE",
        })
    ).data;

    return response;
};
