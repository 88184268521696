import { makeHttpRequest, mapEmployeeFromServer } from "@/shared";

export const getEmployee = async (employeeId) => {
    const response = (
        await makeHttpRequest({
            url: `/human/employee/${employeeId}`,
            method: "GET",
        })
    ).data;

    return mapEmployeeFromServer(response.data);
};
