import { makeHttpRequest } from "@/shared";

export const deleteRepairStatus = async (id) => {
    return (
        await makeHttpRequest({
            url: `/repair/state/${id}`,
            method: "DELETE",
        })
    ).data;
};
