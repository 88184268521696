import {
    getDataMapper,
    mapCompanyFromServer,
    mapContactFromServer,
    mapField,
    mapHumanFromServer,
    mapMediaFromServer,
    mapUserFromServer,
} from "@/shared";

export const mapDirectorFromServer = getDataMapper({
    id: mapField("id", Number),
    human: mapField("human", mapHumanFromServer),
    contacts: mapField("contacts", mapContactFromServer),
    user: mapField("user", mapUserFromServer),
    directorTariffId: mapField("director_tariff_id", Number),
    address: mapField("address", String),
    avatar: mapField("avatar", String),
    media: mapField("media", mapMediaFromServer),
    autopayment: mapField("autopayment", Boolean),
    hasPaymentMethod: mapField("has_payment_method", Boolean),
    company: mapField("company", mapCompanyFromServer),
});
