import { makeHttpRequest } from "@/shared";

import { mapBreakingFromServer } from "./mappers/mapBreakingsFromServer";
export const getBreaking = async (id) => {
    const response = (
        await makeHttpRequest({
            url: `/broken/${id}`,
            method: "GET",
        })
    ).data;

    return mapBreakingFromServer(response.data);
};
