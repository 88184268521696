import {
    getDataMapper,
    mapField,
    composeFields,
    mapMediaToServer,
    formatISO,
} from "@/shared";

import { mapInventoryStatusToServer } from "@/entities/inventory-statuses/@x";

const mapInventoryChildToServer = getDataMapper({
    state: mapField("state", mapInventoryStatusToServer),
    state_id: mapField("stateId", Number),
    count: mapField("count", Number),
});

const mapInventoryChildrenToServer = function (children) {
    const result = [];
    const map = {};

    children.forEach((item) => {
        if (map[item.stateId]) {
            map[item.stateId].count++;
        } else {
            map[item.stateId] = { ...item, count: 1 };
        }
    });

    for (const key in map) {
        if (map[key]) {
            result.push(mapInventoryChildToServer(map[key]));
        }
    }

    return result;
};

export const mapInventoryToServer = getDataMapper(
    {
        id: mapField("id"),
        title: mapField("title"),
        description: mapField("description"),
        state_id: mapField("statusId", Number),
        point_id: mapField("pointId", Number),
        article_number: mapField("articleNumber"),
        category_resource_id: mapField("categoryId", Number),
        rent_number: mapField("rentNumber", String),
        children_count: composeFields(
            ["childrenCount", "isGroup"],
            ([childrenCount, isGroup]) => {
                return isGroup ? Number(childrenCount) : undefined;
            }
        ),
        children: composeFields(
            ["children", "isGroup"],
            ([children, isGroup]) => {
                return isGroup ? mapInventoryChildrenToServer(children) : null;
            }
        ),
        cash_deposit: mapField("cashDeposit"),
        prices: mapField("prices", (item) => item.id),
        is_group: mapField("isGroup", Boolean),
        discounts: mapField("discounts", (item) => item.id),
        option: mapField("option"),
        media: mapField("media", mapMediaToServer),
        buy_price: mapField("buyPrice", Number),
        buy_date: mapField("buyDate", formatISO),
    },
    {
        shouldAvoidUndefined: true,
    }
);
