import { getDataMapper, mapField } from "@/shared";

export const mapAdditionalServiceToServer = getDataMapper(
    {
        id: mapField("id", Number),
        title: mapField("title"),
        price: mapField("price", Number),
    },
    {
        shouldAvoidUndefined: true,
    }
);
