import { makeHttpRequest } from "@/shared";

import { mapProductFromServer } from "./mappers/mapProductsFromServer";

export const getProduct = async (id) => {
    const response = (
        await makeHttpRequest({
            url: `/product/${id}`,
            method: "GET",
        })
    ).data;

    return mapProductFromServer(response.data);
};
