/* eslint-disable node/no-unsupported-features/es-syntax */
import { RENT_ROUTE_NAMES } from "@/shared";
export const RENT_ROUTES = [
    {
        name: RENT_ROUTE_NAMES.LIST,
        path: "/rent",
        meta: {
            title: "Аренды",
        },
        props: (route) => ({
            ...route.params,
            ...route.query,
        }),
        component: () => import("../ui/RentList.vue"),
    },
    {
        name: RENT_ROUTE_NAMES.ADD,
        path: "/rent/add",
        meta: {
            title: "Добавление аренды",
        },
        component: () => import("../ui/RentEdit.vue"),
    },
    {
        name: RENT_ROUTE_NAMES.EDIT,
        path: "/rent/edit/:id",
        meta: {
            title: "Редактирование аренды",
        },
        props: true,
        component: () => import("../ui/RentEdit.vue"),
    },
];
