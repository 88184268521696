import { getDataMapper, mapField } from "@/shared";

export const mapExpenseTypeToServer = getDataMapper(
    {
        id: mapField("id", Number),
        title: mapField("title"),
        const: mapField("const", () => "EXPENSE_TYPE", { applyToNil: true }), // const required to be not nil
    },
    {
        shouldAvoidUndefined: true,
    }
);
