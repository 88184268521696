import { getDataMapper, mapField } from "@/shared";

export const mapCompanyFromServer = getDataMapper({
    id: mapField("id", Number),
    title: mapField("title"),
    email: mapField("email"),
    website: mapField("website"),
    mobile: mapField("mobile"),
    placeId: mapField("place_id", Number),
    groupOfUserId: mapField("group_of_user_id", Number),
    bic: mapField("bic"),
    ogrn: mapField("ogrn"),
    inn: mapField("inn"),
    kpp: mapField("kpp"),
    checkingAccount: mapField("checking_account"),
    correspondentAccount: mapField("correspondent_account"),
    bankName: mapField("bank_name"),
    address: mapField("address"),
});
