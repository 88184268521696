import { defineStore } from "pinia";

import { useEntityStore, useSelectEntityStore } from "@/shared";

import { addDeposit } from "../api/addDeposit";
import { deleteDeposit } from "../api/deleteDeposit";
import { deleteDeposits } from "../api/deleteDeposits";
import { editDeposit } from "../api/editDeposit";
import { getDeposit } from "../api/getDeposit";
import { getDeposits } from "../api/getDeposits";

export const useDepositsStore = defineStore("deposits", () => {
    const {
        isManyLoading,
        isOneLoading,
        listData,
        singleData,
        meta,
        permissions,
        addOne,
        deleteOne,
        deleteMany,
        editOne,
        fetchMany,
        getOne,
        setManyLoading,
    } = useEntityStore({
        addEntity: addDeposit,
        getEntity: getDeposit,
        getEntities: getDeposits,
        deleteEntity: deleteDeposit,
        deleteEntities: deleteDeposits,
        editEntity: editDeposit,
    });

    const { selectData, selectMeta, isSelectLoading, fetchSelect } =
        useSelectEntityStore({
            getEntities: getDeposits,
        });

    return {
        // entity
        listData,
        singleData,
        isManyLoading,
        isOneLoading,
        meta,
        permissions,
        fetchMany,
        deleteOne,
        deleteMany,
        getOne,
        editOne,
        addOne,
        setManyLoading,

        // select
        selectData,
        selectMeta,
        isSelectLoading,
        fetchSelect,
    };
});
