import { makeHttpRequest } from "@/shared";

import { mapCalculatedSellingFromServer } from "./mappers/mapCalculatedSellingFromServer";
import { mapSellingAdditionalServiceToServer } from "./mappers/mapSellingAdditionalServiceToServer";

export const addAdditionalServiceToSelling = async (sessionKey, service) => {
    const response = (
        await makeHttpRequest({
            url: `/stock/builder/${sessionKey}/add/service`,
            method: "PUT",
            data: mapSellingAdditionalServiceToServer(service),
        })
    ).data;

    return mapCalculatedSellingFromServer(response.data);
};
