import { getDataMapper, mapField } from "@/shared";

export const mapRegstrationDataToServer = getDataMapper({
    email: mapField("email"),
    password: mapField("password"),
    password_confirmation: mapField("confirmPassword"),
    name: mapField("name"),
    surname: mapField("surname"),
    phone: mapField("phone"),
});
