import { makeHttpRequest } from "@/shared";

import { mapDiscountToServer } from "./mappers/mapDiscountToServer";

export const editDiscount = async (id, discount) => {
    return (
        await makeHttpRequest({
            url: `/discount/${id}`,
            method: "PUT",
            data: mapDiscountToServer(discount),
        })
    ).data;
};
