import {
    getDataMapper,
    mapField,
    parseISO,
    mapHumanFromServer,
} from "@/shared";

import { mapAdditionalServiceFromServer } from "@/entities/additional-services/@x";
import { mapBreakingFromServer } from "@/entities/breakings/@x";
import { mapClientFromServer } from "@/entities/client/@x";
import { mapDepositFromServer } from "@/entities/deposits/@x";
import { mapDiscountFromServer } from "@/entities/discounts/@x";
import { mapInventoryFromServer } from "@/entities/inventory/@x";
import { mapKitFromServer } from "@/entities/kit/@x";
import { mapPointFromServer } from "@/entities/points/@x";
import { mapProductFromServer } from "@/entities/products/@x";

export const mapCalculatedRentFromServer = getDataMapper({
    id: mapField("id", Number),
    number: mapField("order_number", Number),
    timeStart: mapField("time_start", parseISO),
    timeEnd: mapField("time_end", parseISO),
    timeClose: mapField("time_fact_end", parseISO),
    sum: mapField("sum", Number),
    totalSum: mapField("sum_real", Number),
    inventorySum: mapField("sum_inventory", Number),
    discountSum: mapField("sum_discount", Number),
    paid: mapField("payed", Number),
    additionalServiceSum: mapField("sum_additional_service", Number),
    productSum: mapField("sum_product", Number),
    additionalPayment: mapField(null, () => 0, { applyToNil: true }),
    additionalPaymentType: mapField(null, () => 1, { applyToNil: true }),
    client: mapField("human", mapClientFromServer),
    clientId: mapField("human", (client) => client?.id),
    adminUser: mapField("admin_user", mapHumanFromServer),
    openPoint: mapField("open_point", mapPointFromServer),
    closePoint: mapField("close_point", mapPointFromServer),
    comment: mapField("comment"),
    status: mapField("rent_state", {
        const: mapField("const"),
    }),
    inventories: mapField("inventories", {
        breakings: mapField("brokens", {
            breaking: mapField("broken", mapBreakingFromServer),
        }),
        rentInventory: mapField("resource_inventory", {
            inventory: mapField("inventory", mapInventoryFromServer),
            count: mapField("count", Number, { applyToNil: true }),
            max: mapField("max", Number, { applyToNil: true }),
            price: mapField("price", {
                id: mapField("id", Number),
                title: mapField("title"),
            }),
            isFinished: mapField("finished", Boolean),
            timeStart: mapField("time_start", parseISO),
            timeEnd: mapField("time_end", parseISO),
            isFree: mapField("is_free", Boolean),
            unionKits: mapField("union_kits", Number),
            children: mapField("children", {
                isFinished: mapField("finished", Boolean),
                timeEnd: mapField("time_end", parseISO),
                status: mapField("state", {
                    title: mapField("title"),
                    color: mapField("color"),
                    const: mapField("const"),
                }),
            }),
        }),
    }),
    kits: mapField("kits", {
        id: mapField("id", Number),
        kit: mapField("kit", mapKitFromServer),
        price: mapField("price", {
            id: mapField("id", Number),
            title: mapField("title"),
        }),
    }),
    products: mapField("products", {
        product: mapField("product", mapProductFromServer),
        count: mapField("count", Number),
        max: mapField("max", Number),
    }),
    additionalServices: mapField("additional_services", {
        additionalService: mapField(
            "additional_service",
            mapAdditionalServiceFromServer
        ),
        count: mapField("count", Number),
    }),
    payments: mapField("payments", {
        id: mapField("id", Number),
        date: mapField("date", parseISO),
        value: mapField("value", Number),
        payType: mapField("pay_type", {
            title: mapField("title"),
            const: mapField("const"),
        }),
    }),
    discounts: mapField("discounts", {
        id: mapField("id", Number),
        discount: mapField("discount", mapDiscountFromServer),
        sum: mapField("sum", Number),
        temporaryKey: mapField("temporary_key"),
    }),
    deposit: mapField("deposit", {
        depositInstance: mapField("deposit", mapDepositFromServer),
        sum: mapField("sum", Number),
        isAccepted: mapField("is_accepted", Boolean),
        isReturned: mapField("is_returned", Boolean),
    }),
    shouldCalculateBreaking: mapField("need_calc_breaking", Boolean),
    shouldCalculateRentDelay: mapField("need_calc_rent_delay", Boolean),
    check: mapField("check"),
    fDocDocuments: mapField("fdoc_documents", {
        id: mapField("id"),
        link: mapField("reference"),
        status: mapField("status"),
        title: mapField("name"),
    }),
});
