import { defineStore } from "pinia";
import { ref } from "vue";

import { cloneDeep } from "@/shared";

import { getRights } from "../api/getRights.js";

import { DIRECTOR_RIGHTS } from "./directorRights.js";

export const useUserStore = defineStore("userStore", () => {
    const humanId = ref(null);
    const profileId = ref(null);
    const isDirector = ref(false);
    const userId = ref(null);
    const userLogin = ref(null);
    const rights = ref({});
    const employeePoint = ref(null);

    function setProfileId(user) {
        profileId.value = user;
    }

    function setHumanId(id) {
        humanId.value = id;
    }

    function setIsDirector(user) {
        isDirector.value = user;
    }

    const setUserCredentials = ({ id, login }) => {
        userId.value = id;
        userLogin.value = login;
    };

    const setDirectorRights = function () {
        rights.value = cloneDeep(DIRECTOR_RIGHTS);
    };

    const loadRights = async function () {
        if (isDirector.value) {
            setDirectorRights();

            return;
        }

        rights.value = await getRights(profileId.value);
    };

    const setEmployeePoint = function (point) {
        employeePoint.value = point;
    };

    return {
        profileId,
        humanId,
        isDirector,
        userId,
        userLogin,
        rights,
        employeePoint,

        setProfileId,
        setHumanId,
        setIsDirector,
        setUserCredentials,
        setDirectorRights,
        setEmployeePoint,

        loadRights,
    };
});
