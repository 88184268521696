import { makeHttpRequest } from "@/shared";

import { mapCalculatedRentFromServer } from "./mappers/mapCalculatedRentFromServer";
export const removeClientFromRent = async (sessionKey) => {
    const response = (
        await makeHttpRequest({
            url: `/rent/builder/${sessionKey}/remove/client`,
            method: "DELETE",
        })
    ).data;

    return mapCalculatedRentFromServer(response.data);
};
