import { makeHttpRequest } from "@/shared";

import { mapClientFromServer } from "./mappers/from/mapClientFromServer";
import { mapClientToServer } from "./mappers/to/mapClientToServer";

export const editClient = async (clientId, client) => {
    const response = (
        await makeHttpRequest({
            url: `/human/client/${clientId}`,
            method: "PUT",
            data: mapClientToServer(client),
        })
    ).data;

    return { client: mapClientFromServer(response.data) };
};
