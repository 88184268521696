import { mapField, mapArray, getDataMapper } from "@/shared";

export const mapProductIncomeFromServer = getDataMapper({
    categoryTitle: mapField("category_title", String),
    sold: mapField("sold", Number),
    income: mapField("income", Number),
    productTitle: mapField("product_title", String),
    pointTitle: mapField("point_title", String),
});

export const mapProductsIncomeFromServer = mapArray(mapProductIncomeFromServer);
