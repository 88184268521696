import { defineStore } from "pinia";
import { ref } from "vue";
import { useRouter } from "vue-router";

import { GENERAL_ROUTE_NAMES } from "@/shared";

import { getActiveTariff } from "../api/getActiveTariff";
import { getTariffsList } from "../api/getTariffsList";

export const useTariffStore = defineStore("tariffStore", () => {
    const router = useRouter();

    const tariff = ref(null);
    const statistic = ref(null);
    const isFree = ref(false);
    const tariffsList = ref(null);

    const setTariff = async () => {
        const {
            tariff: currentTariff,
            statistic: currentStatistic,
            isFree: currentIsFree,
        } = await getActiveTariff();

        tariff.value = currentTariff;
        statistic.value = currentStatistic;
        isFree.value = currentIsFree;
    };

    const setStatistic = (s) => {
        statistic.value = s;

        if (s.daysLeft === 0) {
            router.push({ name: GENERAL_ROUTE_NAMES.BUY_TARIFF });
        }
    };

    const fetchTariffsList = async (promocode) => {
        const { tariffs } = await getTariffsList(promocode);

        tariffsList.value = tariffs;
    };

    return {
        tariff,
        statistic,
        tariffsList,
        isFree,
        setTariff,
        setStatistic,
        fetchTariffsList,
    };
});
