import { makeHttpRequest } from "@/shared";

import { mapInventoryPriceFromServer } from "./mappers/mapInventoryPricesFromServer";
import { mapInventoryPriceToServer } from "./mappers/mapInventoryPriceToServer";

export const addInventoryPrice = async (tariff) => {
    const response = (
        await makeHttpRequest({
            url: "/inventory/price",
            method: "POST",
            data: mapInventoryPriceToServer(tariff),
        })
    ).data;

    return mapInventoryPriceFromServer(response.data);
};
