import { makeHttpRequest } from "@/shared";

import { mapInventoryFromServer } from "./mappers/mapInventoriesFromServer";

export const getInventory = async (inventoryId) => {
    const response = (
        await makeHttpRequest({
            url: `/inventory/${inventoryId}`,
            method: "GET",
        })
    ).data;

    return mapInventoryFromServer(response.data);
};
