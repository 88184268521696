import { makeHttpRequest } from "@/shared";

import { mapInventoryStatusToServer } from "./mappers/mapInventoryStatusToServer";

export const addInventoryStatus = async (inventoryStatus) => {
    const response = (
        await makeHttpRequest({
            url: "/inventory/state",
            method: "POST",
            data: mapInventoryStatusToServer(inventoryStatus),
        })
    ).data;

    return response;
};
