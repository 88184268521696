import { getDataMapper, mapField } from "@/shared";

export const mapSourceAttractionToServer = getDataMapper(
    {
        id: mapField("id", Number),
        title: mapField("title"),
        const: mapField("const", () => "SOURCE_ATTRACTION", {
            applyToNil: true,
        }), // const required to be not nil
    },
    {
        shouldAvoidUndefined: true,
    }
);
