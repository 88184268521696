import { makeHttpRequest } from "@/shared";

import { mapCalculatedRentFromServer } from "./mappers/mapCalculatedRentFromServer";

export const addDiscountToRentByPromo = async (sessionKey, promocode) => {
    const response = (
        await makeHttpRequest({
            url: `/rent/builder/${sessionKey}/add/discount/promocode`,
            method: "PUT",
            data: {
                promocode,
            },
        })
    ).data;

    return mapCalculatedRentFromServer(response.data);
};
