import {
    getDataMapper,
    mapField,
    mapArray,
    parseISO,
    mapHumanFromServer,
} from "@/shared";

import { mapAdditionalServiceFromServer } from "@/entities/additional-services/@x";
import { mapBreakingFromServer } from "@/entities/breakings/@x";
import { mapClientFromServer } from "@/entities/client/@x";
import { mapInventoryFromServer } from "@/entities/inventory/@x";
import { mapPointFromServer } from "@/entities/points/@x";
import { mapProductFromServer } from "@/entities/products/@x";
import { mapServiceFromServer } from "@/entities/services/@x";

export const mapRepairFromServer = getDataMapper({
    id: mapField("id", Number),
    number: mapField("order_number", Number),
    timeStart: mapField("time_start", parseISO),
    timeEnd: mapField("time_end", parseISO),
    timeClose: mapField("time_fact_end", parseISO),
    sum: mapField("sum", Number),
    totalSum: mapField("sum_real", Number),
    discountSum: mapField("sum_discount", Number),
    paid: mapField("payed", Number),
    additionalServiceSum: mapField("sum_additional_service", Number),
    serviceSum: mapField("sum_service", Number),
    productSum: mapField("sum_product", Number),
    additionalPayment: mapField(null, () => 0, { applyToNil: true }),
    additionalPaymentType: mapField(null, () => 1, { applyToNil: true }),
    client: mapField("human", mapClientFromServer),
    clientId: mapField("human", (client) => client?.id),
    adminUser: mapField("admin_user", mapHumanFromServer),
    point: mapField("open_point", mapPointFromServer),
    comment: mapField("comment"),
    status: mapField("repair_state", {
        id: mapField("id", Number),
        title: mapField("title", String),
        const: mapField("const"),
        color: mapField("color"),
    }),
    inventories: mapField("inventories", {
        breakings: mapField("brokens", {
            breaking: mapField("broken", mapBreakingFromServer),
        }),
        inventory: mapField("inventory", mapInventoryFromServer),
        services: mapField("services", {
            id: mapField("id", Number),
            sum: mapField("sum", Number),
            service: mapField("service", mapServiceFromServer),
        }),
    }),
    products: mapField("products", {
        product: mapField("product", mapProductFromServer),
        count: mapField("count", Number),
    }),
    additionalServices: mapField("additional_services", {
        additionalService: mapField(
            "additional_service",
            mapAdditionalServiceFromServer
        ),
        count: mapField("count", Number),
    }),
    fDocDocuments: mapField("fdoc_documents", {
        id: mapField("id"),
        link: mapField("reference"),
        status: mapField("status"),
        title: mapField("name"),
    }),
});

export const mapRepairsFromServer = mapArray(mapRepairFromServer);
