import {
    makeHttpRequest,
    mapDatabaseParamsFromServer,
    mapPaginationParamsToServer,
} from "@/shared";

import { mapEmployeeFiltersToServer } from "./mappers/mapEmployeeFiltersToServer";
import { mapEmployeesFromServer } from "./mappers/mapEmployeesFromServer";

export const getEmployees = async (paginationParams, filters) => {
    const response = (
        await makeHttpRequest({
            url: "/human/employee",
            method: "GET",
            params: {
                ...mapPaginationParamsToServer(paginationParams),
                ...mapEmployeeFiltersToServer(filters),
            },
        })
    ).data;

    return {
        employees: mapEmployeesFromServer(response.data),
        data: mapEmployeesFromServer(response.data),
        ...mapDatabaseParamsFromServer(response),
    };
};
