import {
    makeHttpRequest,
    mapPaginationParamsToServer,
    mapDatabaseParamsFromServer,
    mapCategoriesFromServer,
} from "@/shared";

import { mapCategoriesFiltersToServer } from "./mappers/mapCategoriesFiltersToServer";

export const getCategoriesInventory = async (
    paginationParams = {},
    filters = {}
) => {
    const response = (
        await makeHttpRequest({
            url: `/category/inventory${filters.isFlat ? "/flat" : ""}`,
            method: "GET",
            params: {
                ...mapPaginationParamsToServer(paginationParams),
                ...mapCategoriesFiltersToServer(filters),
            },
        })
    ).data;

    return {
        data: mapCategoriesFromServer(response.data),
        ...mapDatabaseParamsFromServer(response),
    };
};
