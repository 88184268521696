import { getDataMapper, mapField, mapArray } from "@/shared";

export const mapAttendanceFromServer = getDataMapper({
    attractionTitle: mapField("attraction_title", String),
    fio: mapField("fio", String),
    totalCount: mapField("total_count", Number),
    rentCount: mapField("rent_count", Number),
    repairCount: mapField("repair_count", Number),
    stockCount: mapField("stock_count", Number),
    income: mapField("income", Number),
});

export const mapAttendancesFromServer = mapArray(mapAttendanceFromServer);
