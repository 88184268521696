import { storeToRefs } from "pinia";

import { useEducationStore } from "../model/useEducationStore";
import { useOverlayStore } from "../model/useOverlayStore";

const getPopoverDimensions = (popover, padding = 0, offset = 0) => {
    if (!popover) return;

    const boundingClientRect = popover.getBoundingClientRect();

    return {
        width: boundingClientRect.width + padding + offset,
        height: boundingClientRect.height + padding + offset,

        realWidth: boundingClientRect.width,
        realHeight: boundingClientRect.height,
    };
};

const calculateTopForLeftRight = (
    align,
    {
        elementDimensions,
        popoverDimensions,
        popoverPadding,
        popoverArrowDimensions,
    }
) => {
    if (align === "start") {
        return Math.max(
            Math.min(
                elementDimensions.top - popoverPadding,
                window.innerHeight -
                    popoverDimensions.realHeight -
                    popoverArrowDimensions.width
            ),
            popoverArrowDimensions.width
        );
    }

    if (align === "end") {
        return Math.max(
            Math.min(
                elementDimensions.top -
                    popoverDimensions?.realHeight +
                    elementDimensions.height +
                    popoverPadding,
                window.innerHeight -
                    popoverDimensions?.realHeight -
                    popoverArrowDimensions.width
            ),
            popoverArrowDimensions.width
        );
    }

    if (align === "center") {
        return Math.max(
            Math.min(
                elementDimensions.top +
                    elementDimensions.height / 2 -
                    popoverDimensions?.realHeight / 2,
                window.innerHeight -
                    popoverDimensions?.realHeight -
                    popoverArrowDimensions.width
            ),
            popoverArrowDimensions.width
        );
    }

    return 0;
};

const calculateLeftForTopBottom = (
    align,
    {
        elementDimensions,
        popoverDimensions,
        popoverPadding,
        popoverArrowDimensions,
    }
) => {
    if (align === "start") {
        return Math.max(
            Math.min(
                elementDimensions.left - popoverPadding,
                window.innerWidth -
                    popoverDimensions.realWidth -
                    popoverArrowDimensions.width
            ),
            popoverArrowDimensions.width
        );
    }

    if (align === "end") {
        return Math.max(
            Math.min(
                elementDimensions.left -
                    popoverDimensions?.realWidth +
                    elementDimensions.width +
                    popoverPadding,
                window.innerWidth -
                    popoverDimensions?.realWidth -
                    popoverArrowDimensions.width
            ),
            popoverArrowDimensions.width
        );
    }

    if (align === "center") {
        return Math.max(
            Math.min(
                elementDimensions.left +
                    elementDimensions.width / 2 -
                    popoverDimensions?.realWidth / 2,
                window.innerWidth -
                    popoverDimensions?.realWidth -
                    popoverArrowDimensions.width
            ),
            popoverArrowDimensions.width
        );
    }

    return 0;
};

export const renderPopoverArrow = (align, side, element) => {
    const { stepElement } = storeToRefs(useEducationStore());
    const { overlayConfig } = storeToRefs(useOverlayStore());

    if (!stepElement.value) return;

    const elementDimensions = element.getBoundingClientRect();
    const popoverDimensions = getPopoverDimensions(
        stepElement.value.wrapper,
        overlayConfig.value.stagePadding,
        10
    );
    const popoverArrow = stepElement.value.arrow;

    const popoverWidth = popoverDimensions.width;
    const windowWidth = window.innerWidth;
    const elementWidth = elementDimensions.width;
    const elementLeft = elementDimensions.left;

    const popoverHeight = popoverDimensions.height;
    const windowHeight = window.innerHeight;
    const elementTop = elementDimensions.top;
    const elementHeight = elementDimensions.height;

    popoverArrow.className = "education__step-arrow";

    let arrowSide = side;
    let arrowAlign = align;

    if (side === "top") {
        if (elementLeft + elementWidth <= 0) {
            arrowSide = "right";
            arrowAlign = "end";
        } else if (elementLeft + elementWidth - popoverWidth <= 0) {
            arrowSide = "top";
            arrowAlign = "start";
        }

        if (elementLeft >= windowWidth) {
            arrowSide = "left";
            arrowAlign = "end";
        } else if (elementLeft + popoverWidth >= windowWidth) {
            arrowSide = "top";
            arrowAlign = "end";
        }
    } else if (side === "bottom") {
        if (elementLeft + elementWidth <= 0) {
            arrowSide = "right";
            arrowAlign = "start";
        } else if (elementLeft + elementWidth - popoverWidth <= 0) {
            arrowSide = "bottom";
            arrowAlign = "start";
        }

        if (elementLeft >= windowWidth) {
            arrowSide = "left";
            arrowAlign = "start";
        } else if (elementLeft + popoverWidth >= windowWidth) {
            arrowSide = "bottom";
            arrowAlign = "end";
        }
    } else if (side === "left") {
        if (elementTop + elementHeight <= 0) {
            arrowSide = "bottom";
            arrowAlign = "end";
        } else if (elementTop + elementHeight - popoverHeight <= 0) {
            arrowSide = "left";
            arrowAlign = "start";
        }

        if (elementTop >= windowHeight) {
            arrowSide = "top";
            arrowAlign = "end";
        } else if (elementTop + popoverHeight >= windowHeight) {
            arrowSide = "left";
            arrowAlign = "end";
        }
    } else if (side === "right") {
        if (elementTop + elementHeight <= 0) {
            arrowSide = "bottom";
            arrowAlign = "start";
        } else if (elementTop + elementHeight - popoverHeight <= 0) {
            arrowSide = "right";
            arrowAlign = "start";
        }

        if (elementTop >= windowHeight) {
            arrowSide = "top";
            arrowAlign = "start";
        } else if (elementTop + popoverHeight >= windowHeight) {
            arrowSide = "right";
            arrowAlign = "end";
        }
    }

    if (!arrowSide) {
        popoverArrow.classList.add("education__step-arrow-none");
    } else {
        popoverArrow.classList.add(`education__step-arrow-side-${arrowSide}`);
        popoverArrow.classList.add(`education__step-arrow-align-${arrowAlign}`);
    }
};

export const repositionEducationStep = (element, step) => {
    const { stepElement } = storeToRefs(useEducationStore());
    const { overlayConfig } = storeToRefs(useOverlayStore());

    if (!stepElement.value) return;

    const { align, side } = step;

    const requiredAlignment = align || "start";
    const requiredSide = side || "left";
    const popoverPadding = overlayConfig.value.stagePadding || 0;

    const popoverDimensions = getPopoverDimensions(
        stepElement.value.wrapper,
        overlayConfig.value.stagePadding,
        10
    );
    const popoverArrowDimensions =
        stepElement.value.arrow.getBoundingClientRect();
    const elementDimensions = element.getBoundingClientRect();

    const topValue = elementDimensions.top - popoverDimensions.height;
    let isTopOptimal = topValue >= 0;

    const bottomValue =
        window.innerHeight -
        (elementDimensions.bottom + popoverDimensions.height);
    let isBottomOptimal = bottomValue >= 0;

    const leftValue = elementDimensions.left - popoverDimensions.width;
    let isLeftOptimal = leftValue >= 0;

    const rightValue =
        window.innerWidth - (elementDimensions.right + popoverDimensions.width);
    let isRightOptimal = rightValue >= 0;

    const noneOptimal =
        !isTopOptimal && !isBottomOptimal && !isLeftOptimal && !isRightOptimal;
    let popoverRenderedSide = requiredSide;

    if (requiredSide === "top" && isTopOptimal) {
        isRightOptimal = isLeftOptimal = isBottomOptimal = false;
    } else if (requiredSide === "bottom" && isBottomOptimal) {
        isRightOptimal = isLeftOptimal = isTopOptimal = false;
    } else if (requiredSide === "left" && isLeftOptimal) {
        isRightOptimal = isTopOptimal = isBottomOptimal = false;
    } else if (requiredSide === "right" && isRightOptimal) {
        isLeftOptimal = isTopOptimal = isBottomOptimal = false;
    }

    if (requiredSide === "self") {
        const leftToSet =
            elementDimensions.x +
            elementDimensions.width -
            popoverDimensions.realWidth;
        const bottomToSet = Math.min(
            window.innerHeight -
                (elementDimensions.top + popoverDimensions.realHeight),
            window.innerHeight -
                popoverDimensions.realHeight -
                popoverArrowDimensions.width +
                10
        );

        stepElement.value.wrapper.style.left = `${leftToSet}px`;
        stepElement.value.wrapper.style.right = "auto";
        stepElement.value.wrapper.style.top = "auto";
        stepElement.value.wrapper.style.bottom = `${bottomToSet}px`;
    } else if (requiredSide === "over") {
        const leftToSet =
            window.innerWidth / 2 - popoverDimensions.realWidth / 2;
        const topToSet =
            window.innerHeight / 2 - popoverDimensions.realHeight / 2;

        stepElement.value.wrapper.style.left = `${leftToSet}px`;
        stepElement.value.wrapper.style.right = "auto";
        stepElement.value.wrapper.style.top = `${topToSet}px`;
        stepElement.value.wrapper.style.bottom = "auto";
    } else if (noneOptimal) {
        const leftValue =
            window.innerWidth / 2 - popoverDimensions?.realWidth / 2;
        const bottomValue = 10;

        stepElement.value.wrapper.style.left = `${leftValue}px`;
        stepElement.value.wrapper.style.right = "auto";
        stepElement.value.wrapper.style.bottom = `${bottomValue}px`;
        stepElement.value.wrapper.style.top = "auto";
    } else if (isLeftOptimal) {
        const leftToSet = Math.min(
            leftValue,
            window.innerWidth -
                popoverDimensions?.realWidth -
                popoverArrowDimensions.width
        );
        const topToSet = calculateTopForLeftRight(requiredAlignment, {
            elementDimensions,
            popoverDimensions,
            popoverPadding,
            popoverArrowDimensions,
        });

        stepElement.value.wrapper.style.left = `${leftToSet}px`;
        stepElement.value.wrapper.style.top = `${topToSet}px`;
        stepElement.value.wrapper.style.right = "auto";
        stepElement.value.wrapper.style.bottom = "auto";

        popoverRenderedSide = "left";
    } else if (isRightOptimal) {
        const rightToSet = Math.min(
            rightValue,
            window.innerWidth -
                popoverDimensions?.realWidth -
                popoverArrowDimensions.width
        );
        const topToSet = calculateTopForLeftRight(requiredAlignment, {
            elementDimensions,
            popoverDimensions,
            popoverPadding,
            popoverArrowDimensions,
        });

        stepElement.value.wrapper.style.right = `${rightToSet}px`;
        stepElement.value.wrapper.style.top = `${topToSet}px`;
        stepElement.value.wrapper.style.bottom = "auto";
        stepElement.value.wrapper.style.left = "auto";

        popoverRenderedSide = "right";
    } else if (isTopOptimal) {
        const topToSet = Math.min(
            topValue,
            window.innerHeight -
                popoverDimensions.realHeight -
                popoverArrowDimensions.width
        );
        let leftToSet = calculateLeftForTopBottom(requiredAlignment, {
            elementDimensions,
            popoverDimensions,
            popoverPadding,
            popoverArrowDimensions,
        });

        stepElement.value.wrapper.style.top = `${topToSet}px`;
        stepElement.value.wrapper.style.left = `${leftToSet}px`;
        stepElement.value.wrapper.style.right = "auto";
        stepElement.value.wrapper.style.bottom = "auto";

        popoverRenderedSide = "top";
    } else if (isBottomOptimal) {
        const bottomToSet = Math.min(
            bottomValue,
            window.innerHeight -
                popoverDimensions?.realHeight -
                popoverArrowDimensions.width
        );

        let leftToSet = calculateLeftForTopBottom(requiredAlignment, {
            elementDimensions,
            popoverDimensions,
            popoverPadding,
            popoverArrowDimensions,
        });

        stepElement.value.wrapper.style.left = `${leftToSet}px`;
        stepElement.value.wrapper.style.bottom = `${bottomToSet}px`;
        stepElement.value.wrapper.style.top = "auto";
        stepElement.value.wrapper.style.right = "auto";

        popoverRenderedSide = "bottom";
    }

    if (!noneOptimal) {
        renderPopoverArrow(requiredAlignment, popoverRenderedSide, element);
    } else {
        stepElement.value.arrow.classList.add("education__step-arrow-none");
    }
};
