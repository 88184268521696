/* eslint-disable node/no-unsupported-features/es-syntax */
import { EMPLOYEE_ROUTE_NAMES } from "@/shared";
export const EMPLOYEE_ROUTES = [
    {
        name: EMPLOYEE_ROUTE_NAMES.LIST,
        path: "/employee",
        meta: {
            title: "Сотрудники",
        },
        component: () => import("../ui/EmployeesPage.vue"),
    },
    {
        name: EMPLOYEE_ROUTE_NAMES.PROFILE,
        path: "/employee/:employeeId",
        meta: {
            title: "Профиль сотрудника",
        },
        component: () => import("../ui/EmployeeProfile.vue"),
        props: true,
    },
    {
        name: EMPLOYEE_ROUTE_NAMES.CREATE,
        path: "/employee/create",
        meta: {
            title: "Добавление сотрудника",
        },
        component: () => import("../ui/EmployeeEdit.vue"),
    },
    {
        name: EMPLOYEE_ROUTE_NAMES.EDIT,
        path: "/employee/edit/:employeeId",
        meta: {
            title: "Редактирование сотрудника",
        },
        component: () => import("../ui/EmployeeEdit.vue"),
        props: true,
    },
];
