import { defineStore } from "pinia";

import { useSelectEntityStore } from "@/shared";

import { getEmployeePoints } from "../api/getEmployeePoints";

export const useEmployeePointsStore = defineStore("employeePoints", () => {
    const { selectMeta, selectData, isSelectLoading, fetchSelect } =
        useSelectEntityStore({
            getEntities: getEmployeePoints,
        });

    return {
        selectMeta,
        selectData,
        isSelectLoading,
        fetchSelect,
    };
});
