import { getDataMapper, mapArray, mapField } from "@/shared";

export const mapPaymentHistoryFromServer = getDataMapper({
    id: mapField("id", Number),
    directorTariff: mapField("director_tariff", {
        id: mapField("id", Number),
        title: mapField("title", String),
        cost: mapField("cost", Number),
        period: mapField("period", String),
    }),
    period: mapField("period", String),
    datePay: mapField("date_pay", String),
    sum: mapField("sum", Number),
    title: mapField("title"),
});

export const mapPaymentsHistoryFromServer = mapArray(
    mapPaymentHistoryFromServer
);
