import { makeHttpRequest } from "@/shared";

import { mapCalculatedRentFromServer } from "./mappers/mapCalculatedRentFromServer";
import { mapRentProductToServer } from "./mappers/mapRentProductToServer";

export const updateRentProduct = async (sessionKey, product) => {
    const response = (
        await makeHttpRequest({
            url: `/rent/builder/${sessionKey}/update/product`,
            method: "PUT",
            data: mapRentProductToServer(product),
        })
    ).data;

    return mapCalculatedRentFromServer(response.data);
};
