export const REPORTS_ROUTE_NAMES = {
    REPORTS: "ReportsPage",
    REPORTS_ATTENDANCE: "ReportsAttendance",
    REPORTS_EFFICIENCY: "ReportsEfficiency",
    REPORTS_INCOME: "ReportsIncome",
    REPORTS_INVENTORY_INCOME: "ReportsInventoryIncome",
    REPORTS_INVENTORY_PAYBACK: "ReportsInventoryPayback",
    REPORTS_PRODUCT_INCOME: "ReportsProductIncome",
    REPORTS_PRODUCT_LEFTOVER: "ReportsProductLeftover",
    REPORTS_REVISE: "ReportsRevise",
};
