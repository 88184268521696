import { makeHttpRequest } from "@/shared";

import { mapCalculatedRepairFromServer } from "./mappers/mapCalculatedRepairFromServer";
export const prepareRepair = async (id) => {
    const response = (
        await makeHttpRequest({
            url: `/repair/builder/prepare${id ? `/${id}` : ""}`,
            method: "POST",
            data: {
                time_start: null,
                time_fact_end: null,
                currency_id: null,
                repair_state_id: null,
            },
        })
    ).data;

    return {
        repair: mapCalculatedRepairFromServer(response.repair),
        repairSessionKey: response.repairSessionKey,
    };
};
