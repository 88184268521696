import { getDataMapper, mapField } from "@/shared";

import {
    mapDashboardExpenseType,
    mapDashboardAttraction,
    mapInventoryState,
    mapIncomeRentState,
} from "./mapDashboardMainFromServer";

export const mapTopInventory = getDataMapper({
    id: mapField("inventory_id", Number),
    title: mapField("title", String),
    avatar: mapField("avatar", String),
    sum: mapField("sum", Number),
    countDeals: mapField("count_deals", Number),
});

function CategoryIncome() {
    this.categoryResourceId = mapField("category_resource_id", Number);
    this.sumTotal = mapField("sum_total", Number);
    this.title = mapField("title", String);
    this.parentId = mapField("parent_id");
    this.children = mapField("children", this);
}

export const mapCategoriesIncome = getDataMapper(new CategoryIncome());

export const mapDashboardStartBusinessFromServer = getDataMapper({
    income: mapField("income", {
        sumTotal: mapField("sum_total", Number),
        sumCard: mapField("sum_card", Number),
        sumCash: mapField("sum_cash", Number),
        sumClean: mapField("sum_clean", Number),
    }),
    expense: mapField("expense", Number),
    countContracts: mapField("count_contracts", Number),
    middle_receipt: mapField("middle_receipt", Number),
    clientCount: mapField("client_count", {
        all: mapField("all", Number),
        blackList: mapField("black_list", Number),
    }),
    graph: mapField("graph"),
    expenseTypes: mapField("expense_types", mapDashboardExpenseType),
    attractions: mapField("attractions", mapDashboardAttraction),
    topInventory: mapField("top_inventory", mapTopInventory),
    incomeRentStates: mapField("income_rent_states", mapIncomeRentState),
    inventoryStates: mapField("inventory_states", {
        states: mapField("states", mapInventoryState),
        noPointsCount: mapField("no_points_count", Number),
    }),
    categoriesIncome: mapField("categories_income", mapCategoriesIncome),
    sumDebts: mapField("sum_debts", Number),
});
