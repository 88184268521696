import { makeHttpRequest } from "@/shared";

import { mapCalculatedRepairFromServer } from "./mappers/mapCalculatedRepairFromServer";
import { mapRepairInventoryToServer } from "./mappers/mapRepairInventoryToServer";
export const updateRepairInventory = async (sessionKey, repairInventory) => {
    const response = (
        await makeHttpRequest({
            url: `/repair/builder/${sessionKey}/update/inventory`,
            method: "PUT",
            data: mapRepairInventoryToServer(repairInventory),
        })
    ).data;

    return mapCalculatedRepairFromServer(response.data);
};
