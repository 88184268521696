/* eslint-disable node/no-unsupported-features/es-syntax */
import { EXPENSE_ROUTE_NAMES } from "@/shared";
export const EXPENSE_ROUTES = [
    {
        name: EXPENSE_ROUTE_NAMES.LIST,
        path: "/expense",
        meta: {
            title: "Расходы",
        },
        props: (route) => ({
            ...route.params,
            ...route.query,
        }),
        component: () => import("../ui/ExpenseList.vue"),
    },
    {
        name: EXPENSE_ROUTE_NAMES.ADD,
        path: "/expense/add",
        meta: {
            title: "Добавление расхода",
        },
        component: () => import("../ui/ExpenseEdit.vue"),
    },
    {
        name: EXPENSE_ROUTE_NAMES.EDIT,
        path: "/expense/edit/:id",
        props: true,
        meta: {
            title: "Редактирование расхода",
        },
        component: () => import("../ui/ExpenseEdit.vue"),
    },
];
