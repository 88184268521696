import { makeHttpRequest } from "@/shared";

import { mapDeliveryFromServer } from "./mappers/mapDeliveriesFromServer";

export const getDelivery = async (deliveryId) => {
    const response = (
        await makeHttpRequest({
            url: `/delivery/${deliveryId}`,
            method: "GET",
        })
    ).data;

    return {
        delivery: mapDeliveryFromServer(response.data),
    };
};
