import { getDataMapper, mapField, isPrimitive, isNil } from "@/shared";

export const mapInventoryIncomesFiltersToServer = getDataMapper({
    period: mapField("period", (period) => {
        return isPrimitive(period.interval) || isNil(period.interval)
            ? null
            : period.interval;
    }),
    period_interval: mapField("period", (period) => {
        return isPrimitive(period.interval) ? period.interval : null;
    }),
    category_resource_id: mapField("categoryId", Number),
    point_id: mapField("pointId", Number),
});
