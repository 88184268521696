import { getDataMapper, mapField } from "@/shared";

export const mapCategoriesFiltersToServer = getDataMapper(
    {
        flat: mapField("isFlat", Boolean),
        has_inventories: mapField("hasInventories", Boolean),
    },
    {
        shouldAvoidUndefined: true,
        shouldAvoidNull: true,
    }
);
