import { setAuthToken } from "@/entities";
import { formatISO } from "@/shared";

export const takeJwtFromUrl = function () {
    const url = new URL(window.location.href);
    const jwt = url.searchParams.get("token");

    if (jwt) {
        const expires = new Date();

        expires.setDate(expires.getDate() + 1);
        setAuthToken(jwt, formatISO(expires));
        url.searchParams.delete("token");
        window.history.pushState({}, "", url.toString());
    }
};
