import { defineStore } from "pinia";
import { ref } from "vue";
import { useRouter } from "vue-router";

import { STORE_ROUTE_NAMES } from "@/shared";

import { addAdditionalServiceToSelling } from "../api/addAdditionalServiceToSelling";
import { addClientToSelling } from "../api/addClientToSelling";
import { addDiscountToSellingByPromo } from "../api/addDiscountToSellingByPromo";
import { addDiscountToSellingByValue } from "../api/addDiscountToSellingByValue";
import { addPaymentToSelling } from "../api/addPaymentToSelling";
import { addProductToSelling } from "../api/addProductToSelling";
import { prepareSelling } from "../api/prepareSelling";
import { removeAdditionalServiceFromSelling } from "../api/removeAdditionalServiceFromSelling";
import { removeClientFromSelling } from "../api/removeClientFromSelling";
import { removeDiscountFromSelling } from "../api/removeDiscountFromSelling";
import { removePaymentFromSelling } from "../api/removePaymentFromSelling";
import { removeProductFromSelling } from "../api/removeProductFromSelling";
import { saveSelling } from "../api/saveSelling";
import { updateSellingAdditional } from "../api/updateSellingAdditional";
import { updateSellingAdditionalService } from "../api/updateSellingAdditionalService";
import { updateSellingProduct } from "../api/updateSellingProduct";

export const useStoreSellingEditorStore = defineStore(
    "storeSellingEditor",
    () => {
        const sessionKey = ref(null);
        const sellingData = ref({
            client: null,
            discounts: [],
            products: [],
            additionalServices: [],
        });
        const isLoading = ref(false);
        const router = useRouter();

        const addClient = doAsyncAction(async () => {
            sellingData.value = await addClientToSelling(
                sessionKey.value,
                sellingData.value.client.human.id
            );
        });

        const removeClient = doAsyncAction(async () => {
            sellingData.value = await removeClientFromSelling(sessionKey.value);
        });

        const addProduct = doAsyncAction(async (rentProduct) => {
            sellingData.value = await addProductToSelling(
                sessionKey.value,
                rentProduct
            );
        });

        const removeProduct = doAsyncAction(async (rentProduct) => {
            sellingData.value = await removeProductFromSelling(
                sessionKey.value,
                rentProduct
            );
        });

        const updateProduct = doAsyncAction(async (rentProduct) => {
            sellingData.value = await updateSellingProduct(
                sessionKey.value,
                rentProduct
            );
        });

        const addAdditionalService = doAsyncAction(
            async (rentAdditionalService) => {
                sellingData.value = await addAdditionalServiceToSelling(
                    sessionKey.value,
                    rentAdditionalService
                );
            }
        );

        const removeAdditionalService = doAsyncAction(
            async (rentAdditionalService) => {
                sellingData.value = await removeAdditionalServiceFromSelling(
                    sessionKey.value,
                    rentAdditionalService
                );
            }
        );

        const updateAdditionalService = doAsyncAction(
            async (rentAdditionalService) => {
                sellingData.value = await updateSellingAdditionalService(
                    sessionKey.value,
                    rentAdditionalService
                );
            }
        );

        const addPayment = doAsyncAction(async (payment) => {
            sellingData.value = await addPaymentToSelling(
                sessionKey.value,
                payment
            );
        });

        const removePayment = doAsyncAction(async (payment) => {
            sellingData.value.payments = sellingData.value?.payments?.filter(
                ({ date }) => date === payment.date
            );

            sellingData.value = await removePaymentFromSelling(
                sessionKey.value,
                payment
            );
        });

        const addDiscountByValue = doAsyncAction(async (value) => {
            sellingData.value = await addDiscountToSellingByValue(
                sessionKey.value,
                value
            );
        });

        const addDiscountByPromo = doAsyncAction(async (promo) => {
            sellingData.value = await addDiscountToSellingByPromo(
                sessionKey.value,
                promo
            );
        });

        const removeDiscount = doAsyncAction(async () => {
            sellingData.value.discounts = [];
            sellingData.value = await removeDiscountFromSelling(
                sessionKey.value
            );
        });

        const updateAdditional = doAsyncAction(async (additional) => {
            sellingData.value = await updateSellingAdditional(
                sessionKey.value,
                additional
            );
        });

        const save = doAsyncAction(async () => {
            await saveSelling(sessionKey.value);
            router.push({ name: STORE_ROUTE_NAMES.STOCK.MAIN });
        });

        const prepare = doAsyncAction(async (id) => {
            const { sellingSessionKey, data } = await prepareSelling(id);

            sessionKey.value = sellingSessionKey;
            sellingData.value = data;
        });

        function doAsyncAction(action) {
            return async function asyncActionWrapper(...params) {
                isLoading.value = true;
                await action(...params);
                isLoading.value = false;
            };
        }

        return {
            // data
            sellingData,
            sessionKey,
            isLoading,

            // preparing
            prepare,

            // calculating
            addClient,
            removeClient,

            addProduct,
            removeProduct,
            updateProduct,

            addAdditionalService,
            removeAdditionalService,
            updateAdditionalService,

            addPayment,
            removePayment,

            addDiscountByPromo,
            addDiscountByValue,
            removeDiscount,

            updateAdditional,

            // saving
            save,
        };
    }
);
