/* eslint-disable node/no-unsupported-features/es-syntax */
import { SALARY_ROUTE_NAMES } from "@/shared";
export const SALARY_ROUTES = [
    {
        name: SALARY_ROUTE_NAMES.LIST,
        path: "/employee/salary",
        meta: {
            title: "Зарплаты сотрудников",
        },
        component: () => import("../ui/SalaryList.vue"),
    },
    {
        name: SALARY_ROUTE_NAMES.HISTORY,
        path: "/employee/salary/history",
        meta: {
            title: "История зарплат",
        },
        component: () => import("../ui/SalaryHistory.vue"),
    },
    {
        name: SALARY_ROUTE_NAMES.EDIT,
        path: "/employee/salary/edit/:salaryId",
        meta: {
            title: "Редактирование зарплаты",
        },
        component: () => import("../ui/SalaryEdit.vue"),
        props: true,
    },
];
