import { makeHttpRequest } from "@/shared";

export const deleteSourceAttraction = async (id) => {
    return (
        await makeHttpRequest({
            url: `/attraction/${id}`,
            method: "DELETE",
        })
    ).data;
};
