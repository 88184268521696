import { makeHttpRequest } from "@/shared";

import { mapInventoryPriceToServer } from "./mappers/mapInventoryPriceToServer";

export const editInventoryPrice = async (id, tariff) => {
    const response = (
        await makeHttpRequest({
            url: `/inventory/price/${id}`,
            method: "PUT",
            data: mapInventoryPriceToServer(tariff),
        })
    ).data;

    return response;
};
