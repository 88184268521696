import { getDataMapper, mapField, mapArray } from "@/shared";

function CategoriesMeta() {
    this.id = mapField("id");
    this.text = mapField("title");
    this.discounts = mapField("discounts");
    this.tariffs = mapField("tariffs");
    this.cashDeposit = mapField("cashDeposit", Number);
    this.children = mapField("children", this);
}

export const mapCategoryToBaseTree = getDataMapper(new CategoriesMeta());

export const mapCategoriesToBaseTree = mapArray(mapCategoryToBaseTree);
