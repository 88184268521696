import { defineStore } from "pinia";

import { useEntityStore, useSelectEntityStore } from "@/shared";

import { addPoint } from "../api/addPoint";
import { deletePoint } from "../api/deletePoint";
import { deletePoints } from "../api/deletePoints";
import { editPoint } from "../api/editPoint";
import { getPoint } from "../api/getPoint";
import { getPoints } from "../api/getPoints";

export const usePointsStore = defineStore("points", () => {
    const {
        isManyLoading,
        isOneLoading,
        listData,
        singleData,
        meta,
        permissions,
        addOne,
        deleteOne,
        deleteMany,
        editOne,
        fetchMany,
        getOne,
        setManyLoading,
    } = useEntityStore({
        addEntity: addPoint,
        getEntity: getPoint,
        getEntities: getPoints,
        deleteEntity: deletePoint,
        deleteEntities: deletePoints,
        editEntity: editPoint,
    });

    const { selectMeta, selectData, isSelectLoading, fetchSelect } =
        useSelectEntityStore({
            getEntities: getPoints,
        });

    return {
        // entity
        listData,
        singleData,
        isManyLoading,
        isOneLoading,
        meta,
        permissions,
        fetchMany,
        deleteOne,
        deleteMany,
        getOne,
        editOne,
        addOne,
        setManyLoading,

        // select
        selectData,
        selectMeta,
        isSelectLoading,
        fetchSelect,
    };
});
