import { makeHttpRequest } from "@/shared";

import { mapPointFromServer } from "./mappers/mapPointsFromServer";

export const getPoint = async (id) => {
    const response = (
        await makeHttpRequest({
            url: `/point/${id}`,
            method: "GET",
        })
    ).data;

    return mapPointFromServer(response.data);
};
