import { getDataMapper, mapField, mapArray, parseISODuration } from "@/shared";

export const mapInventoryPriceFromServer = getDataMapper({
    id: mapField("id", Number),
    title: mapField("title"),
    values: mapField("values", {
        id: mapField("id", Number),
        priceId: mapField("price_id", Number),
        periodISO: mapField("period"),
        period: mapField("period", parseISODuration),
        value: mapField("value", Number),
        moreThenISO: mapField("more_then"),
        moreThen: mapField("more_then", (v) => {
            if (!v) {
                v = "PT0S";
            }

            return parseISODuration(v);
        }),
        isFixed: mapField("is_fixed", Boolean),
    }),
});

export const mapInventoryPricesFromServer = mapArray(
    mapInventoryPriceFromServer
);
