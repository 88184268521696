import {
    getDataMapper,
    mapField,
    parseISO,
    mapHumanFromServer,
} from "@/shared";

import { mapAdditionalServiceFromServer } from "@/entities/additional-services/@x";
import { mapBreakingFromServer } from "@/entities/breakings/@x";
import { mapClientFromServer } from "@/entities/client/@x";
import { mapDepositFromServer } from "@/entities/deposits/@x";
import { mapDiscountFromServer } from "@/entities/discounts/@x";
import { mapInventoryFromServer } from "@/entities/inventory/@x";
import { mapKitFromServer } from "@/entities/kit/@x";
import { mapPointFromServer } from "@/entities/points/@x";
import { mapProductFromServer } from "@/entities/products/@x";
import { mapServiceFromServer } from "@/entities/services/@x";

export const mapCalculatedRepairFromServer = getDataMapper({
    id: mapField("id", Number),
    number: mapField("order_number", Number),
    timeStart: mapField("time_start", parseISO),
    timeEnd: mapField("time_end", parseISO),
    timeClose: mapField("time_fact_end", parseISO),
    sum: mapField("sum", Number),
    totalSum: mapField("sum_real", Number),
    discountSum: mapField("sum_discount", Number),
    paid: mapField("payed", Number),
    additionalServiceSum: mapField("sum_additional_service", Number),
    serviceSum: mapField("sum_service", Number),
    productSum: mapField("sum_product", Number),
    additionalPayment: mapField(null, () => 0, { applyToNil: true }),
    additionalPaymentType: mapField(null, () => 1, { applyToNil: true }),
    client: mapField("human", mapClientFromServer),
    clientId: mapField("human", (client) => client?.id),
    adminUser: mapField("admin_user", mapHumanFromServer),
    point: mapField("open_point", mapPointFromServer),
    comment: mapField("comment"),
    status: mapField("repair_state", {
        id: mapField("id", Number),
        title: mapField("title", String),
        const: mapField("const"),
        color: mapField("color"),
    }),
    inventories: mapField("inventories", {
        breakings: mapField("brokens", {
            breaking: mapField("broken", mapBreakingFromServer),
        }),
        resourceInventory: mapField("resource_inventory", {
            inventory: mapField("inventory", mapInventoryFromServer),
            price: mapField("price", {
                title: mapField("title"),
                id: mapField("id", Number),
            }),
        }),
        services: mapField("services", {
            id: mapField("id", Number),
            sum: mapField("sum", Number),
            service: mapField("service", mapServiceFromServer),
        }),
    }),
    products: mapField("products", {
        product: mapField("product", mapProductFromServer),
        count: mapField("count", Number),
        max: mapField("max", Number),
    }),
    additionalServices: mapField("additional_services", {
        additionalService: mapField(
            "additional_service",
            mapAdditionalServiceFromServer
        ),
        count: mapField("count", Number),
    }),
    kits: mapField("kits", {
        id: mapField("id", Number),
        kitInstance: mapField("kit", mapKitFromServer),
    }),
    payments: mapField("payments", {
        id: mapField("id", Number),
        date: mapField("date", parseISO),
        value: mapField("value", Number),
        payType: mapField("pay_type", {
            title: mapField("title"),
            const: mapField("const"),
        }),
    }),
    discounts: mapField("discounts", {
        id: mapField("id", Number),
        discount: mapField("discount", mapDiscountFromServer),
        sum: mapField("sum", Number),
        temporaryKey: mapField("temporary_key"),
    }),
    deposit: mapField("deposit", {
        depositInstance: mapField("deposit", mapDepositFromServer),
        sum: mapField("sum", Number),
        isAccepted: mapField("is_accepted", Boolean),
        isReturned: mapField("is_returned", Boolean),
    }),
    shouldCalculateBreaking: mapField("need_calc_breaking", Boolean),
    shouldCalculateRepairDelay: mapField("need_calc_repair_delay", Boolean),
    check: mapField("check"),
    fDocDocuments: mapField("fdoc_documents", {
        id: mapField("id"),
        link: mapField("reference"),
        status: mapField("status"),
        title: mapField("name"),
    }),
});
