import { makeHttpRequest } from "@/shared";

import { mapSalaryFromServer } from "./mappers/mapSalariesFromServer";

export const getSalary = async (id) => {
    const response = (
        await makeHttpRequest({
            url: `/human/employee/salary/${id}`,
            method: "GET",
        })
    ).data;

    return mapSalaryFromServer(response.data);
};
