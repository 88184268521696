import { makeHttpRequest } from "@/shared";

import { mapCalculatedRentFromServer } from "./mappers/mapCalculatedRentFromServer";
import { mapRentInventoryToServer } from "./mappers/mapRentInventoryToServer";

export const finishRentInventoryGroup = async (
    sessionKey,
    rentInventory,
    count
) => {
    const response = (
        await makeHttpRequest({
            url: `/rent/builder/${sessionKey}/inventory/finish-multiple`,
            method: "PUT",
            data: mapRentInventoryToServer({
                inventory: rentInventory.inventory,
                count,
            }),
        })
    ).data;

    return mapCalculatedRentFromServer(response.data);
};
