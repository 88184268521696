export const easeInOutQuad = (
    elapsed,
    initialValue,
    amountOfChange,
    duration
) => {
    if ((elapsed /= duration / 2) < 1) {
        return (amountOfChange / 2) * elapsed * elapsed + initialValue;
    }

    return (
        (-amountOfChange / 2) * (--elapsed * (elapsed - 2) - 1) + initialValue
    );
};
