const isElementInView = (element) => {
    const rect = element.getBoundingClientRect();

    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
            (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
            (window.innerWidth || document.documentElement.clientWidth)
    );
};

const hasScrollableParent = (element) => {
    if (!element || !element.parentElement) return;

    const parent = element.parentElement;

    return parent.scrollHeight > parent.clientHeight;
};

export const bringInView = (element) => {
    const mobileView = window.matchMedia("(max-width: 599px)").matches;

    if ((!element || isElementInView(element)) && !mobileView) return;

    element.scrollIntoView({
        behavior: hasScrollableParent(element) ? "auto" : "smooth",
        inline: "center",
        block: mobileView ? "end" : "center",
    });
};
