import { makeHttpRequest } from "@/shared";

import { mapSalaryToServer } from "./mappers/mapSalariesToServer";

export const addSalary = async (salary) => {
    const response = (
        await makeHttpRequest({
            url: "/human/employee/salary",
            method: "POST",
            data: mapSalaryToServer(salary),
        })
    ).data;

    return response;
};
