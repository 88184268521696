import { makeHttpRequest } from "@/shared";

import { mapBreakingToServer } from "./mappers/mapBreakingToServer";

export const editBreaking = async (id, breaking) => {
    const response = (
        await makeHttpRequest({
            url: `/broken/${id}`,
            method: "PUT",
            data: mapBreakingToServer(breaking),
        })
    ).data;

    return response;
};
