import { defineStore } from "pinia";

import { useEntityStore } from "@/shared";

import { addKit } from "../api/addKit";
import { deleteKit } from "../api/deleteKit";
import { deleteKits } from "../api/deleteKits";
import { editKit } from "../api/editKit";
import { getKit } from "../api/getKit";
import { getKits } from "../api/getKits";

export const useKitsStore = defineStore("kits", () => {
    const {
        isManyLoading,
        isOneLoading,
        listData,
        singleData,
        meta,
        permissions,
        addOne,
        deleteOne,
        deleteMany,
        editOne,
        fetchMany,
        getOne,
        setManyLoading,
    } = useEntityStore({
        addEntity: addKit,
        getEntities: getKits,
        getEntity: getKit,
        deleteEntity: deleteKit,
        deleteEntities: deleteKits,
        editEntity: editKit,
    });

    return {
        // entity
        listData,
        singleData,
        isManyLoading,
        isOneLoading,
        meta,
        permissions,
        fetchMany,
        getOne,
        addOne,
        deleteOne,
        deleteMany,
        editOne,
        setManyLoading,
    };
});
