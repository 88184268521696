export default {
    getSignedURL(file, config) {
        let payload = {
            filePath: file.name,
            contentType: file.type,
        };

        return new Promise((resolve, reject) => {
            const fd = new FormData();
            const request = new XMLHttpRequest();
            const signingURL =
                typeof config.signingURL === "function"
                    ? config.signingURL(file)
                    : config.signingURL;

            request.open("POST", signingURL);
            request.onload = () => {
                if (request.status === 200) {
                    resolve(JSON.parse(request.response));
                } else {
                    reject(request.statusText);
                }
            };
            request.onerror = (err) => {
                console.error(
                    "Network Error : Could not send request to AWS (Maybe CORS errors)"
                );
                reject(err);
            };

            if (config.withCredentials === true) {
                request.withCredentials = true;
            }

            Object.entries(config.headers || {}).forEach(([name, value]) => {
                request.setRequestHeader(name, value);
            });
            payload = Object.assign(payload, config.params || {});
            Object.entries(payload).forEach(([name, value]) => {
                fd.append(name, value);
            });

            request.send(fd);
        });
    },
    sendFile(file, config, isSendingS3) {
        const handler = isSendingS3
            ? this.setResponseHandler
            : this.sendS3Handler;

        return this.getSignedURL(file, config)
            .then((response) => handler(response, file))
            .catch((error) => error);
    },
    setResponseHandler(response, file) {
        file.s3Signature = response.signature;
        file.s3Url = response.postEndpoint;
    },
    sendS3Handler(response, file) {
        const fd = new FormData();
        const { signature } = response;

        Object.keys(signature).forEach((key) => {
            fd.append(key, signature[key]);
        });
        fd.append("file", file);

        return new Promise((resolve, reject) => {
            const request = new XMLHttpRequest();

            request.open("POST", response.postEndpoint);
            request.onload = () => {
                if (request.status === 201) {
                    const s3Error = new window.DOMParser().parseFromString(
                        request.response,
                        "text/xml"
                    );
                    const successMsg = s3Error.firstChild.children[0].innerHTML;

                    resolve({
                        success: true,
                        message: successMsg,
                    });
                } else {
                    const s3Error = new window.DOMParser().parseFromString(
                        request.response,
                        "text/xml"
                    );
                    const errMsg = s3Error.firstChild.children[0].innerHTML;

                    // eslint-disable-next-line prefer-promise-reject-errors
                    reject({
                        success: false,
                        message: `${errMsg}. Request is marked as resolved when returns as status 201`,
                    });
                }
            };
            request.onerror = () => {
                const s3Error = new window.DOMParser().parseFromString(
                    request.response,
                    "text/xml"
                );
                const errMsg = s3Error.firstChild.children[1].innerHTML;

                // eslint-disable-next-line prefer-promise-reject-errors
                reject({
                    success: false,
                    message: errMsg,
                });
            };
            request.send(fd);
        });
    },
};
