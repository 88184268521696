import { makeHttpRequest } from "@/shared";

import { mapDiscountToServer } from "./mappers/mapDiscountToServer";

export const addDiscount = async (discount) => {
    return (
        await makeHttpRequest({
            url: "/discount",
            method: "POST",
            data: mapDiscountToServer(discount),
        })
    ).data;
};
