<script setup>
import { ref } from "vue";

import { useAuthStore } from "@/entities";
import {
    BaseModal,
    BaseButton,
    BaseFormGroup,
    BaseTextarea,
    BaseLoader,
} from "@/shared";

const authStore = useAuthStore();

const modal = ref(null);
const comment = ref(null);
const isLoading = ref(false);

const show = () => {
    modal.value.show();
};

const hide = () => {
    modal.value.hide();
};

const handleExit = async function () {
    isLoading.value = true;

    await authStore.logout(comment.value);

    location.reload();

    isLoading.value = true;

    hide();
};

defineExpose({ show });
</script>

<template>
    <BaseModal class="the-header-exit-modal" ref="modal" has-header has-footer>
        <template #heading> Подтверждение </template>
        <template #default>
            <div class="the-header-exit-modal__content">
                <span>Вы действительно хотите выйти и закрыть смену?</span>
                <BaseFormGroup label="Комментарий">
                    <template #input>
                        <BaseTextarea
                            v-model="comment"
                            placeholder="Введите комментарий..."
                        />
                    </template>
                </BaseFormGroup>
            </div>
            <BaseLoader v-if="isLoading" />
        </template>
        <template #footer>
            <div class="the-header-exit-modal__footer">
                <BaseButton variant="primary" @click="handleExit">
                    Выйти
                </BaseButton>
            </div>
        </template>
    </BaseModal>
</template>

<style scoped lang="scss">
.the-header-exit-modal {
    &__footer {
        display: flex;

        justify-content: flex-end;

        width: 100%;
    }

    &__content {
        display: flex;

        flex-direction: column;

        gap: 20px;
    }
}
</style>
