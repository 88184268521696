import { makeHttpRequest } from "@/shared";

import { mapCalculatedRentFromServer } from "./mappers/mapCalculatedRentFromServer";
import { mapRentAdditionalServiceToServer } from "./mappers/mapRentAdditionalServiceToServer";

export const updateRentAdditionalService = async (sessionKey, service) => {
    const response = (
        await makeHttpRequest({
            url: `/rent/builder/${sessionKey}/update/service`,
            method: "PUT",
            data: mapRentAdditionalServiceToServer(service),
        })
    ).data;

    return mapCalculatedRentFromServer(response.data);
};
